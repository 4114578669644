import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import '../components/all.sass'

// import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'

const openInstagram = () => {
  window.open('https://www.instagram.com/xuehka', '_blank');
}

const openFacebook = () => {
  window.open('https://www.facebook.com/Xuehka', '_blank');
}

const openEmail = () => {
  window.open('mailto:info@xuehka.com', '_blank');
}

export const IndexPageTemplate = () => (
  <div
    className="coming-soon"
    style={{
      width: '100vw',
      height: '100vh',
      backgroundColor: '#A896A6',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 345.646 243.602"
    >
    <g>
      <g>
        <path fill="#A896A6" d="M173.672-1.08c57.24,0,114.479,0.002,171.721-0.022c0.899,0,1.104,0.203,1.104,1.103
          c-0.023,81.2-0.023,162.398,0,243.603c0,0.897-0.202,1.102-1.104,1.102c-114.479-0.023-228.961-0.023-343.439,0
          c-0.899,0-1.104-0.203-1.103-1.102C0.874,162.4,0.874,81.202,0.85,0c0-0.899,0.202-1.104,1.103-1.103
          C59.193-1.078,116.43-1.08,173.672-1.08z M245.617,56.474c-0.256,0.077-0.384,0.267-0.445,0.511
          c-0.858,0.955-1.138,2.002-0.504,3.191c0.224,0.36,0.429,0.733,0.672,1.079c0.38,0.538,0.754,1.108,1.558,0.984
          c1.076-0.004,2.104-0.22,3.066-0.707c0.452-0.275,0.656-0.688,0.665-1.207c0.088-0.735,0.171-1.471,0.262-2.205
          c0.126-1.02-0.263-1.797-1.093-2.376c-0.67-0.625-1.4-0.715-2.195-0.253c-0.241,0.027-0.481,0.055-0.724,0.083
          c-0.691-0.093-0.98,0.385-1.25,0.89L245.617,56.474z M98.428,64.883c0.126-0.399,0.021-0.721-0.32-0.964
          c-0.768-1.107-1.985-0.697-3.011-0.82c-0.68-0.083-1.549-0.146-1.924,0.766c-0.274,0.612-0.567,1.218-0.818,1.841
          c-0.243,0.605-0.184,1.223,0.351,1.623c0.929,0.696,1.978,1.257,3.172,1.053c0.773-0.131,1.612-0.38,1.893-1.318
          c0.114-0.144,0.229-0.288,0.346-0.432c0.208-0.228,0.145-0.48,0.063-0.734C98.563,65.633,98.501,65.259,98.428,64.883z
           M30.429,80.712c-0.764-0.966-1.827-0.714-2.736-0.487c-0.82,0.205-0.498,1.125-0.604,1.755c-0.007,0.979,0.547,1.561,1.441,1.692
          c0.705,0.105,1.389-0.11,1.643-0.938C30.845,82.135,31.028,81.472,30.429,80.712z M307.669,178.504
          c-0.195-0.5-0.56-0.746-1.101-0.717c-0.15-0.072-0.303-0.146-0.453-0.218c-1.25-0.231-2.515-0.403-3.748-0.706
          c-2.666-0.658-5.387-0.951-8.099-1.324c-2.807-0.386-5.657,0.028-8.448-0.533c0.206-1.265-0.824-1.229-1.52-1.271
          c-1.651-0.105-3.313-0.057-4.969-0.068c-0.847-2.152-1.538-4.352-1.844-6.652c-0.131-0.994-0.491-1.914-0.822-2.856
          c-0.938-2.675-2.455-5.067-3.55-7.664c-0.389-0.919-0.87-1.784-1.392-2.638c-1.699-2.779-4.182-4.928-6.08-7.541
          c-1.244-1.711-2.918-3.092-3.897-4.988c-0.574-1.113-1.218-2.141-2.152-2.982c-0.948-0.854-1.789-1.795-2.474-2.9
          c-0.466-0.75-1.021-1.577-2.079-1.637c-2.946-0.164-5.896-0.396-8.844-0.44c-4.234-0.063-8.463-0.19-12.66-0.774
          c-1.734-0.242-3.444-0.851-5.18-0.9c-4.63-0.137-9.271-0.199-13.89,0.271c-1.396,0.143-2.792,0.063-4.183,0.241
          c-3.123,0.397-6.268,0.651-9.373,1.146c-2.256,0.359-4.535,0.31-6.78,0.64c-2.289,0.336-4.579,0.64-6.891,0.771
          c-1.516,0.084-3.029-0.041-4.55,0.137c-3.245,0.375-6.464,0.941-9.766,0.875c-3.009-0.063-6.007-0.176-9.043,0.375
          c-2.596,0.473-5.198,1.283-7.907,1.041c-0.152-0.016-0.316,0.039-0.472,0.078c-3.713,0.949-7.597,0.748-11.32,1.752
          c-1.756,0.477-3.599,0.727-5.324,1.313c-4.703,1.6-9.359,3.336-14.061,4.946c-1.18,0.405-2.29,0.94-3.389,1.487
          c-2.247,1.117-4.425,2.365-6.092,4.313c-2.299-1.938-5.183-2.707-7.857-3.875c-2.37-1.032-4.879-1.864-6.918-3.451
          c-3.624-2.821-7.229-5.68-10.749-8.627c-4.98-4.174-10.732-6.065-17.056-6.514c-2.299-0.162-4.608-0.063-6.924,0.292
          c-2.476,0.383-4.747,1.127-6.886,2.4c-1.481,0.885-2.568,2.239-3.936,3.231c-1.734,1.263-3.036,2.847-4.229,4.58
          c-1.448,2.104-1.474,4.485-1.441,6.892c-1.867,2.756-2.617,5.877-2.814,9.129c-0.148,2.469,0.661,4.791,1.575,7.043
          c0.688,1.693,1.172,3.437,0.968,5.234c-0.3,2.643-0.579,5.316-2.098,7.645c-1.223,1.875-1.846,4.076-2.951,5.965
          c-1.488,2.543-2.402,5.379-4.188,7.763c-1.64,2.188-2.304,4.885-3.59,7.268c-0.771,1.43-1.11,3.057-1.264,4.701
          c-0.093,1.012,0.297,1.721,1.221,2.103c3.567,1.471,7.121,3.012,10.976,3.588c2.244,0.336,4.496,0.868,6.768,0.713
          c2.631-0.185,5.25-0.455,7.893-0.396c0.558,0.013,1.149,0.063,1.67-0.094c2.861-0.857,5.834-0.332,8.751-0.75
          c2.872-0.41,5.796-0.385,8.527-1.551c2.019,1.004,4.075,1.934,6.044,3.026c3.385,1.88,6.917,3.31,10.684,4.244
          c2.239,0.558,4.57,0.771,6.729,1.57c4.536,1.682,9.269,2.813,13.679,4.85c0.373,0.173,0.721,0.209,0.941-0.168
          c0.246-0.42,0.045-0.729-0.321-1.014c-0.656-0.51-1.278-1.063-1.908-1.604c-2.048-1.758-4.159-3.43-6.65-4.53
          c-4.415-1.949-7.21-5.584-9.824-9.406c1.153-0.521,2.406-0.199,3.597-0.424h-0.047c0.253-0.004,0.506-0.008,0.759-0.012
          c1.34,1.186,2.679,2.368,4.021,3.547c0.502,0.438,0.99,1.01,1.701,0.307c3.396,3.844,9.523,3.588,12.337-0.713
          c1.195-1.826,2.315-3.701,3.47-5.558c0.523-0.282,0.735-0.756,0.8-1.317c4.063-0.312,7.996,0.479,11.938,1.313
          c2.288,0.484,4.616,0.926,6.943,1.039c3.424,0.168,6.863,0.043,10.296,0.01c1.912-0.02,3.815-0.264,5.735-0.172
          c2.59,0.123,5.172-0.147,7.713-0.559c3.068-0.492,6.049-1.369,8.696-3.103c0.896,0.089,0.81-0.954,1.396-1.219
          c0.132,0.01,0.265,0.021,0.396,0.031c-0.048,0.258-0.098,0.516-0.146,0.772c-0.444,1.136-0.44,2.306-0.339,3.494
          c0.038,0.457-0.182,1.035,0.454,1.172c0.556,0.121,0.942-0.354,1.06-0.782c0.261-0.976,0.796-1.789,1.267-2.646
          c0.931,0.168,1.892,0.193,2.766,0.613c0.398-0.004,0.799-0.008,1.196-0.016c0.851,0.334,1.672,0.821,2.554,0.977
          c3.775,0.654,7.354,1.959,10.962,3.172c2.014,0.677,3.777,1.884,5.778,2.58c2.104,0.73,4.282,1.164,6.429,1.729
          c2.562,0.674,4.949,0.537,7.339-0.521c3.495-1.547,7.125-2.742,10.705-4.069c0.655-0.017,0.959,0.436,1.232,0.93
          c0.18,0.324,0.407,0.664,0.812,0.35c0.359-0.283,0.042-0.545-0.14-0.793c-0.298-0.41-0.584-0.828-0.875-1.242
          c2.969-1.461,6.081-2.578,9.174-3.721c2.835-1.051,5.818-1.701,8.634-2.795c3.373-1.313,6.881-1.558,10.403-1.842
          c1.985-0.16,3.973-0.32,5.959-0.478c0.339-0.024,0.639-0.084,0.738-0.469c1.028,0.731,1.384,2.229,2.83,2.514
          c-0.232-0.508-0.57-0.918-0.91-1.324c-0.146-0.176-0.409-0.319-0.315-0.588c0.118-0.338,0.457-0.233,0.717-0.252
          c0.233-0.014,0.471,0.017,0.705,0.027c2.788-0.639,5.677-0.648,8.453-1.354c0.863,0.873,1.728,1.748,2.597,2.615
          c0.181,0.18,0.433,0.295,0.63,0.076c0.191-0.211,0.112-0.465-0.155-0.572c-1.107-0.438-1.567-1.498-2.259-2.334
          c4.521-1.313,8.725-3.465,13.18-4.951c2.973-0.991,5.955-1.735,9.127-1.545c2.017,0.123,4.046,0.564,6.052-0.104
          c0.582,0.17,1.355,1.313,1.583-0.314c1.184-0.235,0.964-1.145,0.939-1.959c0.562-0.561-0.116-0.602-0.349-0.828
          c-0.212-0.551-0.54-1.024-0.957-1.438l-0.036-0.014L307.669,178.504z M312.166,128.998l-0.002,0.032
          c1.088-0.15,1.391-1.028,1.521-1.874c0.171-1.114,0.372-2.336-0.521-3.263c-0.702-0.728-2.229-0.672-3.447,0.12
          c-0.805,0.522-1.511,1.214-1.073,2.359c-0.439,0.719-0.035,1.112,0.553,1.436c0.056,0.628,0.211,1.182,0.961,1.291
          c0.555,0.532,1.149,0.413,1.759,0.113C311.998,129.142,312.082,129.071,312.166,128.998z M148.24,63.975
          c0.168-0.091,0.171-0.192,0.021-0.305c0.001-0.218,0.003-0.438,0.004-0.654c0.287-1.29-0.012-2.171-0.925-2.354
          c-0.854-0.172-1.743-0.24-2.614-0.228c-1.021,0.016-1.671,0.594-1.996,1.585c-0.727,2.22,0.545,3.955,3.113,4.205
          C147.103,66.283,147.85,65.58,148.24,63.975z M315.804,94.96c0.344-0.159,0.384-0.456,0.355-0.784
          c0.223-2.016-0.271-2.959-1.855-3.555c-0.877-0.464-1.759-0.811-2.662-0.06c-0.253,0.53-0.562,1.042-0.748,1.595
          c-0.456,1.339-0.138,2.407,0.8,2.908c0.354,1.1,1.36,1.138,2.213,1.193c0.85,0.056,1.594-0.362,1.894-1.279L315.804,94.96z
           M27.243,129.201l-0.01,0.007c-1.351,0.625-1.771,2.177-0.832,3.065c0.805,0.763,2.405,0.681,3.484-0.18l0.002-0.006
          c0.238-0.074,0.485-0.139,0.481-0.465c0.512-1.092,0.368-2.004-0.43-2.338c-0.537-0.226-1.125-0.334-1.688-0.496
          C27.877,128.835,27.408,128.644,27.243,129.201z M78.857,69.174c0.158,0.007,0.318,0.014,0.479,0.021
          c1.114-0.464,1.529-1.611,1.2-3.315c-0.176-0.911-1.791-1.879-3.132-1.879c-0.872,0-1.646,0.887-1.86,2.133
          c-0.427,2.521,1.054,3.274,2.86,3.049C78.556,69.365,78.709,69.365,78.857,69.174z M297.263,128.527
          c-0.163-0.284-0.363-0.479-0.721-0.297c-0.146-0.024-0.295-0.051-0.441-0.075c-0.563-0.589-1.226-0.249-1.59,0.099
          c-0.591,0.562-0.899,1.37-1.001,2.214c-0.592,1.625-0.536,1.764,1.124,2.84c0.429,0.201,0.84,0.478,1.291,0.588
          c0.637,0.156,1.329,0.355,1.869-0.28C299.05,132.137,298.806,129.817,297.263,128.527L297.263,128.527z M264.85,60.865
          c1.42,1.39,2.487,1.467,3.678,0.265c1.353-0.961,1.25-2.252,0.82-3.617c-0.015-1.424-1.02-2.188-2.557-1.946
          C264.028,56.568,263.237,58.724,264.85,60.865z M275.3,131.268c-0.021-0.112-0.037-0.227-0.059-0.34
          c0.049-0.953-0.632-1.966-1.438-2.142c-1.339-0.292-3.008,0.635-3.483,1.935c-0.607,1.654-0.098,3.062,1.379,3.803
          c2.188,1.097,3.576,0.33,3.497-1.924c0.033-0.436,0.067-0.869,0.103-1.303L275.3,131.268z M314.705,111.051
          c-0.165-1.371-1.007-2.185-2.297-2.222c-1.853-0.055-2.881,1.183-2.836,3.41c0.077,1.095,0.737,1.817,1.556,2.442
          c0.967,0.883,2.149,0.912,3.191,0.421c0.922-0.434,0.892-1.479,0.723-2.414C315.113,112.105,314.895,111.58,314.705,111.051z
           M300.189,56.93c-0.973-0.936-2.143-0.662-3.083-0.181c-0.836,0.427-1.228,1.42-1.218,2.438c0.006,0.688-0.222,1.49,0.738,1.792
          c0.045,0.119,0.092,0.238,0.137,0.357c0.06,0.258,0.232,0.414,0.464,0.519c0.462,0.26,0.979,0.291,1.485,0.347
          c2.249,0.241,3.563-1.38,2.862-3.525C301.365,57.895,300.934,57.287,300.189,56.93z M150.881,127.808
          c-0.602,0.268-0.677,0.835-0.792,1.381c-0.361,1.799,0.721,3.11,2.363,2.878c0.671-0.095,1.064-0.657,1.643-0.925
          c0.813-0.371,1.318-1.033,1.245-1.975c-0.078-0.989-0.757-1.631-1.591-1.905C152.761,126.937,151.705,126.943,150.881,127.808z
           M316.635,57.271c0.016-1.194-0.999-0.933-1.646-1.114c-1.467-0.413-3.257,0.373-3.821,1.606c-0.658,1.44-0.146,2.744,1.479,3.767
          c0.387,0.075,0.771,0.165,1.161,0.226c1.509,0.238,2.701-0.633,2.961-2.157C316.901,58.814,316.678,58.047,316.635,57.271z
           M45.989,128.973c-1.186-0.357-2.313-0.477-3.116,0.746c-0.649,1.707-0.534,2.6,0.427,3.322c1.293,0.971,2.794,0.975,4.049,0.057
          c0.24-0.176,0.405-0.328,0.476-0.625C48.077,131.387,47.071,129.447,45.989,128.973z M131.416,127.63
          c-0.507,0.084-0.861,0.341-0.979,0.866c-0.271,0.278-0.374,0.626-0.404,1.003c-0.486,1.876-0.116,2.812,1.24,2.976
          c0.872,0.105,1.758,0.008,2.57-0.338c0.454-0.193,0.935-0.41,1.089-1.004c0.382-1.457-0.328-2.871-1.818-3.536
          C132.543,127.318,131.971,127.066,131.416,127.63z M225.561,58.926c0.372,1.04,1.06,1.846,1.938,2.479
          c1.106,0.8,3.057,0.447,3.402-0.64c0.619-1.942-0.021-4.199-2.271-5.214c-1.245-0.593-2.077,0.28-2.82,0.966
          C225.139,57.137,225.016,58.056,225.561,58.926z M171.743,130.897c0.762-0.104,1.601,0.041,2.174-0.67
          c0.193-0.135,0.275-0.311,0.374-0.543c0.605-1.439-0.127-2.696-0.462-4.009c-0.316-1.224-0.896-1.646-2.107-1.543
          c-1.838,0.157-3.229,1.409-3.563,3.268c-0.082,0.463-0.438,1.013,0.189,1.398C168.868,130.488,170.237,130.801,171.743,130.897z
           M115.355,133.045c1.166,0.455,1.896-0.23,2.497-1.043c0.576-0.777,1.014-1.644,0.176-2.552c-1.165-1.771-3.39-2.428-5.256-1.536
          c-0.232,0.109-0.303,0.354-0.31,0.605c-0.24,0.962-0.707,1.924,0,2.888C113.04,133.047,113.148,133.108,115.355,133.045z
           M314.315,73.086c-0.394,0.07-0.634,0.313-0.771,0.675c-0.05,0.149-0.1,0.299-0.147,0.448c-0.039-0.041-0.087-0.058-0.146-0.048
          c-1.786,0.743-2.559,2.588-1.467,3.871c0.702,0.827,1.789,1.282,2.886,1.483c0.894,0.164,1.489-0.167,1.958-1.086
          c0.838-1.641,0.401-3.127-0.154-4.661c-0.271-0.884-1.042-0.629-1.657-0.728C314.649,73.056,314.482,73.072,314.315,73.086z
           M192.968,40.519c-0.198-0.278-0.283-0.364-0.33-0.468c-1.956-4.334-4.534-8.138-8.773-10.596
          c-1.693-0.982-3.548-1.377-5.424-1.713c-2.22-0.398-4.29,0.29-6.245,1.197c-1.733,0.807-3.434,1.802-4.596,3.372
          c-2.941,3.977-5.229,8.306-6.832,13c-0.804,2.352-0.641,4.719-0.396,7.104c0.27,2.657,1.333,5.033,2.369,7.475
          c2.199,5.182,5.15,9.809,8.939,13.948c1.141,1.247,2.504,2.224,3.689,3.414c3.247,3.258,6.709,6.277,10.447,8.968
          c1.063,0.768,2.221,1.452,3.045,2.485c1.972,2.469,2.742,5.458,3.51,8.438c0.082,0.319-0.104,0.866,0.406,0.839
          c0.482-0.025,0.326-0.557,0.342-0.888c0.164-3.638,0.951-7.119,2.785-10.289c1.739-3.004,3.533-5.961,5.927-8.542
          c3.337-3.597,6.197-7.627,10.066-10.741c1.36-1.095,2.643-2.316,3.8-3.649c4.442-5.107,7.372-10.949,8.843-17.571
          c0.96-4.325,0.784-8.373-1.8-12.159c-2.019-2.958-4.52-4.973-8.271-5.185c-3.317-0.188-6.545,0.359-9.736,1.063
          c-4.433,0.976-7.515,3.871-9.938,7.56C194.186,38.509,193.621,39.467,192.968,40.519z M327.758,210.906
          c0-1.236,0.007-2.478-0.005-3.715c-0.004-0.396,0.013-0.92-0.546-0.886c-1.101,0.072-2.093-0.489-3.171-0.457
          c-3.95,0.123-7.854-0.762-11.808-0.563c-0.118,0.006-0.243,0.002-0.356-0.029c-2.666-0.727-5.397-0.291-8.097-0.457
          c-0.631-0.039-0.947,0.265-0.946,0.935c0.011,4.301-0.023,8.596-0.424,12.891c-0.242,2.619-0.091,5.271-0.129,7.91
          c-0.006,0.434-0.013,0.889-0.137,1.299c-0.662,2.174,0.169,2.539,1.723,2.844c2.592,0.504,5.2,0.922,7.796,1.422
          c3.37,0.65,6.804,0.976,10.173,1.628c1.095,0.213,2.2,0.271,3.276,0.512c1.209,0.271,1.574,0.055,1.652-1.154
          c0.18-2.813,0.115-5.627,0.355-8.453C327.506,220.076,327.241,215.471,327.758,210.906z M289.265,101.089
          c-0.092-0.001-0.184-0.003-0.274-0.005c-1.161-4.04-2.257-8.1-3.511-12.109c-0.722-2.305-1.244-4.688-2.359-6.862
          c-0.2-0.392-0.414-0.689-0.898-0.611c-0.459,0.073-0.462,0.457-0.566,0.806c-1.145,3.801-2.279,7.606-3.765,11.292
          c-1.145,2.84-2.185,5.718-2.945,8.683c-1.13,4.387-2.685,8.637-4.102,12.93c-0.255,0.771-0.016,1.204,0.624,1.158
          c2.199-0.159,4.238,0.65,6.365,0.896c0.582,0.067,1.156-0.05,1.393-0.667c0.227-0.591,0.425-1.214,0.498-1.839
          c0.363-3.086,0.681-6.179,1.021-9.269c0.036-0.342-0.034-0.791,0.409-0.898c0.416-0.101,0.464,0.349,0.643,0.59
          c0.118,0.16,0.266,0.307,0.351,0.481c2.124,4.364,5.012,8.236,7.815,12.165c0.608,0.853,1.319,0.834,1.809-0.119
          c0.162-0.317,0.322-0.651,0.396-0.996c0.682-3.111,1.339-6.228,2.01-9.34c0.593-2.759,1.137-5.531,1.809-8.271
          c0.586-2.392,1.37-4.733,1.976-7.122c0.907-3.585,1.719-7.193,2.604-10.784c0.382-1.548-0.031-2.36-1.589-2.492
          c-1.789-0.152-3.593-0.112-5.392-0.166c-0.559-0.017-0.863,0.192-1.005,0.771c-0.415,1.703-0.689,3.424-0.926,5.161
          c-0.646,4.774-1.623,9.502-1.939,14.322C289.658,99.566,289.418,100.325,289.265,101.089z M252.936,97.613
          c0.132-3.661-0.206-6.818-1.52-9.873c-1.163-2.708-2.733-5.068-5.33-6.458c-1.794-0.961-3.813-1.807-5.922-0.763
          c-3.893,1.927-6.519,4.805-7.517,9.218c-0.782,3.457-1.71,6.86-2.037,10.411c-0.287,3.125-0.417,6.116,1.011,9.107
          c2.006,4.206,6.214,7.233,10.854,7.098c3.651-0.106,6.728-2.049,8.022-5.468C252.176,106.469,252.975,101.916,252.936,97.613z
           M139.78,107.425c0.921,1.29,1.801,2.56,2.443,4.002c1.993,4.461,5.229,8.103,8.045,12.016c0.201,0.279,0.486,0.396,0.838,0.344
          c0.414-0.063,0.447-0.389,0.517-0.704c0.6-2.761,1.315-5.503,1.782-8.286c0.876-5.23,1.534-10.494,2.114-15.769
          c0.184-1.665,0.252-3.348,0.527-5.001c0.566-3.396,0.866-6.81,0.86-10.254c-0.002-0.714-0.252-1.033-0.972-1.005
          c-1.996,0.082-3.993,0.149-5.99,0.222c-0.875,0.03-1.282,0.44-1.262,1.356c0.028,1.278,0.093,2.574-0.075,3.833
          c-0.59,4.443-0.425,8.929-0.854,13.382c-0.278,2.9-0.344,5.82-0.507,8.73c-0.009,0.157-0.041,0.315-0.307,0.268
          c-0.377-1.271-0.729-2.569-1.146-3.849c-1.202-3.697-2.422-7.389-3.647-11.079c-0.851-2.56-2.168-4.95-2.831-7.574
          c-0.284-1.123-0.568-2.226-1.106-3.261c-0.189-0.366-0.399-0.618-0.799-0.566c-0.404,0.054-0.585,0.364-0.635,0.764
          c-0.146,1.186-0.356,2.366-0.443,3.557c-0.26,3.544-0.293,7.115-0.756,10.63c-0.438,3.317-0.724,6.652-1.217,9.964
          c-0.521,3.48-0.617,6.989-0.036,10.495c0.106,0.657,0.285,0.917,0.957,0.865c1.194-0.092,2.394-0.18,3.59-0.175
          c0.883,0.004,1.225-0.33,1.188-1.207c-0.093-2.156-0.18-4.313-0.191-6.472C139.856,110.922,139.413,109.203,139.78,107.425z
           M109.131,98.343c0.305,0.347,0.168,0.722,0.198,1.062c0.521,6.014,0.203,12.071,0.946,18.076c0.189,1.546,0.59,1.834,2.07,1.197
          c0.667-0.286,1.384-0.611,2.073-0.452c1.586,0.366,3.082,0.005,4.614-0.271c0.854-0.154,0.905-0.512,0.69-1.243
          c-0.722-2.477-1.392-4.968-1.774-7.525c-0.202-1.34-0.404-2.681-0.636-4.017c-0.739-4.278-1.775-8.503-2.269-12.828
          c-0.32-2.813-0.957-5.577-1.567-8.339c-0.087-0.389-0.124-0.944-0.671-1.031c-0.661-0.104-0.583,0.551-0.764,0.892
          c-1.549,2.91-3.247,5.73-5.021,8.51c-0.602,0.939-0.852,0.792-1.278-0.095c-1.104-2.285-2.173-4.583-3.139-6.932
          c-0.227-0.547-0.481-1.091-0.798-1.591c-0.369-0.585-0.783-0.663-1.121,0.066c-0.116,0.253-0.28,0.483-0.396,0.736
          c-3.508,7.647-7.015,15.296-10.519,22.946c-0.513,1.119-1.051,2.229-1.48,3.378c-0.113,0.302-0.646,0.989,0.271,1.086
          c1.785,0.19,3.51,0.656,5.243,1.084c0.948,0.234,1.732,0.194,2.216-0.962c0.854-2.045,1.943-3.99,2.813-6.028
          c1.02-2.385,2.299-4.649,3.165-7.138c0.609,1.733,1.104,3.448,1.571,5.168c0.095,0.347,0.075,0.741,0.512,0.859
          c0.493,0.134,0.737-0.226,0.957-0.555c0.968-1.447,1.909-2.914,2.885-4.355C108.31,99.475,108.469,98.74,109.131,98.343z
           M63.615,100.143c-0.264,2.398,0.825,4.668,1.471,7.038c0.55,2.023,1.808,3.385,3.623,4.326c0.97,0.502,2.034,0.691,3.068,0.958
          c2.194,0.567,4.515,0.88,6.609,0.008c3.832-1.594,6.86-4.184,8.687-8.058c2.35-4.988,2.688-10.063,0.594-15.157
          c-1.148-2.792-3.079-5.011-5.843-6.501c-1.66-0.895-3.457-0.832-5.18-1.225c-2.543-0.581-4.619,0.372-6.426,2.243
          c-2.646,2.741-4.479,5.854-5.529,9.546C64.061,95.531,63.528,97.696,63.615,100.143z M222,82.656c0-0.757,0.229-1.616-0.057-2.245
          c-0.391-0.867-1.399,0.036-2.072-0.335c-0.102-0.056-0.232-0.048-0.352-0.076c-2.715-0.652-5.331-0.296-8.014,0.432
          c-4.203,1.143-6.485,7.73-4.287,11.25c1.537,2.462,3.708,4.154,6.117,5.518c4.005,2.262,5.813,5.561,5.489,10.111
          c-0.105,1.492-0.824,2.621-2.082,3.267c-1.378,0.706-2.9,1.05-4.473,1.133c-1.781,0.093-3.488-0.293-5.184-0.751
          c-1.703-0.46-2.242-0.144-2.321,1.591c-0.07,1.557-0.022,3.119-0.038,4.678c-0.006,0.696,0.27,1.084,0.996,1.253
          c3.452,0.801,6.889,1.437,10.483,1.056c4.226-0.448,7.225-2.464,8.843-6.374c1.324-3.202,1.264-6.512,0.485-9.856
          c-0.929-3.998-2.634-7.418-6.172-9.825c-1.686-1.146-3.279-2.518-4.516-4.201c-1.137-1.549-0.507-3.342,1.236-4.063
          c1.129-0.467,2.129,0.028,3.175,0.099C222.067,85.505,222.064,85.547,222,82.656z M254.333,217.541
          c0-2.797-0.007-5.596,0.004-8.393c0.003-0.656-0.045-1.302-0.923-1.156c-1.706,0.281-3.441,0.061-5.136,0.317
          c-3.001,0.462-6.028,0.568-9.043,0.841c-2.263,0.203-4.51,0.594-6.796,0.553c-0.907-0.016-1.52,0.418-1.412,1.488
          c0.104,1.03,0.221,2.069,0.202,3.104c-0.103,5.834,0.354,11.652,0.479,17.479c0.023,1.119,0.397,1.418,1.471,1.424
          c2.563,0.018,5.096-0.435,7.644-0.471c3.677-0.054,7.314-0.72,10.996-0.574c0.234,0.01,0.474-0.104,0.709-0.104
          c1.211-0.02,1.515-0.735,1.552-1.809C254.226,226.008,254.357,221.778,254.333,217.541z M273.08,97.249
          c0.234-4.576-1.105-8.453-3.183-12.022c-1.201-2.065-3.276-3.436-5.751-3.643c-2.907-0.242-5.034,1.27-6.415,3.712
          c-1.679,2.966-2.563,6.182-3.042,9.586c-0.585,4.14-0.594,8.139,0.85,12.114c1.188,3.273,4.537,5.553,7.999,5.143
          c2.596-0.307,4.636-1.563,6.146-3.773C272.053,104.898,273.246,101.118,273.08,97.249z M123.907,62.398
          c-1.83-2.471-3.352-4.848-5.609-6.638c-1.332-1.055-2.716-1.581-4.189-0.881c-1.357,0.645-2.183,2.029-2.302,3.519
          c-0.221,2.776,0.81,5.247,2.338,7.525c2.647,3.946,6.221,7.168,8.748,11.213c0.497,0.797,1.346,1.367,1.458,2.415
          c0.04,0.369,0.277,0.706,0.713,0.676c0.385-0.025,0.495-0.37,0.545-0.693c0.146-0.929,0.5-1.775,1.075-2.505
          c2.981-3.785,6.069-7.481,8.972-11.339c2.077-2.764,2.603-5.559,1.992-8.763c-0.724-3.797-2.65-5.151-6.473-4.572
          c-0.513,0.078-1.015,0.233-1.528,0.298c-0.927,0.115-1.477,0.636-1.905,1.444C126.352,56.703,124.885,59.269,123.907,62.398z
           M172.651,98.687c-1.273-0.03-2.542-0.354-3.821-0.088c-0.378,0.079-0.771,0.078-0.957,0.57c-0.391,1.046-0.766,2.091-0.91,3.205
          c-0.093,0.709,0.218,1.057,0.922,1.082c2.559,0.09,5.042,0.721,7.563,1.06c0.228,0.03,0.543-0.025,0.6,0.264
          c0.026,0.144-0.131,0.375-0.271,0.488c-2.188,1.803-4.74,2.665-7.527,2.366c-2.568-0.273-3.443-2.418-4.191-4.522
          c-0.752-2.122-0.434-4.264,0.038-6.377c0.732-3.279,2.117-6.156,5.225-7.871c1.482-0.819,3.185-0.938,4.769-1.436
          c1.021-0.321,1.146-0.813,0.667-1.68c-0.559-1.007-1.118-2.006-1.112-3.232c0.004-0.83-0.589-0.666-1.121-0.496
          c-4.063,1.304-7.36,3.66-9.773,7.191c-4.373,6.395-5.729,13.048-1.527,20.086c0.973,1.628,2.019,3.237,3.879,4.054
          c2.24,0.985,4.54,1.66,7.011,1.814c4.258,0.267,7.116-1.844,9.373-5.172c0.951-1.403,1.407-3.021,2.14-4.517
          c0.729-1.489,1.361-3.054,1.604-4.723c0.057-0.388,0.251-0.929-0.416-1.063c-0.664-0.132-1.336-0.359-1.998-0.34
          C179.399,99.459,176.046,98.766,172.651,98.687z M54.617,113.724c1.237,0,2.477,0.001,3.715,0c0.627,0,1.021-0.19,1.145-0.922
          c0.205-1.211,0.527-2.403,0.837-3.594c0.223-0.858-0.265-1.052-0.945-1.114c-4.846-0.445-8.428-2.974-11.17-6.835
          c-1.313-1.85-1.225-4.9,0.058-6.726c1.853-2.641,4.449-3.836,7.59-4.017c0.684-0.039,1.503,0.046,1.545-0.903
          c0.098-2.195,0.099-4.395,0.139-6.592c0.008-0.461-0.214-0.751-0.647-0.505c-0.838,0.476-1.789,0.354-2.668,0.615
          c-2.532,0.751-4.878,1.908-7.129,3.242c-2.296,1.36-4.396,2.999-5.905,5.257c-3.218,4.818-3.646,9.979-1.809,15.392
          c1.166,3.432,3.603,5.566,7.128,6.043C49.172,113.426,51.893,113.688,54.617,113.724z M131.139,99.599c0.114,0,0.229,0,0.343,0
          c0-3.12-0.016-6.24,0.008-9.358c0.009-1.359,0.116-2.718,0.13-4.077c0.006-0.61-0.073-1.229-0.951-1.217
          c-2.319,0.029-4.64,0.034-6.959,0.058c-0.782,0.007-1.132,0.366-1.107,1.198c0.25,8.634,0.482,17.267,0.698,25.901
          c0.02,0.724,0.261,1.021,0.993,1.2c1.688,0.414,3.427,0.268,5.116,0.608c1.514,0.306,1.715,0.162,1.724-1.36
          C131.157,108.236,131.139,103.917,131.139,99.599z M289.49,226.119c-0.041-0.002-0.082-0.002-0.123-0.004
          c-0.329-5.74-0.663-11.48-0.987-17.224c-0.084-1.502-0.157-1.625-1.734-1.541c-5.303,0.279-10.604,0.601-15.905,0.896
          c-1.512,0.084-1.563,0.145-1.558,1.725c0.016,3.316,0.362,6.621,0.454,9.931c0.104,3.877,0.508,7.729,0.646,11.602
          c0.033,0.892,0.366,1.26,1.313,1.256c5.396-0.021,10.771-0.555,16.164-0.715c0.275-0.008,0.556-0.061,0.828-0.117
          c0.685-0.139,0.967-0.496,0.928-1.26C289.436,229.155,289.49,227.635,289.49,226.119z M283.62,62.203
          c1.704,0.009,2.808-0.787,2.802-2.024c-0.008-2.24-0.924-3.835-2.219-3.866c-2.238-0.053-3.927,1.189-3.934,2.896
          C280.264,60.759,281.868,62.195,283.62,62.203z M191.039,124.93c-1.597-0.033-3.228,1.294-3.298,2.685
          c-0.07,1.39,1.644,2.796,3.415,2.807c1.292,0.008,2.568-1.053,2.573-2.137C193.74,126.157,192.783,124.966,191.039,124.93z
           M61.235,64.727c-1.64-0.223-2.768,1.471-2.731,3.151c0.023,1.148,1.191,2.222,2.369,2.17c2.501-0.109,3.313-0.642,3.154-2.244
          C63.861,66.128,62.853,64.508,61.235,64.727z M44.177,65.405c-1.663,0.01-2.891,1.443-2.828,3.302
          c0.027,0.79,1.974,2.114,3.135,2.133c1.029,0.017,2.164-1.829,2.13-3.466C46.592,66.416,45.331,65.397,44.177,65.405z
           M30.701,117.424c0.006-1.317-1.037-2.295-2.485-2.331c-1.328-0.033-2.767,0.984-2.758,1.951c0.012,1.226,1.563,2.678,2.894,2.708
          C29.571,119.781,30.695,118.667,30.701,117.424z M30.103,97.703c0.13-1.708,0.028-1.931-1.898-2.117
          c-1.095-0.106-2.104,0.93-2.092,2c0.017,1.378,1.036,2.391,2.441,2.424C29.61,100.035,30.1,99.303,30.103,97.703z M28.715,67.103
          c-1.036-0.015-2.133,0.917-2.148,1.825c-0.021,1.132,1.041,2.143,2.253,2.143c0.931,0,1.977-1.214,2.017-2.343
          C30.869,67.797,29.988,67.122,28.715,67.103z M99.258,130.747c0.021-1.038-1.378-2.51-2.413-2.542
          c-1.23-0.038-1.942,0.972-1.994,2.83c-0.021,0.771,1.259,1.986,2.122,2.021C98.022,133.094,99.236,131.868,99.258,130.747z"/>
        <path fill="#FDA8A9" d="M115.325,151.075c1.668-1.949,3.846-3.196,6.093-4.313c1.1-0.547,2.209-1.082,3.389-1.488
          c4.699-1.609,9.355-3.348,14.061-4.945c1.727-0.588,3.568-0.838,5.324-1.313c3.725-1.004,7.607-0.804,11.32-1.752
          c0.154-0.039,0.318-0.095,0.472-0.078c2.709,0.242,5.313-0.568,7.908-1.041c3.036-0.551,6.034-0.438,9.042-0.375
          c3.302,0.065,6.521-0.5,9.766-0.875c1.521-0.178,3.034-0.053,4.55-0.137c2.312-0.132,4.602-0.435,6.891-0.771
          c2.246-0.33,4.524-0.279,6.78-0.64c3.105-0.494,6.25-0.748,9.373-1.146c1.391-0.179,2.785-0.101,4.183-0.242
          c4.617-0.473,9.26-0.407,13.89-0.271c1.733,0.053,3.443,0.658,5.18,0.9c4.197,0.584,8.426,0.711,12.66,0.774
          c2.948,0.045,5.896,0.276,8.845,0.44c1.057,0.06,1.613,0.887,2.078,1.637c0.685,1.105,1.523,2.045,2.474,2.9
          c0.938,0.844,1.578,1.869,2.152,2.982c0.979,1.896,2.653,3.277,3.897,4.988c1.899,2.613,4.381,4.762,6.08,7.541
          c0.521,0.854,1.003,1.718,1.392,2.638c1.096,2.597,2.611,4.989,3.551,7.663c0.33,0.943,0.69,1.863,0.821,2.857
          c0.307,2.303,0.997,4.5,1.843,6.652c-0.238-0.014-0.482,0.008-0.715-0.037c-3.358-0.643-6.737-1.049-10.168-0.896
          c-0.067-0.03-0.14-0.035-0.21-0.012c-1.076-0.012-2.152-0.063-3.228-0.023c-1.188,0.044-2.402,0.373-3.551,0.205
          c-1.247-0.182-2.438-0.164-3.666-0.02c-1.317,0.156-2.66,0.271-3.379-1.27c-0.081-0.176-0.393-0.245-0.596-0.363
          c0.067-0.777-0.647-1.119-0.938-1.699c-0.136-2.076-1.354-3.752-2.14-5.576c-0.348-0.807-0.543-1.797-1.387-2.35
          c-0.043-0.191-0.063-0.393-0.132-0.572c-0.702-1.848-2.227-3.18-3.091-4.926c-1.465-3.652-4.101-6.729-5.161-10.58
          c-1.24,0.654-1.075,1.408-0.401,2.34c0.94,1.303,1.465,2.803,1.854,4.359c0.177,0.7,0.582,1.676,1.131,1.713
          c0.95,0.063,0.98,0.658,1.302,1.199c0.281,0.475,0.304,1.098,0.814,1.436c0.467,1.051,1.324,1.916,1.47,3.119
          c-0.172-0.076-0.343-0.152-0.514-0.229l0.008-0.008c0.026,1.213,1.487,2.414-0.287,3.68c0.624-0.047,0.935-0.068,1.242-0.094
          c-0.027,0.318-0.271,0.496-0.466,0.705c-1.842,0.746-3.648,1.594-5.53,2.221c-3.54,1.18-6.974,2.646-10.563,3.705
          c-2.472,0.729-4.924,1.498-7.451,2.004c-3.074,0.615-6.157,1.197-9.166,2.104c-0.476,0.053-0.896-0.346-1.386-0.16
          c-0.132-0.02-0.263-0.039-0.394-0.059c-0.48-0.305-1.015-0.117-1.521-0.156l-0.04-0.008c-0.45-0.227-0.929-0.119-1.396-0.105
          c0.051-0.514,0.188-1.037,0.134-1.539c-0.084-0.801-0.04-1.934-1.154-1.905c-1.285,0.032-1.548-0.577-1.597-1.604
          c-0.006-0.129-0.179-0.248-0.273-0.373c-0.683-1.084-1.267-1.162-1.951-0.256c-0.938,0.104-0.487-0.68-0.51-0.971
          c-0.064-0.912-0.069-1.813-0.472-2.671c-0.258-0.556-0.756-0.911-1.223-0.704c-1.232,0.551-1.208-0.355-1.413-1.057
          c-0.761-1.219-1.188-1.355-2.6-0.85c-0.072,0.088-0.146,0.176-0.22,0.262l-0.006,0.008c-0.515,0.111-0.974,0.189-0.884-0.616
          c0.038-0.345-0.071-0.702-0.115-1.056c-0.181-1.451-0.669-1.838-2.123-1.543c-0.567,0.115-0.704-0.078-0.744-0.572
          c-0.021-0.268-0.142-0.523-0.217-0.785c-0.542-1.848-1.237-2.161-2.663-1.203c-0.075-0.43-0.134-0.859-0.23-1.281
          c-0.382-1.625-1.315-2.688-3-1.746c-0.641,0.356-0.728-0.146-0.866-0.563c-0.382-1.127-1.063-1.828-2.359-1.646
          c-0.251-0.844-0.232-1.896-1.517-1.928c-0.061,0.002-0.115-0.012-0.168-0.041c-0.087-0.07-0.173-0.144-0.26-0.215l0.015,0.02
          c-0.471-0.391-0.95-0.363-1.437-0.027c-0.155,0.037-0.313,0.076-0.469,0.113c-0.003-0.278-0.006-0.559-0.01-0.838
          c0.161-0.24,0.321-0.481,0.481-0.723c0.801,0.305,1.398,0.155,1.677-0.732c0.518,0.012,1.037,0.058,1.552,0.031
          c1.116-0.059,2.259-0.029,3.338-0.271c0.935-0.209,1.814-0.385,2.79-0.315c2.851,0.197,5.705,0.106,8.553-0.133
          c1.6-0.138,3.193-0.071,4.728,0.34c1.896,0.511,3.829,0.449,5.688,0.222c2.276-0.278,4.551-0.67,6.834-0.955
          c0.283-0.035,0.542-0.075,0.788-0.221c0.313-0.184,0.672-0.322,0.625-0.777c-0.039-0.398-0.293-0.644-0.648-0.784
          c-0.462-0.185-0.928-0.362-1.531-0.601c0.741-0.396,1.375-0.697,2.021-0.67c1.351,0.053,2.691,0.061,4-0.263
          c1.862-0.459,3.878-0.728,4.712-2.888c0.304-0.026,0.728,0.463,0.886-0.11c0.111-0.412-0.237-0.649-0.633-0.709
          c-0.234-0.032-0.479-0.004-0.717-0.002c-2.061,0-4.118,0-6.178,0c2.413-0.546,4.874-0.688,7.15-1.694
          c0.647-0.105,1.3-0.207,1.948-0.316c0.354-0.059,0.601-0.25,0.523-0.635c-0.112-0.586-0.487-0.336-0.813-0.218
          c-0.085-0.017-0.17-0.03-0.255-0.049c-0.169-0.086-0.319-0.248-0.541-0.134c-0.711-0.557-1.569-0.125-2.331-0.352
          c-0.643-0.029-1.282,0.268-1.925,0l-0.004-0.004c0.003-0.068,0.003-0.135,0.003-0.201c0.828-0.291,1.688-0.436,2.556-0.555
          c1.072-0.15,2.109-0.414,2.956-1.176c-3.024,0.426-6.004,1.152-9.078,0.984c-0.23,0.041-0.46,0.082-0.689,0.123
          c-1.217,0.186-2.435,0.365-3.648,0.556c-0.317,0.05-0.687,0.099-0.661,0.528c0.024,0.463,0.408,0.554,0.775,0.574
          c0.907,0.053,1.816,0.077,2.727,0.114c0.09,0.002,0.18,0.007,0.269,0.009c0.955,0,1.909-0.003,2.863-0.005
          c0.431-0.008,0.859-0.015,1.289-0.021c0.219,0.012,0.438,0.021,0.656,0.031c0.456,0.004,0.91,0.01,1.366,0.015
          c0.234,0.034,0.512-0.089,0.788,0.196c-1.216,0.391-2.328,0.731-3.55,0.776c-2.311,0.089-4.528,0.744-6.764,1.281
          c-0.631-0.046-1.621,0.034-1.589,0.528c0.055,0.847,1.007,0.385,1.583,0.467c3.188,0.013,6.375,0.023,9.563,0.037
          c0.345,0.002,0.789-0.131,0.913,0.334c0.106,0.398-0.269,0.568-0.563,0.65c-1.796,0.504-3.573,1.227-5.404,1.41
          c-2.967,0.297-5.904,0.803-8.874,1.059c-0.391,0.035-1.067-0.203-1.092,0.365c-0.022,0.521,0.64,0.467,1.063,0.5
          c1.624,0.129,3.25,0.244,4.874,0.369c0.357,0.026,0.726,0.059,0.784,0.817c-2.545-0.854-5.016,0.716-7.522-0.155
          c-0.523-0.205-1.054-0.359-1.608-0.119c-1.437-0.185-2.85-0.146-4.255,0.27c-0.68,0.199-1.39,0.381-2.116,0.24
          c-2.677-0.52-5.402,0.033-8.087-0.371c-0.424-0.063-0.896-0.049-1.303,0.072c-1.92,0.567-3.923,0.422-5.877,0.68
          c-1.854,0.244-3.763,0.121-5.597,0.629c-2.592,0.722-5.322,0.797-7.868,1.728c-0.054-0.041-0.111-0.065-0.179-0.078
          c-0.152-0.161-0.346-0.144-0.54-0.116c-1.122,0.049-2.171,0.334-3.106,0.973c-1.03-0.168-1.96,0.279-2.926,0.49
          c-0.427,0.092-0.885,0.338-1.273,0.069c-0.82-0.562-1.812-0.373-2.392,0.005c-1.031,0.674-2.123,0.741-3.229,0.977
          c-1.581,0.334-2.924,1.494-4.655,1.369c-0.186-0.015-0.447,0.192-0.57,0.369c-0.352,0.504-0.85,0.53-1.379,0.539
          c-1.141,0.016-2.196,0.305-3.121,1c-0.167,0.082-0.334,0.164-0.501,0.245l0.008,0.013c-0.892,0.17-1.795,0.184-2.688,0.438
          c-2.436,0.695-4.896,1.293-7.414,1.627c-1.11,0.146-1.809,0.754-1.813,1.961c-1.399,0.939-1.051,2.4-0.979,3.702
          c0.03,0.585-0.193,2.052,1.354,1.159c0.111-0.063,0.406,0.189,0.614,0.301c0.707,0.372,1.045,1.088,1.503,1.678
          c0.98,1.264,1.993,2.623,2.275,4.164c0.521,2.834-0.665,5.283-3.148,6.979c-1.156,0.791-2.298,1.658-3.968,1.986
          c1.071,0.445,1.681-0.102,2.328-0.393c2.313-1.037,4.324-2.417,5.232-4.938c1.314-3.643-0.808-8.951-4.301-10.815
          c-1.465-0.782-1.6-1.677-0.453-2.997c1.815,0.875,3.811,1.072,5.741,1.492c2.388,0.521,4.834,0.438,7.255,0.627
          c3.983,0.313,7.903,0.867,11.894-0.533c4.372-1.535,8.279-3.646,11.543-6.93c1.666,0.476,0.179,1.164,0.118,1.757
          c-0.158,1.147,0.511,1.761,1.431,2.212c-0.376,1.277-0.203,2.456,0.763,3.418c0.61,0.609,0.253,0.922-0.27,1.238
          c-0.729,1.063-0.422,1.961,0.434,2.775c0.104,0.215,0.526,0.334,0.219,0.674c-0.923,1.015-0.992,1.892-0.241,2.965
          c0.288,0.41,0.843,0.845,0.619,1.328c-0.479,1.043-0.085,1.863,0.417,2.697c0.233,0.389,0.36,0.766-0.105,1.021
          c-1.213,0.676-1.036,1.648-0.63,2.685c0.207,0.527,0.363,1.057,0.27,1.631c-0.949,0.172-1.412,0.629-0.949,1.627
          c-0.119,0.674,0.641,1.17,0.438,1.818c-0.372,1.188-0.058,2.287,0.322,3.381c0.128,0.369,0.283,0.729-0.167,0.916
          c-1.337,0.551-0.779,1.541-0.587,2.436c0.063,0.308,0.418,0.72,0.32,0.857c-0.788,1.127,0.055,1.955,0.466,2.842
          c0.196,0.424,0.093,0.732-0.208,0.943c-0.977,0.684-0.545,1.36-0.021,2.02c0.441,0.551,0.939,1.058,1.412,1.582
          c-2.646,1.732-5.628,2.609-8.695,3.104c-2.541,0.405-5.123,0.682-7.713,0.559c-1.921-0.092-3.824,0.152-5.736,0.172
          c-3.434,0.033-6.871,0.158-10.296-0.01c-2.326-0.113-4.654-0.557-6.942-1.039c-3.942-0.834-7.876-1.625-11.938-1.316
          c-0.127-2.819-0.992-5.471-1.751-8.317c-0.505,0.738-0.509,1.363-0.292,1.91c0.46,1.164,0.491,2.399,0.764,3.59
          c0.313,1.373-0.032,2.805,0.479,4.139c-1.154,1.854-2.274,3.729-3.47,5.556c-2.813,4.303-8.942,4.559-12.336,0.713
          c-1.564-1.933-3.397-3.576-5.391-5.056c-0.583-0.432-1.169-0.893-2.004-0.598c0.402,0.832,1.276,1.086,1.669,1.801
          c-0.253,0.004-0.506,0.009-0.759,0.015l0.047-0.002c-1.188,0.225-2.441-0.101-3.597,0.42c-0.146-0.129-0.333-0.23-0.436-0.394
          c-2.209-3.516-5.298-6.098-8.759-8.295c-3.123-1.982-6.023-4.271-8.961-6.512c0.083-0.586,0.166-1.17,0.25-1.756
          c1.047-0.021,2.058,0.403,3.115,0.266c2.441,0.051,4.892-0.025,7.461,0.4c-0.94,0.206-1.146,0.309-1.078,0.518
          c0.131,0.414,0.485,0.324,0.799,0.307c3.171-0.184,6.04-1.594,9.154-2.155c-1.022-0.636-2.095-0.275-3.104-0.445
          c0.237-0.105,0.479-0.207,0.5-0.521c1.081-1.241,1.938-2.582,1.891-4.317c-0.03-0.275,0.145-0.443,0.317-0.613
          c0.691-2.35,1.063-4.751,1.185-7.218c0.585,1.03,0.789,2.168,1.178,3.234c0.472,1.296,0.442,2.771,1.371,3.901l0.001,0.004
          c-0.005,0.533,0.003,1.063,0.458,1.449l0.002,0.008c0.017,0.67,0.146,1.312,0.521,1.881l0.002,0.011
          c-0.053,0.549,0.075,1.032,0.5,1.413l0.004,0.011c0.082,0.688,0.353,1.274,0.921,1.701c1.33,2.034,2.663,4.069,3.99,6.106
          c0.336,0.516,0.781,0.912,1.387,0.584c0.704-0.385,0.478-1.029,0.168-1.563c-2.787-4.795-3.365-10.119-3.536-15.483
          c-0.113-3.545,0.229-7.104,0.34-10.658c0.021-0.668,0.229-0.807,0.875-0.668c1.377,0.295,2.708,0.683,3.939,1.383
          c3.671,2.093,7.008,4.63,9.816,7.777c1.938,2.168,4.296,3.809,6.464,5.688c0.313,0.272,0.681,0.477,1.067,0.123
          c0.354-0.318,0.175-0.669-0.033-0.97c-1.542-2.233-2.866-4.669-4.692-6.642c-2.864-3.091-4.628-6.66-5.728-10.636
          c-0.466-1.688-1.27-3.153-2.415-4.483C117.921,153.426,116.594,152.284,115.325,151.075z M258.904,141.553
          c-2.921-0.178-3.648-0.088-4.736,0.67c1.893-0.373,3.813,0.431,5.695-0.238c0.343-0.049,0.721,0.125,1.063-0.202
          C260.289,141.238,259.586,141.473,258.904,141.553z M117.481,199.582c-1.424,0.008-1.999,0.617-1.978,2.092
          c0.023,1.547,0.717,2.449,1.866,2.429c1.196-0.021,2.462-1.451,2.471-2.783C119.848,200.193,119.007,199.574,117.481,199.582z
           M137.116,169.725c-0.013-1.188-0.689-1.955-1.67-2.416c-1.042-0.489-1.694,0.066-1.557,1.229c0.107,0.891,1.26,2.084,2.119,2.184
          C136.755,170.809,137.145,170.49,137.116,169.725z M254.534,143.092c-0.053,0.199,0.078,0.301,0.224,0.367
          c2.101,0.949,4.317,0.971,6.558,0.826c0.112-0.009,0.235-0.064,0.326-0.134c0.225-0.171,0.476-0.378,0.326-0.687
          c-0.146-0.302-0.473-0.345-0.717-0.162c-0.347,0.254-0.702,0.237-1.103,0.28C258.235,143.799,256.428,142.969,254.534,143.092z
           M166.024,178.443c-0.478,0.009-0.942-0.098-1.246,0.324c-0.137,0.191-0.023,0.408,0.151,0.508
          c0.844,0.48,1.607,1.162,2.687,1.052c0.319-0.033,0.658-0.007,0.712-0.441c0.04-0.34-0.035-0.676-0.357-0.813
          C167.327,178.803,166.753,178.291,166.024,178.443z M266.093,154.508c-0.053,0.131-0.104,0.26-0.155,0.391
          c0.896,0.406,1.782,0.836,2.688,1.214c2.125,0.886,2.267,0.909,2.65,0.64C269.594,155.823,267.826,155.207,266.093,154.508z
           M113.687,182.045c1.728-0.51,2.328-1.707,1.813-3.162c-0.094-0.266-0.185-0.553-0.546-0.529
          c-0.345,0.021-0.514,0.244-0.447,0.568C114.74,180.074,114.806,181.18,113.687,182.045z M253.333,153.008
          c-0.229-1.133-0.444-2.217-0.668-3.299c-0.041-0.195-0.11-0.41-0.354-0.404c-0.174,0.004-0.271,0.166-0.247,0.338
          c0.15,1.176,0.302,2.353,0.475,3.525c0.04,0.266,0.267,0.399,0.529,0.354C253.399,153.467,253.34,153.192,253.333,153.008z
           M256.877,148.957c0.245,0.715,1.074,0.572,1.225,1.215c0.205,0.887,1.021,1.406,1.397,2.195c0.091,0.188,0.212,0.379,0.439,0.287
          c0.212-0.085,0.177-0.328,0.206-0.523c0.078-0.525-0.35-0.807-0.613-1.105C258.813,150.209,258.258,149.135,256.877,148.957z
           M256.625,157.735c0.847,1.363,1.818,2.289,2.708,3.281c0.149,0.168,0.423,0.334,0.623,0.092c0.189-0.229-0.021-0.434-0.185-0.607
          c-0.401-0.438-0.792-0.886-1.208-1.309C258.061,158.678,257.753,157.918,256.625,157.735z M264.648,165.369
          c0.518,1.053,1.205,2,1.659,3.07c0.082,0.193,0.271,0.309,0.486,0.207c0.207-0.094,0.271-0.295,0.188-0.5
          C266.51,166.977,265.771,166.022,264.648,165.369z M251.179,159.768c0.291,1.186,0.241,2.539,1.668,3.621
          C252.567,161.834,252.047,160.745,251.179,159.768z M273.246,164.344c-0.927-0.731-1.835-1.5-3.115-1.469
          C271.07,163.579,272.058,164.176,273.246,164.344z M184.483,147.581c0.027-0.02,0.056-0.035,0.082-0.054
          c-0.034-0.046-0.064-0.093-0.104-0.132c-0.005-0.004-0.051,0.031-0.077,0.051C184.417,147.491,184.45,147.536,184.483,147.581z
           M188.345,149.311c-0.031,0.004-0.091,0.008-0.092,0.017c-0.003,0.056,0.006,0.11,0.011,0.166
          c0.031-0.004,0.091-0.009,0.091-0.017C188.357,149.422,188.349,149.366,188.345,149.311z M186.961,148.954
          c-0.005-0.033-0.01-0.092-0.017-0.092c-0.056-0.003-0.11,0.006-0.167,0.01c0.005,0.031,0.009,0.09,0.017,0.092
          C186.848,148.965,186.905,148.957,186.961,148.954z"/>
        <path fill="#FEFEFE" d="M192.968,40.519c0.652-1.053,1.22-2.011,1.829-2.938c2.424-3.688,5.506-6.583,9.938-7.56
          c3.191-0.703,6.419-1.25,9.736-1.063c3.751,0.212,6.252,2.227,8.271,5.185c2.584,3.786,2.76,7.834,1.8,12.159
          c-1.471,6.622-4.398,12.464-8.843,17.571c-1.157,1.333-2.438,2.555-3.8,3.649c-3.869,3.114-6.729,7.145-10.066,10.741
          c-2.394,2.58-4.188,5.537-5.927,8.542c-1.834,3.169-2.62,6.651-2.785,10.289c-0.016,0.331,0.143,0.862-0.342,0.888
          c-0.511,0.027-0.324-0.52-0.406-0.839c-0.768-2.979-1.538-5.969-3.51-8.438c-0.824-1.034-1.979-1.719-3.045-2.485
          c-3.739-2.69-7.2-5.71-10.448-8.968c-1.187-1.189-2.55-2.167-3.688-3.414c-3.789-4.14-6.74-8.767-8.939-13.948
          c-1.036-2.441-2.102-4.816-2.369-7.475c-0.242-2.387-0.405-4.753,0.396-7.104c1.604-4.693,3.891-9.023,6.832-13
          c1.162-1.57,2.86-2.565,4.596-3.372c1.955-0.908,4.025-1.596,6.245-1.197c1.876,0.336,3.729,0.73,5.424,1.713
          c4.239,2.458,6.817,6.262,8.773,10.596C192.683,40.156,192.768,40.241,192.968,40.519z"/>
        <path fill="#E01F14" d="M81.988,186.127c2.937,2.24,5.838,4.529,8.961,6.512c3.461,2.197,6.55,4.779,8.759,8.296
          c0.101,0.159,0.287,0.264,0.435,0.395c2.615,3.824,5.41,7.459,9.825,9.408c2.491,1.104,4.604,2.772,6.65,4.531
          c0.63,0.54,1.252,1.094,1.907,1.604c0.367,0.283,0.568,0.594,0.322,1.014c-0.222,0.377-0.568,0.34-0.941,0.168
          c-4.41-2.032-9.143-3.168-13.679-4.85c-2.158-0.803-4.489-1.016-6.729-1.57c-3.767-0.938-7.299-2.364-10.684-4.244
          c-1.969-1.094-4.025-2.022-6.044-3.026c-0.274-0.316-0.664-0.429-1.033-0.574c-2.707-1.076-5.42-2.137-8.117-3.236
          c-3.671-1.498-6.839-3.656-9.49-6.678c-1.319-1.506-2.114-3.256-3.173-4.877c0.016-0.662-0.12-1.267-0.668-1.709
          c-0.079-0.429-0.132-0.863-0.241-1.285c-0.752-2.918-1.144-5.871-0.916-8.889c0.15-1.988,0.385-3.974,0.467-5.964
          c0.088-2.1,0.621-4.252,0.072-6.297c-0.774-2.887-1.431-5.799-2.122-8.703c0.041-0.541-0.055-1.043-0.398-1.479
          c-0.171-1.245-1.067-0.413-1.6-0.5c-0.646-0.104-0.81,0.438-0.841,1.031c-0.177,3.47-0.547,6.938,0.243,10.388
          c0.104,0.465,0.167,0.944,0.205,1.422c0.028,0.359,0.016,0.756-0.447,0.861c-0.494,0.109-0.62-0.271-0.708-0.621
          c-0.165-0.664-0.414-1.285-0.71-1.902c-2.21-4.592-2.878-9.442-2.205-14.459c0.22-1.638,0.292-3.252,0.24-4.89
          c-0.031-2.401-0.007-4.782,1.441-6.89c1.192-1.733,2.495-3.317,4.229-4.579c1.366-0.992,2.453-2.349,3.936-3.231
          c2.139-1.273,4.411-2.018,6.886-2.4c2.313-0.355,4.625-0.454,6.924-0.292c6.322,0.448,12.074,2.341,17.056,6.515
          c3.52,2.947,7.125,5.806,10.749,8.627c2.039,1.586,4.548,2.418,6.918,3.451c2.676,1.168,5.56,1.937,7.856,3.875
          c1.271,1.209,2.598,2.353,3.751,3.688c1.146,1.33,1.948,2.795,2.415,4.484c1.098,3.975,2.86,7.545,5.727,10.635
          c1.827,1.973,3.152,4.408,4.693,6.643c0.208,0.302,0.386,0.648,0.032,0.97c-0.389,0.352-0.754,0.149-1.067-0.123
          c-2.167-1.881-4.527-3.521-6.463-5.689c-2.812-3.147-6.146-5.686-9.817-7.776c-1.229-0.7-2.563-1.089-3.938-1.384
          c-0.646-0.139-0.854,0-0.875,0.668c-0.11,3.554-0.453,7.113-0.341,10.658c0.171,5.363,0.749,10.689,3.535,15.484
          c0.311,0.531,0.537,1.176-0.168,1.561c-0.604,0.328-1.05-0.067-1.386-0.584c-1.327-2.037-2.66-4.071-3.991-6.106
          c-0.006-0.729-0.445-1.226-0.92-1.699l-0.004-0.012c-0.025-0.521-0.062-1.039-0.499-1.414l-0.002-0.011
          c-0.023-0.668,0.113-1.381-0.52-1.881l-0.003-0.008c0.021-0.539,0.045-1.076-0.458-1.449l-0.001-0.004
          c-0.381-2.469-1.267-4.802-1.951-7.188c0.028-0.269,0.007-0.519-0.249-0.683c-0.082-0.239-0.164-0.479-0.245-0.721
          c-0.726-2.201-1.679-4.304-2.744-6.357c-0.116-0.493-0.063-1.045-0.499-1.428c-0.486-1.819-1.225-3.519-2.411-4.998
          c-0.409-0.571-0.817-1.143-1.227-1.713c-0.059-0.082-0.113-0.164-0.169-0.246c0.005-0.076-0.029-0.125-0.105-0.145l-0.018-0.037
          c-0.069-0.088-0.141-0.176-0.208-0.268l0.011,0.01c-0.219-0.55-0.547-1.021-0.979-1.422l-0.007-0.01
          c-0.074-0.09-0.147-0.182-0.223-0.271l0.029,0.01c-2.407-3.414-5.691-5.789-9.384-7.559c-2.98-1.432-6.138-2.492-8.95-4.279
          c-0.967-0.615-1.785-1.471-3.062-1.863c0.215,1.517,0.845,2.804,1.311,4.138c0.142,0.405,0.443,0.883-0.053,1.182
          c-0.493,0.302-0.778-0.163-1.064-0.502c-1.028-1.213-2.14-2.346-3.405-3.351c0.532,1.492,1.168,2.929,1.727,4.396
          c0.243,0.642,0.753,1.515,0.051,1.982c-0.787,0.526-1.206-0.449-1.713-0.9c-1.033-0.92-2.064-1.842-3.098-2.762
          c-0.084,0.078-0.167,0.153-0.251,0.231c0.576,1.31,1.245,2.584,1.564,3.986c0.157,0.688,1.042,1.537,0.241,2.031
          c-0.809,0.498-1.354-0.559-1.887-1.094c-0.941-0.949-1.818-1.967-2.747-2.979c-0.24,0.354-0.051,0.572,0.021,0.772
          c0.702,1.951,1.521,3.859,2.021,5.882c0.096,0.381,0.396,0.784-0.007,1.094c-0.441,0.343-0.821,0.004-1.166-0.28
          c-0.185-0.152-0.345-0.343-0.547-0.468c-1.184-0.729-2.188-1.764-3.727-2.348c0.188,0.614,0.293,0.991,0.424,1.36
          c1.415,3.996,2.477,8.08,3.114,12.277c0.2,1.33,0.141,2.719,0.764,3.971c0.138,0.871,0.5,1.647,0.986,2.373l0.001,0.006
          c-0.001,0.205,0.035,0.396,0.218,0.523c0.033,0.071,0.064,0.146,0.1,0.221c0.049,0.086,0.1,0.174,0.146,0.26
          c0.018,0.271,0.137,0.49,0.33,0.675c0.212,0.735,0.548,1.401,1.102,1.946l-0.006-0.004c0.329,0.824,0.875,1.504,1.458,2.158
          c0.062,0.082,0.123,0.164,0.184,0.244c0.586,0.873,1.236,1.682,2.188,2.188l-0.009-0.039c0.094,0.084,0.187,0.168,0.278,0.253
          l-0.019-0.009c0.885,1.031,1.88,1.938,2.976,2.748c1.105,0.816,2.489,1.388,3.396,2.234c0.921,0.859,1.931,1.396,2.929,2.043
          c0.097,0.063,0.191,0.133,0.286,0.197C81.101,185.594,81.514,185.898,81.988,186.127z"/>
        <path fill="#FDA8A9" d="M265.855,190.918c-0.102,0.385-0.399,0.442-0.738,0.469c-1.986,0.154-3.974,0.314-5.959,0.478
          c-3.522,0.284-7.03,0.528-10.403,1.842c-2.813,1.094-5.799,1.744-8.634,2.795c-3.093,1.143-6.205,2.26-9.173,3.719
          c-0.938-0.135-1.46-1.193-2.565-1.285c0.425,0.892,1.51,1.062,1.535,2.046c-3.58,1.327-7.21,2.522-10.705,4.069
          c-2.39,1.06-4.777,1.195-7.339,0.521c-2.146-0.563-4.325-0.996-6.429-1.729c-2.001-0.696-3.767-1.903-5.778-2.58
          c-3.608-1.213-7.187-2.518-10.962-3.172c-0.882-0.152-1.703-0.641-2.553-0.975c-0.112-0.398-0.545-0.664-0.477-1.14
          c0.164-0.908,0.447-1.412,1.587-1.016c1.21,0.424,1.725-0.008,1.698-1.324c-0.021-1.049,0.255-1.539,1.351-1.221
          c0.901,0.264,1.309-0.277,1.684-0.955c0.281-0.508,0.133-1.084,0.274-1.633c1.621,0.981,2.097,0.78,2.272-0.97
          c0.063-0.629,0.271-0.936,0.943-0.94c0.872-0.012,1.307-0.479,1.211-1.387c-0.032-0.314-0.021-0.644,0.249-0.886
          c1.285,0.648,1.448,0.603,2.049-0.696c0.115-0.252,0.111-0.484,0.535-0.443c1.864,0.182,2.02,0.031,2.134-1.91
          c0.033-0.578,0.146-0.949,0.88-0.94c0.882,0.01,1.607-0.357,1.873-1.308c0.289-0.399,0.288-0.795-0.02-1.184
          c-0.009-0.082-0.02-0.162-0.026-0.242c0.624,0,1.25-0.023,1.872,0.006c0.747,0.035,1.111-0.256,1-1.031
          c0.136-0.955-0.04-1.836-0.629-2.616c0.322-0.249,0.646-0.497,0.967-0.745c1.021-0.05,2.024-0.03,3.04,0.218
          c2.185,0.533,4.218,1.451,6.271,2.324c0.075,0.002,0.15,0.004,0.228,0.006c0.021-0.051,0.059-0.068,0.109-0.053l0.002-0.003
          c0.206,0.093,0.412,0.185,0.618,0.273c1.274,0.979,2.355,2.195,3.728,3.059c0.313,0.351,0.545,0.757,0.907,1.082
          c2.052,1.843,2.04,1.854,0.174,3.771c-1.752,1.27-3.505,2.539-5.063,4.053c1.044-0.055,1.855-0.572,2.478-1.27
          c0.961-1.078,2.18-1.654,3.479-2.142c1.438-1.039,2.92-2.002,4.559-2.7c1.248-0.765,2.663-1.22,3.85-2.097
          c1.385-0.809,2.838-1.465,4.306-2.11c1.643-0.726,3.268-1.394,5.075-1.505c0.213,0.019,0.369-0.073,0.483-0.25
          c0.234-0.002,0.472-0.004,0.705-0.004c0.72-0.256,1.576-0.057,2.186-0.672c1.104,0.164,2.075-0.43,3.127-0.602
          c1.852-0.301,3.78-0.306,5.529-1.125c0.171,0.145,0.361,0.114,0.563,0.09c4.811-0.588,9.635-0.99,14.479-1.172
          c0.146,0.16,0.322,0.17,0.516,0.096c1.156,0.029,2.334-0.063,3.47,0.111c4.453,0.676,8.937,0.932,13.428,1.09
          c2.791,0.562,5.645,0.148,8.448,0.533c2.712,0.373,5.433,0.666,8.1,1.324c1.232,0.303,2.498,0.475,3.747,0.706
          c-0.172,0.154-0.314,0.392-0.514,0.451c-0.534,0.162-0.667,0.494-0.552,0.988c0.144,0.609,0.597,0.627,1.104,0.605
          c0.739-0.029,1.198-0.451,1.566-1.027c0.417,0.412,0.745,0.887,0.955,1.436c-0.469,0.033-0.939,0.052-1.404,0.107
          c-0.322,0.041-0.62,0.172-0.646,0.563c-0.023,0.349,0.249,0.447,0.505,0.566c0.736,0.342,1.362,0.191,1.896-0.408
          c0.022,0.814,0.242,1.724-0.938,1.959c-0.808-0.903-1.201-0.826-1.583,0.314c-2.006,0.668-4.036,0.227-6.053,0.104
          c-3.172-0.188-6.153,0.554-9.126,1.545c-4.456,1.486-8.658,3.642-13.18,4.949c-0.308-0.067-0.696-0.44-0.813,0.217
          c-2.777,0.705-5.665,0.716-8.449,1.355c-1.974,0.549-2.827-1.16-4.131-2.016C265.121,189.826,265.488,190.371,265.855,190.918z
           M238.326,182.121c1.231-0.59,2.364-0.228,3.483-0.289c0.43-0.025,0.891,0.004,1.235-0.314c0.179-0.166,0.257-0.412,0.146-0.653
          c-0.104-0.23-0.35-0.265-0.484-0.105c-0.914,1.057-2.138,0.527-3.227,0.633C239.019,181.434,238.556,181.303,238.326,182.121z
           M267.614,183.81c-0.03,0.113-0.062,0.229-0.093,0.34c1.404,0.48,2.81,0.961,4.213,1.443c0.04-0.117,0.079-0.234,0.119-0.352
          C270.592,184.344,269.073,184.16,267.614,183.81z M258.161,180.781c1.487-0.063,2.651-0.861,4.115-1.81
          C260.346,178.834,259.521,180.41,258.161,180.781z M234.486,193.221c1.425,0.383,2.521,0.592,3.559-0.168
          c0.162-0.119,0.323-0.26,0.211-0.486c-0.105-0.213-0.341-0.266-0.5-0.143C236.878,193.098,235.786,192.881,234.486,193.221z
           M253.534,185.869c1.198,0.366,2.397,0.735,3.599,1.104c0.025-0.098,0.055-0.192,0.081-0.291
          C256.057,186.17,254.975,185.416,253.534,185.869z M245.454,186.281c-1.085-0.841-2.186,0.018-3.293-0.002
          C243.245,186.707,244.338,186.459,245.454,186.281z M276.111,177.935c-1.298-0.233-2.364,0.584-3.587,0.697
          C273.818,178.906,274.98,178.48,276.111,177.935z M230.469,190.248c0.603-0.953,2.029-0.439,2.618-1.558
          C231.678,188.545,230.677,189.109,230.469,190.248z M277.928,184.318c1.193,0.375,2.229-0.12,3.277-0.549
          C280.028,183.489,279.023,184.155,277.928,184.318z M245.723,190.91c0.303,0.844,0.674,1.348,1.382,1.557
          c0.244,0.072,0.481,0.15,0.591-0.141c0.114-0.306-0.015-0.451-0.357-0.572C246.832,191.578,246.376,191.262,245.723,190.91z
           M250.295,180.199c0.79-0.519,1.268-1.031,1.429-1.843C250.698,178.65,250.666,178.689,250.295,180.199z M285.094,178.768
          c0.816,0.179,1.623,0.252,2.671-0.375C286.62,178.182,285.87,178.586,285.094,178.768z"/>
        <path fill="#FDA8A9" d="M55.55,156.149c0.691,2.904,1.348,5.816,2.122,8.703c0.549,2.045,0.016,4.197-0.072,6.297
          c-0.081,1.99-0.315,3.977-0.467,5.962c-0.228,3.021,0.164,5.974,0.916,8.892c0.108,0.422,0.162,0.854,0.241,1.285
          c-0.976,3.578-2.558,6.817-5.301,9.395c-0.434,0.404-0.988,0.683-1.398,1.105c-0.27,0.274-0.802,0.805-0.45,1.055
          c0.583,0.414,0.416-0.404,0.636-0.637c0.051-0.058,0.146-0.064,0.21-0.107c2.632-1.828,4.603-4.197,5.95-7.11
          c0.312-0.673,0.428-1.46,1.021-1.99c1.059,1.621,1.854,3.371,3.173,4.877c2.651,3.021,5.819,5.18,9.49,6.678
          c2.697,1.101,5.41,2.16,8.117,3.236c0.371,0.146,0.759,0.258,1.033,0.574c-2.73,1.166-5.655,1.141-8.527,1.551
          c-2.917,0.418-5.891-0.107-8.751,0.75c-0.521,0.156-1.112,0.105-1.67,0.094c-2.643-0.059-5.262,0.213-7.893,0.396
          c-2.271,0.156-4.522-0.377-6.768-0.713c-3.854-0.576-7.406-2.117-10.976-3.588c-0.924-0.382-1.313-1.091-1.221-2.103
          c0.152-1.646,0.491-3.271,1.264-4.701c1.286-2.383,1.951-5.08,3.59-7.266c1.784-2.386,2.698-5.222,4.188-7.765
          c1.105-1.889,1.729-4.09,2.951-5.965c1.52-2.328,1.798-5.003,2.098-7.645c0.204-1.801-0.279-3.541-0.968-5.234
          c-0.914-2.252-1.725-4.574-1.575-7.043c0.197-3.252,0.947-6.373,2.814-9.131c0.052,1.637-0.021,3.252-0.24,4.889
          c-0.673,5.017-0.005,9.867,2.205,14.459c0.297,0.617,0.546,1.238,0.71,1.902c0.088,0.352,0.213,0.73,0.708,0.621
          c0.462-0.105,0.477-0.502,0.447-0.861c-0.038-0.477-0.099-0.957-0.205-1.422c-0.79-3.447-0.42-6.918-0.243-10.388
          c0.031-0.595,0.194-1.138,0.841-1.03c0.531,0.086,1.429-0.746,1.601,0.5c-1.474-0.322-1.557-0.267-1.453,0.979
          c-0.216,0.938-0.288,1.869-0.001,2.805c0.064,0.215,0.016,0.516,0.325,0.57c0.321,0.061,0.491-0.17,0.671-0.396
          c0.662-0.854,0.393-1.816,0.356-2.754C55.218,155.965,55.384,156.057,55.55,156.149z M56.587,163.529
          c-0.637-0.356-1.266-0.011-1.86-0.104c-0.189-0.029-0.427,0.021-0.431,0.281c-0.003,0.243,0.224,0.338,0.412,0.342
          C55.354,164.059,56.043,164.186,56.587,163.529z M54.786,165.381c0.676,0.133,1.278,0.266,1.856-0.088
          c0.049-0.029,0.049-0.279-0.002-0.313c-0.613-0.385-1.177-0.008-1.754,0.152C54.867,165.141,54.861,165.198,54.786,165.381z"/>
        <path fill="#DF2015" d="M289.265,101.089c0.151-0.764,0.393-1.523,0.443-2.294c0.317-4.82,1.295-9.548,1.94-14.322
          c0.234-1.736,0.511-3.458,0.925-5.161c0.143-0.579,0.447-0.789,1.006-0.771c1.798,0.053,3.603,0.014,5.392,0.166
          c1.558,0.132,1.971,0.944,1.589,2.492c-0.885,3.591-1.695,7.199-2.604,10.784c-0.604,2.388-1.39,4.73-1.976,7.122
          c-0.672,2.74-1.216,5.513-1.81,8.271c-0.671,3.113-1.327,6.229-2.009,9.34c-0.075,0.345-0.234,0.678-0.396,0.996
          c-0.487,0.953-1.198,0.972-1.809,0.119c-2.806-3.929-5.691-7.8-7.815-12.165c-0.085-0.176-0.23-0.322-0.351-0.481
          c-0.179-0.241-0.227-0.69-0.643-0.59c-0.443,0.107-0.373,0.559-0.41,0.898c-0.34,3.09-0.655,6.182-1.021,9.269
          c-0.073,0.625-0.272,1.248-0.498,1.839c-0.234,0.617-0.811,0.733-1.393,0.667c-2.127-0.246-4.166-1.056-6.366-0.896
          c-0.64,0.046-0.879-0.386-0.624-1.158c1.417-4.293,2.973-8.542,4.103-12.93c0.763-2.964,1.803-5.843,2.945-8.683
          c1.483-3.687,2.619-7.492,3.765-11.292c0.104-0.349,0.107-0.732,0.566-0.806c0.484-0.078,0.698,0.22,0.897,0.611
          c1.116,2.175,1.64,4.558,2.36,6.862c1.254,4.011,2.349,8.07,3.511,12.109C289.083,101.085,289.175,101.087,289.265,101.089z"/>
        <path fill="#DF2015" d="M252.936,97.613c0.039,4.305-0.762,8.856-2.438,13.271c-1.298,3.419-4.371,5.361-8.022,5.468
          c-4.641,0.137-8.849-2.892-10.854-7.098c-1.428-2.991-1.298-5.983-1.011-9.107c0.327-3.551,1.255-6.955,2.037-10.411
          c0.998-4.414,3.624-7.292,7.517-9.218c2.107-1.044,4.128-0.198,5.922,0.763c2.597,1.39,4.167,3.75,5.33,6.458
          C252.73,90.794,253.068,93.951,252.936,97.613z M246.332,96.723c-0.077-2.096-0.323-4.14-1.98-5.677
          c-0.923-0.855-2.185-0.685-2.86,0.34c-0.458,0.692-0.881,1.414-1.394,2.064c-2.008,2.545-2.748,5.404-2.154,8.584
          c0.28,1.497,1.488,2.656,2.835,2.864c1.85,0.285,3.092-0.31,3.987-1.887C245.875,101.062,246.28,98.938,246.332,96.723z"/>
        <path fill="#DF2015" d="M139.78,107.425c-0.366,1.778,0.076,3.497,0.088,5.225c0.013,2.158,0.101,4.315,0.191,6.472
          c0.037,0.877-0.306,1.211-1.188,1.207c-1.197-0.005-2.396,0.083-3.59,0.175c-0.672,0.052-0.851-0.208-0.957-0.865
          c-0.582-3.506-0.483-7.015,0.036-10.495c0.493-3.313,0.778-6.646,1.217-9.964c0.463-3.516,0.497-7.086,0.756-10.63
          c0.087-1.19,0.297-2.371,0.443-3.557c0.049-0.398,0.229-0.71,0.635-0.764c0.398-0.051,0.608,0.2,0.799,0.566
          c0.539,1.036,0.823,2.138,1.106,3.261c0.662,2.625,1.979,5.015,2.831,7.574c1.227,3.69,2.445,7.381,3.647,11.079
          c0.416,1.279,0.771,2.579,1.146,3.849c0.267,0.048,0.298-0.11,0.307-0.268c0.164-2.911,0.229-5.831,0.507-8.73
          c0.43-4.453,0.265-8.938,0.854-13.382c0.167-1.259,0.104-2.556,0.075-3.833c-0.021-0.916,0.387-1.326,1.262-1.356
          c1.997-0.072,3.994-0.14,5.99-0.222c0.72-0.028,0.97,0.292,0.972,1.005c0.006,3.444-0.294,6.858-0.86,10.254
          c-0.275,1.654-0.345,3.336-0.527,5.002c-0.581,5.273-1.238,10.536-2.114,15.768c-0.466,2.783-1.184,5.525-1.782,8.286
          c-0.068,0.315-0.103,0.642-0.517,0.704c-0.353,0.054-0.638-0.063-0.838-0.344c-2.817-3.913-6.053-7.555-8.044-12.016
          C141.58,109.985,140.702,108.715,139.78,107.425z"/>
        <path fill="#DF2015" d="M109.131,98.343c-0.661,0.396-0.82,1.132-1.204,1.698c-0.976,1.441-1.917,2.908-2.885,4.355
          c-0.22,0.33-0.464,0.688-0.957,0.555c-0.437-0.119-0.417-0.514-0.512-0.859c-0.469-1.721-0.961-3.435-1.571-5.168
          c-0.866,2.487-2.146,4.753-3.165,7.138c-0.871,2.038-1.96,3.983-2.813,6.028c-0.482,1.157-1.269,1.196-2.215,0.962
          c-1.734-0.428-3.458-0.895-5.244-1.084c-0.918-0.097-0.386-0.784-0.271-1.086c0.432-1.149,0.969-2.259,1.48-3.378
          c3.504-7.65,7.01-15.298,10.519-22.946c0.116-0.253,0.279-0.483,0.396-0.736c0.339-0.729,0.752-0.651,1.122-0.066
          c0.314,0.5,0.571,1.043,0.797,1.591c0.966,2.348,2.033,4.646,3.139,6.931c0.429,0.888,0.678,1.035,1.278,0.096
          c1.774-2.779,3.474-5.6,5.021-8.51c0.182-0.341,0.103-0.996,0.765-0.892c0.546,0.087,0.584,0.643,0.67,1.031
          c0.61,2.763,1.248,5.524,1.567,8.339c0.492,4.324,1.528,8.549,2.269,12.828c0.23,1.335,0.435,2.676,0.636,4.017
          c0.384,2.559,1.054,5.049,1.775,7.525c0.213,0.73,0.163,1.088-0.691,1.243c-1.533,0.275-3.028,0.637-4.614,0.271
          c-0.689-0.16-1.407,0.166-2.073,0.452c-1.48,0.637-1.881,0.349-2.07-1.197c-0.743-6.005-0.426-12.063-0.946-18.076
          C109.299,99.065,109.435,98.69,109.131,98.343z"/>
        <path fill="#DF2015" d="M63.615,100.143c-0.087-2.448,0.446-4.611,1.074-6.822c1.05-3.692,2.885-6.805,5.529-9.546
          c1.807-1.871,3.883-2.823,6.426-2.243c1.724,0.394,3.521,0.331,5.18,1.225c2.765,1.49,4.693,3.708,5.843,6.501
          c2.096,5.094,1.755,10.168-0.594,15.157c-1.825,3.875-4.854,6.464-8.687,8.058c-2.097,0.873-4.416,0.561-6.609-0.008
          c-1.034-0.267-2.1-0.456-3.068-0.958c-1.815-0.941-3.073-2.303-3.623-4.326C64.44,104.812,63.351,102.542,63.615,100.143z
           M76.387,103.618c0.626-0.058,1.231-0.05,1.838-0.391c3.748-2.104,4.792-8.093,2.011-11.739c-2.106-2.761-5.479-2.636-7.469,0.19
          c-1.609,2.287-1.938,4.778-1.287,7.383C72.118,101.612,74.062,103.803,76.387,103.618z"/>
        <path fill="#DF2015" d="M222,82.656c0.063,2.892,0.066,2.85-2.735,2.659c-1.046-0.07-2.046-0.564-3.175-0.099
          c-1.743,0.722-2.372,2.515-1.236,4.063c1.234,1.684,2.83,3.055,4.517,4.201c3.537,2.407,5.242,5.827,6.171,9.825
          c0.776,3.345,0.839,6.654-0.485,9.856c-1.618,3.91-4.617,5.925-8.843,6.374c-3.597,0.381-7.031-0.255-10.483-1.056
          c-0.729-0.169-1.002-0.558-0.996-1.253c0.016-1.56-0.032-3.122,0.038-4.678c0.079-1.733,0.618-2.051,2.322-1.591
          c1.692,0.458,3.399,0.844,5.183,0.751c1.57-0.083,3.095-0.427,4.473-1.133c1.258-0.646,1.977-1.774,2.082-3.267
          c0.323-4.552-1.484-7.85-5.489-10.111c-2.409-1.362-4.579-3.055-6.117-5.518c-2.198-3.521,0.084-10.108,4.288-11.25
          c2.682-0.728,5.298-1.083,8.013-0.432c0.117,0.028,0.25,0.021,0.352,0.076c0.673,0.371,1.684-0.532,2.072,0.335
          C222.227,81.039,222,81.899,222,82.656z"/>
        <path fill="#DF2015" d="M273.08,97.249c0.166,3.869-1.026,7.648-3.396,11.117c-1.51,2.21-3.55,3.467-6.146,3.773
          c-3.461,0.41-6.813-1.869-7.999-5.143c-1.441-3.976-1.435-7.975-0.85-12.114c0.479-3.405,1.363-6.621,3.042-9.586
          c1.381-2.442,3.508-3.954,6.415-3.712c2.475,0.207,4.55,1.577,5.751,3.643C271.973,88.796,273.315,92.673,273.08,97.249z
           M260.475,98.022c0,0.52,0.011,1.037-0.002,1.556c-0.023,0.952,0.608,1.419,1.336,1.796c0.707,0.367,1.251-0.085,1.684-0.518
          c0.558-0.555,1.099-1.172,1.466-1.857c1.07-1.996,1.82-4.104,0.886-6.355c-0.41-0.984-1.16-1.88-2.325-1.972
          c-1.104-0.087-1.324,0.957-1.705,1.719C260.927,94.162,260.454,96.033,260.475,98.022z"/>
        <path fill="#FEFEFE" d="M123.907,62.398c0.979-3.129,2.446-5.694,3.833-8.302c0.43-0.809,0.979-1.329,1.905-1.445
          c0.515-0.063,1.017-0.22,1.528-0.297c3.82-0.579,5.749,0.775,6.473,4.572c0.609,3.204,0.085,5.999-1.992,8.763
          c-2.9,3.857-5.989,7.554-8.972,11.339c-0.575,0.729-0.93,1.576-1.075,2.505c-0.052,0.323-0.16,0.668-0.545,0.693
          c-0.437,0.03-0.674-0.307-0.714-0.676c-0.111-1.047-0.96-1.617-1.458-2.415c-2.525-4.045-6.1-7.267-8.747-11.213
          c-1.528-2.278-2.559-4.749-2.338-7.525c0.119-1.489,0.943-2.874,2.302-3.519c1.476-0.699,2.858-0.173,4.189,0.881
          C120.555,57.55,122.077,59.928,123.907,62.398z"/>
        <path fill="#DF2015" d="M172.651,98.687c3.396,0.08,6.748,0.772,10.158,0.668c0.662-0.021,1.334,0.208,1.998,0.341
          c0.667,0.133,0.473,0.674,0.416,1.062c-0.243,1.669-0.876,3.233-1.605,4.723c-0.729,1.496-1.188,3.113-2.139,4.517
          c-2.257,3.328-5.115,5.438-9.373,5.172c-2.471-0.155-4.771-0.83-7.011-1.814c-1.86-0.816-2.906-2.426-3.879-4.054
          c-4.201-7.038-2.846-13.691,1.526-20.086c2.414-3.531,5.712-5.888,9.774-7.191c0.531-0.17,1.125-0.334,1.121,0.496
          c-0.007,1.228,0.556,2.227,1.112,3.232c0.479,0.866,0.354,1.359-0.667,1.68c-1.584,0.497-3.285,0.616-4.769,1.436
          c-3.105,1.714-4.491,4.592-5.225,7.872c-0.472,2.112-0.79,4.254-0.038,6.376c0.748,2.104,1.623,4.249,4.191,4.522
          c2.786,0.298,5.34-0.563,7.527-2.366c0.14-0.113,0.297-0.346,0.271-0.488c-0.057-0.29-0.373-0.233-0.6-0.264
          c-2.522-0.339-5.007-0.97-7.564-1.06c-0.704-0.025-1.015-0.373-0.921-1.082c0.146-1.114,0.521-2.159,0.909-3.205
          c0.187-0.492,0.58-0.492,0.957-0.57C170.109,98.332,171.377,98.656,172.651,98.687z"/>
        <path fill="#DF2015" d="M54.617,113.724c-2.724-0.036-5.444-0.298-8.12-0.66c-3.525-0.477-5.962-2.611-7.128-6.043
          c-1.84-5.413-1.41-10.573,1.809-15.392c1.51-2.259,3.609-3.896,5.905-5.257c2.25-1.334,4.597-2.49,7.129-3.242
          c0.879-0.261,1.83-0.141,2.668-0.615c0.435-0.246,0.656,0.043,0.647,0.505c-0.04,2.197-0.041,4.396-0.139,6.592
          c-0.042,0.949-0.862,0.865-1.545,0.903c-3.141,0.181-5.737,1.376-7.59,4.017c-1.281,1.824-1.371,4.876-0.058,6.726
          c2.742,3.861,6.324,6.39,11.17,6.835c0.682,0.063,1.168,0.256,0.945,1.114c-0.31,1.19-0.632,2.383-0.838,3.594
          c-0.125,0.731-0.517,0.922-1.144,0.922C57.093,113.725,55.854,113.724,54.617,113.724z"/>
        <path fill="#DF2015" d="M131.139,99.599c0,4.318,0.018,8.636-0.009,12.954c-0.009,1.521-0.21,1.666-1.724,1.36
          c-1.689-0.341-3.429-0.194-5.116-0.608c-0.732-0.18-0.976-0.477-0.993-1.2c-0.215-8.635-0.447-17.268-0.699-25.901
          c-0.022-0.832,0.327-1.19,1.108-1.198c2.32-0.022,4.64-0.028,6.959-0.058c0.878-0.013,0.958,0.606,0.951,1.217
          c-0.014,1.358-0.121,2.717-0.13,4.077c-0.021,3.118-0.008,6.238-0.008,9.358C131.369,99.599,131.254,99.599,131.139,99.599z"/>
        <path fill="#96688B" d="M178.103,195.691c-0.474-0.522-0.971-1.028-1.412-1.582c-0.524-0.655-0.956-1.336,0.021-2.02
          c0.302-0.211,0.403-0.52,0.208-0.943c-0.41-0.887-1.254-1.715-0.466-2.842c0.099-0.139-0.257-0.553-0.319-0.856
          c-0.192-0.896-0.75-1.886,0.587-2.437c0.449-0.186,0.295-0.547,0.167-0.916c-0.38-1.094-0.695-2.19-0.322-3.381
          c0.203-0.648-0.557-1.145-0.438-1.818c0.138-0.061,0.273-0.118,0.412-0.178c0.45,0.547,0.771,1.166,1.017,1.83
          c-0.282,0.221-0.029,0.459-0.021,0.689c-0.447,1.026-0.059,1.993,0.248,2.952c0.108,0.341,0.416,0.627,0.841,0.504
          c0.395-0.114,0.349-0.489,0.349-0.803c0.001-0.877-0.013-1.752-0.021-2.627c-0.083-0.188-0.167-0.379-0.251-0.567
          c0.958-0.814,1.014-1.46,0.238-2.81c0.503-0.361,0.729,0.305,1.137,0.303c0.024,0.748,0.185,1.521,0.049,2.236
          c-0.413,2.213-0.281,4.428-0.215,6.646c0.037,0.657-1.001,1.184-0.243,1.932L179.665,189c-0.218,0.387-0.505,0.215-0.846,0.233
          c-0.568,0.035-1.462-0.598-1.721,0.314c-0.152,0.537,0.366,1.078,0.839,1.494c0.4,0.354,0.657,0.25,1.01-0.063
          c0.132-0.116,0.451-0.02,0.688-0.021l-0.005,0.002c-0.028,0.51-0.065,1.018-0.085,1.524c-0.014,0.361-0.128,0.854-0.396,0.878
          c-0.509,0.045-0.413-0.479-0.385-0.8c0.075-0.84-0.319-0.764-0.831-0.457c-0.234,0.144-0.474,0.312-0.643,0.521
          c-0.487,0.604-0.03,1.127,0.269,1.59c0.324,0.504,0.62,0.324,0.987-0.109c0.469-0.549,0.898-0.467,0.956,0.359
          C178.913,194.736,179.001,195.78,178.103,195.691z M178.972,186.584c0.024-1.008-0.773-0.998-1.322-1.082
          c-0.488-0.076-0.987,0.256-0.794,0.877c0.218,0.695-0.137,1.799,1.187,1.816C179.451,188.215,178.556,187.019,178.972,186.584z"/>
        <path fill="#F0EBF0" d="M283.62,62.203c-1.752-0.009-3.354-1.444-3.351-2.996c0.007-1.705,1.693-2.947,3.934-2.896
          c1.295,0.03,2.211,1.626,2.219,3.866C286.427,61.416,285.324,62.212,283.62,62.203z"/>
        <path fill="#F0EBF0" d="M191.039,124.93c1.743,0.036,2.7,1.229,2.69,3.354c-0.005,1.084-1.281,2.145-2.573,2.137
          c-1.771-0.011-3.485-1.417-3.415-2.807C187.812,126.223,189.443,124.896,191.039,124.93z M188.461,128.047
          c0.183-0.316,0.237-0.559-0.057-0.806C188.197,127.494,188.208,127.726,188.461,128.047z"/>
        <path fill="#F0EBF0" d="M313.397,74.209c0.05-0.149,0.1-0.299,0.147-0.448c0.525,0.083,0.758-0.172,0.771-0.675
          c0.167-0.015,0.334-0.03,0.502-0.044c0.008,0.927,1.063,1.164,1.202,2.188c0.337-0.605,0.458-1.015,0.455-1.462
          c0.558,1.534,0.992,3.021,0.154,4.661c-0.469,0.919-1.064,1.25-1.958,1.086c-1.097-0.201-2.184-0.655-2.886-1.483
          c-1.092-1.283-0.32-3.128,1.465-3.87c0.521,0.74,1.063,1.456,1.723,2.086c0.228,0.218,0.402,0.607,0.729,0.284
          c0.248-0.247-0.009-0.583-0.139-0.867c-0.206-0.45-1.016-0.327-0.997-0.701C314.621,73.889,313.892,74.282,313.397,74.209z
           M313.764,77.774c0.285-0.319,0.711-0.461,0.641-0.941c-0.022-0.169-0.29-0.345-0.478-0.225
          C313.527,76.867,313.55,77.256,313.764,77.774z"/>
        <path fill="#F0EBF0" d="M61.235,64.727c1.618-0.219,2.625,1.401,2.791,3.077c0.156,1.604-0.652,2.135-3.154,2.244
          c-1.177,0.052-2.345-1.021-2.369-2.17C58.468,66.198,59.596,64.505,61.235,64.727z M60.285,66.912
          c0.778,0,1.201-0.033,1.614,0.013c0.225,0.025,0.426,0.214,0.65,0.278c0.403,0.114,0.899,0.07,0.409-0.43
          c-0.367-0.372-0.946-0.783-1.596-0.575C61.08,66.29,60.845,66.532,60.285,66.912z"/>
        <path fill="#F0EBF0" d="M44.177,65.405c1.154-0.008,2.415,1.013,2.437,1.969c0.034,1.637-1.101,3.482-2.13,3.466
          c-1.161-0.019-3.106-1.343-3.135-2.133C41.287,66.847,42.513,65.413,44.177,65.405z M43.868,66.162
          c0.499,0.307,0.686,0.451,0.896,0.545c0.366,0.165,0.563,0.734,1.104,0.479c0.115-0.055,0.154-0.186,0.056-0.294
          c-0.318-0.35-0.633-0.716-1.164-0.729C44.566,66.159,44.373,66.162,43.868,66.162z"/>
        <path fill="#F0EBF0" d="M112.463,128.52c0.006-0.252,0.075-0.496,0.31-0.605c1.867-0.892,4.091-0.235,5.256,1.536
          c-0.148,0.178-0.457,0.401-0.421,0.524c0.316,1.068-0.579,1.412-1.172,1.691c-0.673,0.315-0.896,0.783-1.079,1.379
          c-2.208,0.063-2.314,0.002-2.893-1.639c0.75-0.395,0.627-1.168,0.851-1.813C113.634,128.668,113.413,128.351,112.463,128.52z"/>
        <path fill="#F0EBF0" d="M173.829,125.675c0.335,1.313,1.067,2.569,0.462,4.009c-0.099,0.232-0.181,0.408-0.374,0.543
          c-0.118-0.311-0.237-0.622-0.447-1.167c-0.207,1.162-1.307,1.121-1.727,1.837c-1.506-0.096-2.875-0.409-3.396-2.099
          c0.403-0.587,0.772-1.203,1.229-1.744c0.174-0.205,0.347-0.905,0.907-0.164c0.311,0.406,0.833,0.188,0.674-0.511
          c-0.093-0.403-0.204-0.933,0.451-0.691C172.386,125.971,173.093,125.703,173.829,125.675z M170.9,129.016
          c0.915,0.146,1.954-0.914,1.729-1.564c-0.033-0.1-0.126-0.208-0.22-0.25c-0.181-0.078-0.357,0.044-0.356,0.197
          C172.056,128.147,171.403,128.44,170.9,129.016z"/>
        <path fill="#F0EBF0" d="M249.797,55.745c0.83,0.579,1.219,1.356,1.093,2.376c-0.091,0.733-0.175,1.47-0.262,2.205
          c-0.412,0.059-0.917-0.011-1.147,0.41c-0.243,0.439,0.332,0.519,0.482,0.797c-0.965,0.487-1.99,0.703-3.066,0.707
          c0.188-0.616,0.711-1.105,0.274-1.913c-0.302-0.56,0.25-0.428,0.602-0.293c0.328,0.125,0.641-0.024,0.885-0.213
          c0.214-0.164-0.02-0.368-0.101-0.521c-0.652-1.22-1.786-1.131-2.903-1.017c-0.979,0.1-0.56,0.959-0.759,1.483
          c-0.055,0.145-0.149,0.273-0.228,0.409c-0.634-1.189-0.354-2.237,0.504-3.19c0.305-0.035,0.451-0.207,0.446-0.512
          c0,0,0.011-0.01,0.011-0.009c0.597-0.045,1.001-0.359,1.25-0.89c0.241-0.027,0.48-0.056,0.724-0.083
          c0,0.837-1.057,0.87-1.302,1.604c0.861,0.289,1.63,0.057,1.925-0.592c0.344-0.753,0.654-0.341,1.033-0.218
          c0.071,0.013,0.146,0.022,0.219,0.028c0.054,0.043,0.133,0.137,0.156,0.123c0.143-0.074,0.078-0.168-0.005-0.252
          c-0.025-0.066-0.061-0.131-0.101-0.19C249.618,55.913,249.708,55.829,249.797,55.745z M247.93,57.619
          c0.603-0.112,1.041,0.486,1.699,0.433C248.949,57.265,248.898,57.251,247.93,57.619z"/>
        <path fill="#E9605D" d="M285.821,175.006c-4.491-0.158-8.975-0.414-13.428-1.09c-1.136-0.175-2.313-0.082-3.47-0.111
          c0.021-0.338-0.082-0.588-0.454-0.647l0.006,0.007c-0.009-0.145-0.018-0.285-0.025-0.432c3.431-0.152,6.811,0.255,10.168,0.896
          c0.231,0.045,0.478,0.023,0.716,0.037c1.656,0.014,3.316-0.037,4.97,0.068C284.996,173.781,286.027,173.744,285.821,175.006z"/>
        <path fill="#F0EBF0" d="M228.633,55.552c2.25,1.015,2.889,3.271,2.27,5.214c-0.348,1.087-2.296,1.439-3.402,0.64
          c-0.878-0.634-1.563-1.439-1.938-2.479c1.052,0.067,2.027,0.978,3.153,0.271c0.268-0.168,0.655-0.25,0.473-0.672
          c-0.153-0.35-0.504-0.351-0.83-0.262c-0.394,0.106-0.706,0.582-1.196,0.263c0.014-0.684,0.961-0.97,0.875-1.424
          C227.897,56.356,228.308,56.013,228.633,55.552z"/>
        <path fill="#F0EBF0" d="M130.438,128.496c0.116-0.525,0.472-0.782,0.979-0.865c0.056,0.383-0.283,0.746-0.01,1.243
          c0.516-0.533,1.636-0.12,1.708-1.276c1.49,0.665,2.2,2.079,1.818,3.536c-0.154,0.594-0.636,0.813-1.089,1.004
          c-0.813,0.347-1.698,0.442-2.57,0.338c-1.355-0.164-1.728-1.1-1.24-2.976C130.772,129.408,131.037,129.126,130.438,128.496z"/>
        <path fill="#F0EBF0" d="M30.701,117.424c-0.006,1.243-1.129,2.357-2.351,2.329c-1.331-0.03-2.882-1.483-2.894-2.708
          c-0.009-0.966,1.43-1.984,2.758-1.951C29.663,115.129,30.706,116.107,30.701,117.424z"/>
        <path fill="#F0EBF0" d="M308.642,126.374c-0.438-1.146,0.271-1.837,1.072-2.359c1.221-0.792,2.746-0.848,3.448-0.12
          c0.893,0.927,0.689,2.147,0.521,3.263c-0.13,0.846-0.433,1.724-1.521,1.874c-0.079-0.319-0.158-0.64-0.237-0.958
          c0.37-0.09,0.601-0.376,0.771-0.664c0.209-0.352,0.301-0.748-0.32-0.816c-0.483,0.006-1.038-0.037-0.496,0.697
          c-0.219,0.098-0.438,0.193-0.655,0.292c-0.418,0.047-0.322-0.745-0.605-0.535C309.569,127.823,309.206,126.857,308.642,126.374z"
          />
        <path fill="#F0EBF0" d="M45.989,128.973c1.082,0.474,2.087,2.414,1.834,3.5c-0.069,0.297-0.234,0.449-0.476,0.625
          c-1.254,0.918-2.755,0.914-4.049-0.057c-0.961-0.724-1.076-1.615-0.427-3.322c0.752,0.859,1.675,0.113,2.477,0.273
          C46.705,130.26,46.386,129.694,45.989,128.973z"/>
        <path fill="#F0EBF0" d="M97.768,67.062c-0.279,0.938-1.119,1.188-1.893,1.318c-1.196,0.204-2.243-0.356-3.172-1.053
          c-0.533-0.399-0.594-1.017-0.351-1.623c0.251-0.623,0.544-1.229,0.819-1.84c0.668,0.209,1.958,0.1,0.655,1.308
          c-0.238,0.221-0.149,0.567-0.021,0.771c0.298,0.476,0.586-0.207,0.907-0.068l0.047,0.056l-0.053-0.05
          c-0.087-0.745,0.63-1.435-0.079-2.34c0.73,0.118,1.118,0.388,1.431,0.708c0.425,0.436,0.756,0.257,1.116-0.04
          c0.075,0.277,0.134,0.563,0.405,0.73c0.061,0.405-0.509,1.203,0.597,0.955c0.081,0.254,0.146,0.507-0.063,0.734
          C97.865,66.666,97.549,66.651,97.768,67.062z M95.943,66.303c0.289-0.02,0.415-0.191,0.404-0.464
          c-0.008-0.217-0.122-0.386-0.352-0.379c-0.329,0.011-0.498,0.224-0.474,0.54C95.541,66.224,95.719,66.315,95.943,66.303z"/>
        <path fill="#D8D2D8" d="M312.645,61.531c-1.626-1.022-2.138-2.326-1.479-3.767c0.564-1.233,2.354-2.02,3.821-1.606
          c0.646,0.182,1.661-0.08,1.646,1.114c-0.552-0.074-0.729,0.789-1.354,0.539c-0.294-0.118-0.66-0.555-0.873-0.004
          c-0.174,0.452,0.354,0.516,0.621,0.721c0.331,0.254,0.613,0.67,0.39,1.022c-0.252,0.396-0.68,0.777-1.11,0.927
          c-0.246,0.084-0.763-0.185-0.771-0.718c-0.005-0.256-0.141-0.493-0.438-0.427c-0.256,0.057-0.398,0.331-0.343,0.555
          C312.901,60.457,312.65,60.982,312.645,61.531z"/>
        <path fill="#F0EBF0" d="M150.881,127.808c0.824-0.865,1.88-0.871,2.867-0.546c0.834,0.274,1.514,0.916,1.591,1.905
          c0.074,0.941-0.433,1.604-1.245,1.975c-0.577,0.268-0.971,0.83-1.643,0.925c-1.645,0.231-2.727-1.079-2.363-2.878
          c0.363,0.023,0.688,0.135,0.997,0.337c0.409,0.27,0.746,0.131,0.702-0.351c-0.027-0.294,0.711-0.898-0.263-0.921
          C151.202,128.248,151.026,128.058,150.881,127.808z M153.476,129.446c-0.415-0.254,0-0.979-0.618-0.988
          c-0.238-0.003-0.538,0.321-0.475,0.604C152.521,129.664,153.097,129.23,153.476,129.446z"/>
        <path fill="#F0EBF0" d="M145.844,66.224c-2.568-0.25-3.84-1.985-3.113-4.205c0.324-0.991,0.975-1.569,1.996-1.585
          c0.871-0.013,1.762,0.056,2.614,0.228c0.913,0.184,1.212,1.064,0.925,2.354c-0.415-0.263-0.117-1.144-0.87-1.071
          c-0.554,0.052-0.609,0.522-0.594,0.982c-0.483,0.189-0.38,0.936-0.969,1.026c-0.084-0.336-0.095-0.716-0.267-1
          c-0.402-0.673-0.004-0.945,0.502-1.205c0.146-0.063,0.348-0.22,0.188-0.316c-0.639-0.384-2.2,0.021-2.667,0.707
          c-0.569,0.837-0.253,1.596,0.335,2.289c0.017,0.111,0.032,0.222,0.048,0.333c0.035-0.015,0.07-0.028,0.105-0.043
          c-0.058-0.092-0.113-0.184-0.17-0.275c-0.11-0.8-0.257-1.606,0.478-2.226c0.395,0.536,0.538,1.24,0.463,1.79
          c-0.104,0.772,0.275,0.667,0.706,0.69c0.177,0.09,0.354,0.181,0.528,0.271C146.023,65.389,145.561,65.738,145.844,66.224z"/>
        <path fill="#F0EBF0" d="M30.103,97.703c-0.002,1.6-0.493,2.332-1.55,2.307c-1.405-0.033-2.426-1.045-2.441-2.424
          c-0.015-1.071,0.998-2.106,2.092-2C30.132,95.773,30.233,95.995,30.103,97.703z"/>
        <path fill="#96688B" d="M179.749,195.28c0.048-0.261,0.098-0.519,0.146-0.773c0.193-0.232,0.624-0.406,0.442-0.762
          c-0.364-0.728,0.052-1.41,0.002-2.109c1.053-3.279,1.554-6.664,1.976-10.066c0.313-0.342,0.401-0.768,0.451-1.207
          c0.02-0.293,0.038-0.584,0.058-0.877c0.242-0.313,0.206-0.69,0.253-1.051c0.266,0.178,0.547,0.287,0.872,0.229
          c-0.637,3.25-1.298,6.498-1.903,9.754c-0.453,2.444-1.333,4.805-1.448,7.313C180.357,195.494,180.146,195.211,179.749,195.28z"/>
        <path fill="#D8D2D8" d="M275.197,132.6c0.079,2.254-1.311,3.021-3.497,1.924c-1.477-0.741-1.986-2.147-1.379-3.803
          c0.478-1.3,2.146-2.227,3.483-1.935c0.806,0.176,1.484,1.188,1.438,2.142c-0.285-0.07-0.416-0.299-0.54-0.531
          c-0.082-0.067-0.163-0.137-0.245-0.205l0.02,0.025c-0.608-0.958-1.604-1.062-2.146-0.227c-0.058,0.102-0.091,0.233-0.176,0.303
          c-1.392,1.09-1.405,1.164-0.403,2.531c0.664,0.904,1.302,0.924,2.456,0.141C274.487,132.774,274.863,132.717,275.197,132.6z"/>
        <path fill="#D8D2D8" d="M75.542,66.133c0.215-1.246,0.989-2.132,1.86-2.133c1.342,0,2.955,0.968,3.132,1.879
          c0.33,1.704-0.086,2.853-1.2,3.315c0.197-0.588,1.388-1.318-0.295-1.627c-0.415-0.075-0.408-0.53,0.042-0.784
          c0.203-0.115,0.275-0.313,0.101-0.487c-0.211-0.211-0.455-0.134-0.606,0.083c-0.133,0.191-0.204,0.426-0.298,0.644
          c-0.151,0.35-0.381,0.632-0.777,0.655c-0.494,0.029-0.547-0.405-0.624-0.733C76.704,66.196,76.218,66.023,75.542,66.133z"/>
        <path fill="#F0EBF0" d="M28.715,67.103c1.272,0.02,2.152,0.693,2.12,1.625c-0.04,1.129-1.085,2.344-2.017,2.343
          c-1.212,0-2.273-1.011-2.253-2.143C26.582,68.02,27.679,67.088,28.715,67.103z"/>
        <path fill="#F0EBF0" d="M301.576,58.677c0.7,2.146-0.611,3.768-2.86,3.525c-0.508-0.056-1.024-0.086-1.486-0.347
          c0.005-0.314-0.144-0.493-0.464-0.519c-0.045-0.12-0.091-0.238-0.134-0.358c0.374-0.732-0.006-1.595,0.397-2.322
          c0.095-0.171,0.161-0.4,0.411-0.338c0.271,0.067,0.196,0.296,0.155,0.485c-0.117,0.542-0.192,1.082,0.029,1.629
          c1.232-0.919,1.269-0.981,0.934-1.729c0.796-0.368,1.338-0.06,1.548,0.707c0.137,0.5,0.266,0.727,0.777,0.464
          C301.237,59.547,300.912,58.826,301.576,58.677z"/>
        <path fill="#F0EBF0" d="M99.258,130.747c-0.021,1.121-1.235,2.348-2.286,2.309c-0.862-0.033-2.144-1.25-2.123-2.021
          c0.053-1.856,0.765-2.867,1.995-2.829C97.88,128.236,99.281,129.709,99.258,130.747z M97.059,131.875
          c0.202-0.07,0.72,0.108,0.617-0.498c-0.131-0.791-0.543-1.453-1.066-2.049c-0.255,0.411-1.169,0.19-1.012,0.939
          C95.756,131.006,95.804,131.936,97.059,131.875z"/>
        <path fill="#F0EBF0" d="M269.348,57.512c0.43,1.365,0.53,2.655-0.82,3.617c-0.354-0.004-0.717-0.054-1.063-0.005
          c-1.495,0.211-2.166-0.694-1.654-2.231c0.348-0.069-0.001,0.587,0.491,0.433c-0.064-0.743,0.029-1.483,0.468-2.054
          c0.649-0.846,1.243,0.098,1.871,0.181C268.874,57.482,269.111,57.492,269.348,57.512z"/>
        <path fill="#D8D2D8" d="M315.799,94.979c-0.3,0.917-1.045,1.335-1.894,1.279c-0.853-0.056-1.858-0.094-2.213-1.192
          c0.03-0.084,0.062-0.169,0.093-0.254c0.561,0.146,1.029,0.129,1.089-0.614c0.021-0.027,0.063-0.06,0.058-0.08
          c-0.015-0.06-0.047-0.113-0.071-0.169c-0.367-0.411-0.095-0.896-0.128-1.37c-0.416,0.034-0.364,0.645-0.886,0.587
          c0.021-0.368,0.043-0.74,0.063-1.111c-0.04-0.506,0.146-1.053-0.27-1.492c0.903-0.751,1.785-0.404,2.662,0.06
          c-0.457,0.489-0.939,0.926,0.253,1.115c0.266,0.042,1.256,0.618,0.063,1.045c-0.154-0.23-0.088-0.78-0.536-0.595
          c-0.447,0.186-0.583,0.623-0.242,1.078C313.677,94.32,314.286,94.851,315.799,94.979z"/>
        <path fill="#D8D2D8" d="M300.885,59.876c-0.514,0.263-0.643,0.037-0.777-0.464c-0.21-0.767-0.752-1.075-1.548-0.707
          c0.335,0.746,0.301,0.81-0.934,1.729c-0.224-0.547-0.146-1.087-0.029-1.629c0.041-0.189,0.114-0.418-0.155-0.484
          c-0.25-0.063-0.316,0.167-0.411,0.337c-0.403,0.728-0.024,1.589-0.398,2.322c-0.962-0.301-0.734-1.103-0.74-1.791
          c-0.01-1.018,0.382-2.012,1.218-2.438c0.941-0.481,2.11-0.755,3.083,0.181C299.848,58.047,300.489,58.932,300.885,59.876z"/>
        <path fill="#E2403A" d="M104.451,200.895c-0.395-0.716-1.269-0.97-1.669-1.802c0.835-0.295,1.421,0.166,2.004,0.598
          c1.992,1.479,3.825,3.124,5.39,5.058c-0.712,0.701-1.201,0.135-1.702-0.307C107.129,203.264,105.79,202.078,104.451,200.895z"/>
        <path fill="#FEFEFE" d="M311.128,114.681c-0.816-0.625-1.479-1.348-1.556-2.442c0.722-0.109,1.291-0.228,1.377-1.225
          c0.089-1.011,1.287-1.36,2.223-0.835c0.514,0.288,0.867,0.857,1.533,0.871c0.189,0.53,0.407,1.054,0.337,1.638
          c-0.563-0.038-0.563-0.585-0.885-1.028c-0.088,0.55-0.317,0.872-0.845,0.785c-0.005-0.067-0.017-0.133-0.033-0.197
          c0.018-0.104,0.032-0.206,0.05-0.31c-0.607-0.639-1.287-0.446-1.865,0.529c-0.576-0.189-0.883,0.17-0.895,0.625
          c-0.013,0.555,0.445,0.605,0.939,0.624c0.592,0.022,1.096-0.194,1.597-0.409c0.318-0.136,0.566-0.319,0.791,0.081
          c0.209,0.372-0.157,0.435-0.323,0.634C312.904,114.832,311.958,114.526,311.128,114.681z"/>
        <path fill="#FEFEFE" d="M275.197,132.6c-0.334,0.117-0.71,0.174-0.992,0.365c-1.154,0.783-1.792,0.766-2.456-0.141
          c-1.002-1.367-0.986-1.441,0.403-2.531c0.085-0.068,0.118-0.201,0.175-0.303c0.741-0.166,1.462-0.121,2.147,0.227l-0.02-0.025
          c0.082,0.068,0.163,0.138,0.245,0.205c0.124,0.232,0.255,0.461,0.54,0.531c0.021,0.113,0.038,0.228,0.057,0.34
          c-0.032,0.013-0.064,0.025-0.098,0.039c0.032-0.002,0.064-0.006,0.098-0.01C275.264,131.731,275.23,132.164,275.197,132.6z"/>
        <path fill="#F0EBF0" d="M296.1,128.155c0.146,0.024,0.295,0.051,0.441,0.075c0.188,0.229,0.445,0.28,0.721,0.297l0.001,0.001
          c0.024,0.53-2.046,0.966-0.04,1.555c-0.42,0.404-0.382,0.395-0.248,0.956c0.104,0.424-0.057,1.184-0.244,1.182
          c-0.938-0.012-1.292,0.961-2.098,1.088c-1.66-1.076-1.716-1.215-1.124-2.84C294.517,129.858,295.383,129.088,296.1,128.155z
           M295.426,131.549c0.395-0.143,0.528-0.459,0.565-0.838c0.024-0.24-0.079-0.43-0.322-0.359c-0.345,0.099-0.457,0.422-0.494,0.746
          C295.151,131.284,295.147,131.506,295.426,131.549z"/>
        <path fill="#D8D2D8" d="M173.829,125.675c-0.735,0.028-1.441,0.296-2.219,0.013c-0.655-0.24-0.544,0.288-0.451,0.691
          c0.159,0.699-0.363,0.917-0.674,0.511c-0.563-0.741-0.733-0.041-0.907,0.164c-0.457,0.541-0.826,1.157-1.229,1.744
          c-0.627-0.387-0.271-0.938-0.189-1.398c0.335-1.857,1.727-3.109,3.563-3.268C172.932,124.029,173.511,124.451,173.829,125.675z"/>
        <path fill="#D8D2D8" d="M269.348,57.512c-0.237-0.02-0.476-0.03-0.71-0.061c-0.628-0.083-1.222-1.026-1.871-0.181
          c-0.438,0.57-0.532,1.311-0.468,2.054c-0.492,0.154-0.146-0.502-0.491-0.433c-0.513,1.538,0.159,2.443,1.654,2.231
          c0.349-0.049,0.711,0.001,1.063,0.005c-1.188,1.202-2.258,1.125-3.678-0.265c0.151-1.173,0.096-2.371,0.577-3.5
          c0.239-0.564,0.368-1.108,1.158-1.09c0.802,0.021,0.141-0.468,0.206-0.708C268.33,55.323,269.335,56.088,269.348,57.512z"/>
        <path fill="#D8D2D8" d="M294.635,133.307c0.806-0.127,1.16-1.1,2.098-1.088c0.188,0.002,0.348-0.758,0.244-1.182
          c-0.134-0.563-0.172-0.552,0.248-0.956c-2.006-0.589,0.064-1.024,0.04-1.555c1.543,1.289,1.784,3.609,0.53,5.086
          c-0.54,0.637-1.232,0.438-1.869,0.279C295.473,133.784,295.062,133.508,294.635,133.307z"/>
        <path fill="#D8D2D8" d="M244.667,60.177c0.075-0.136,0.172-0.265,0.228-0.409c0.199-0.524-0.222-1.384,0.759-1.483
          c1.117-0.114,2.251-0.203,2.903,1.017c0.081,0.152,0.313,0.356,0.101,0.521c-0.244,0.188-0.557,0.338-0.885,0.213
          c-0.352-0.135-0.901-0.266-0.602,0.293c0.437,0.808-0.088,1.296-0.274,1.913c-0.804,0.124-1.178-0.446-1.558-0.984
          C245.095,60.91,244.889,60.537,244.667,60.177z"/>
        <path fill="#F0EBF0" d="M312.645,61.531c0.005-0.549,0.256-1.073,0.112-1.645c-0.059-0.225,0.087-0.498,0.343-0.555
          c0.299-0.066,0.435,0.171,0.438,0.427c0.008,0.533,0.522,0.802,0.771,0.718c0.433-0.148,0.858-0.529,1.11-0.927
          c0.226-0.354-0.059-0.769-0.39-1.023c-0.267-0.205-0.795-0.269-0.621-0.72c0.213-0.551,0.579-0.114,0.873,0.004
          c0.625,0.25,0.802-0.612,1.353-0.539c0.044,0.776,0.268,1.542,0.134,2.328c-0.261,1.524-1.452,2.396-2.961,2.157
          C313.417,61.696,313.032,61.606,312.645,61.531z M316.067,59.19c-0.011,0.036-0.036,0.075-0.027,0.105
          c0.029,0.12,0.085,0.126,0.136,0.014c0.014-0.028,0.004-0.08-0.017-0.106C316.147,59.186,316.099,59.195,316.067,59.19z"/>
        <path fill="#D8D2D8" d="M228.633,55.552c-0.323,0.462-0.734,0.805-0.598,1.549c0.085,0.454-0.863,0.74-0.875,1.424
          c0.489,0.318,0.803-0.156,1.195-0.263c0.326-0.089,0.677-0.087,0.83,0.262c0.185,0.422-0.205,0.504-0.474,0.672
          c-1.126,0.708-2.103-0.202-3.152-0.271c-0.544-0.869-0.421-1.789,0.25-2.408C226.554,55.831,227.386,54.959,228.633,55.552z"/>
        <path fill="#F0EBF0" d="M75.542,66.133c0.675-0.11,1.162,0.063,1.334,0.812c0.076,0.328,0.13,0.763,0.624,0.733
          c0.396-0.023,0.626-0.307,0.777-0.655c0.094-0.218,0.164-0.452,0.298-0.644c0.151-0.217,0.396-0.295,0.606-0.083
          c0.176,0.175,0.104,0.372-0.101,0.487c-0.449,0.254-0.457,0.709-0.042,0.784c1.683,0.309,0.492,1.039,0.295,1.627
          c-0.159-0.008-0.319-0.015-0.479-0.021c-0.153-0.145-0.307-0.137-0.454,0.008C76.595,69.408,75.117,68.654,75.542,66.133z"/>
        <path fill="#694E82" d="M179.749,195.28c0.396-0.066,0.607,0.217,0.847,0.448l0.041-0.016c-0.068,0.381-0.416,0.746-0.145,1.164
          c0.57-0.16,0.584-0.828,1.098-0.955c0.611-0.221,0.711,0.082,0.601,0.592c-0.471,0.857-1.006,1.674-1.267,2.646
          c-0.115,0.432-0.504,0.905-1.06,0.785c-0.636-0.138-0.416-0.716-0.454-1.173C179.307,197.582,179.304,196.412,179.749,195.28z"/>
        <path fill="#E9605D" d="M125.981,198.478c-0.513-1.334-0.168-2.766-0.479-4.139c-0.271-1.189-0.302-2.426-0.763-3.59
          c-0.218-0.547-0.214-1.172,0.292-1.91c0.759,2.848,1.624,5.498,1.751,8.318C126.717,197.723,126.505,198.196,125.981,198.478z"/>
        <path fill="#D8D2D8" d="M311.128,114.681c0.83-0.155,1.775,0.151,2.445-0.659c0.166-0.199,0.532-0.263,0.323-0.634
          c-0.225-0.4-0.472-0.217-0.791-0.081c-0.501,0.215-1.005,0.432-1.597,0.409c-0.494-0.019-0.952-0.069-0.939-0.624
          c0.012-0.455,0.316-0.815,0.896-0.625c0.546,0.904,0.928,0.899,1.85-0.023c0.526,0.088,0.757-0.234,0.846-0.784
          c0.319,0.442,0.318,0.99,0.884,1.028c0.169,0.936,0.2,1.98-0.723,2.414C313.278,115.593,312.094,115.565,311.128,114.681z"/>
        <path fill="#96688B" d="M182.189,196.514c0.109-0.51,0.013-0.813-0.601-0.592c-0.035-0.094-0.07-0.189-0.106-0.283
          c0.757-1.813,1.515-3.625,2.271-5.438c0.205,0.209,0.473,0.235,0.741,0.256c-0.074,0.604-0.377,1.119-0.611,1.668
          c-0.319,0.74-0.836,1.479-0.399,2.356c-0.116,0.281-0.271,0.564,0.072,0.806c-0.057,0.328-0.604,0.395-0.485,0.752
          c0.648,0.436,1.807-0.228,1.886,1.088C184.08,196.707,183.12,196.682,182.189,196.514z"/>
        <path fill="#D8D2D8" d="M314.705,111.051c-0.666-0.014-1.021-0.583-1.533-0.871c-0.936-0.525-2.134-0.176-2.223,0.835
          c-0.086,0.997-0.655,1.114-1.377,1.225c-0.045-2.228,0.983-3.465,2.836-3.41C313.699,108.866,314.54,109.68,314.705,111.051z"/>
        <path fill="#FEFEFE" d="M28.25,128.789c0.564,0.162,1.151,0.271,1.688,0.496c0.798,0.334,0.94,1.247,0.43,2.338
          c-0.229,0.084-0.44,0.186-0.481,0.465l-0.002,0.006c-0.779-0.285-1.41-0.981-2.668-0.69c1.097-0.972,0.087-1.513,0.017-2.195
          c0,0,0.01-0.007,0.01-0.006C27.661,129.264,28.06,129.283,28.25,128.789z"/>
        <path fill="#D8D2D8" d="M27.233,129.208c0.07,0.683,1.08,1.224-0.017,2.195c1.26-0.291,1.89,0.405,2.668,0.69
          c-1.079,0.859-2.681,0.941-3.485,0.18C25.461,131.385,25.883,129.832,27.233,129.208z"/>
        <path fill="#F0EBF0" d="M315.799,94.979c-1.514-0.128-2.121-0.659-1.959-1.714c0.387,0.042,0.64-0.131,0.779-0.483
          c1.192-0.427,0.202-1.003-0.063-1.045c-1.192-0.189-0.71-0.627-0.253-1.115c1.586,0.595,2.077,1.539,1.854,3.555
          c-0.367,0.147-0.397,0.449-0.354,0.784L315.799,94.979z"/>
        <path fill="#FEFEFE" d="M145.844,66.224c-0.282-0.486,0.18-0.834,0.237-1.258l0.042-0.023c0.226-0.092,0.535-0.063,0.583-0.392
          c0.002-0.016-0.317-0.077-0.486-0.117c0.138-0.523,1.058-0.748,0.582-1.508c-0.017-0.459,0.041-0.931,0.594-0.983
          c0.753-0.071,0.457,0.81,0.87,1.072c-0.001,0.218-0.003,0.437-0.005,0.654c-0.007,0.102-0.014,0.203-0.021,0.305
          C147.85,65.58,147.103,66.283,145.844,66.224z"/>
        <path fill="#F6A5AB" d="M180.34,191.633c0.05,0.699-0.366,1.385-0.002,2.109c0.182,0.354-0.249,0.528-0.442,0.762
          c-0.132-0.01-0.265-0.021-0.396-0.031c-0.058-0.826-0.487-0.908-0.956-0.358c-0.367,0.436-0.664,0.612-0.987,0.108
          c-0.299-0.463-0.756-0.981-0.269-1.59c0.169-0.211,0.406-0.379,0.643-0.521c0.512-0.308,0.906-0.384,0.831,0.456
          c-0.028,0.32-0.124,0.847,0.385,0.802c0.271-0.023,0.385-0.519,0.396-0.879c0.02-0.509,0.057-1.017,0.085-1.523
          C179.932,191.115,179.945,191.578,180.34,191.633z"/>
        <path fill="#D8D2D8" d="M94.714,65.876c-0.321-0.139-0.609,0.543-0.907,0.068c-0.127-0.204-0.217-0.552,0.021-0.771
          c1.304-1.208,0.014-1.098-0.655-1.308c0.375-0.912,1.244-0.85,1.924-0.767c1.024,0.124,2.243-0.287,3.011,0.82
          c-0.107,0.112-0.217,0.223-0.323,0.334c-0.203-0.014-0.405-0.029-0.607-0.043c-0.36,0.297-0.692,0.475-1.116,0.04
          c-0.313-0.32-0.698-0.59-1.431-0.708c0.709,0.906-0.009,1.595,0.079,2.34L94.714,65.876z"/>
        <path fill="#F0EBF0" d="M30.174,82.736c-0.253,0.825-0.938,1.042-1.643,0.937c-0.896-0.133-1.448-0.713-1.441-1.692
          c0.233-0.104,0.344-0.28,0.426-0.529c0.351-1.053,0.838-1.146,1.748-0.331c-0.398,0.213-1.056,0.183-0.971,0.86
          c0.087,0.693,0.733-0.12,0.967,0.321c0.052,0.098,0.289,0.097,0.441,0.142C29.86,82.539,30.015,82.638,30.174,82.736z"/>
        <path fill="#D8D2D8" d="M115.355,133.045c0.186-0.596,0.406-1.063,1.079-1.379c0.593-0.279,1.488-0.623,1.172-1.691
          c-0.036-0.123,0.271-0.347,0.421-0.524c0.838,0.909,0.4,1.774-0.176,2.552C117.251,132.815,116.521,133.5,115.355,133.045z"/>
        <path fill="#F0EBF0" d="M266.793,55.566c-0.065,0.241,0.596,0.729-0.206,0.708c-0.79-0.02-0.919,0.524-1.158,1.09
          c-0.481,1.129-0.426,2.327-0.577,3.5C263.237,58.724,264.028,56.568,266.793,55.566z"/>
        <path fill="#D8D2D8" d="M308.642,126.374c0.563,0.482,0.927,1.448,1.978,0.672c0.283-0.21,0.188,0.582,0.605,0.535
          c0.065,0.401,0.374,0.46,0.7,0.491c0.079,0.318,0.158,0.639,0.237,0.958l0.002-0.032c-0.084,0.071-0.168,0.145-0.252,0.216
          c-0.607,0.3-1.204,0.419-1.759-0.113c0.371-0.17,0.677-0.386,0.184-0.734c-0.344-0.243-0.676-0.552-1.145-0.557
          C308.607,127.486,308.202,127.093,308.642,126.374z"/>
        <path fill="#694E82" d="M265.855,190.918c-0.367-0.547-0.733-1.092-1.103-1.637c1.305,0.854,2.158,2.563,4.132,2.014
          c-0.239-0.012-0.478-0.041-0.71-0.027c-0.26,0.019-0.599-0.086-0.717,0.252c-0.094,0.269,0.169,0.412,0.315,0.588
          c0.34,0.406,0.678,0.816,0.91,1.324C267.239,193.148,266.885,191.65,265.855,190.918z"/>
        <path fill="#E2403A" d="M307.721,178.588c-0.368,0.576-0.827,0.998-1.566,1.029c-0.507,0.02-0.96,0.002-1.104-0.607
          c-0.115-0.494,0.018-0.826,0.552-0.988c0.197-0.061,0.342-0.297,0.513-0.45c0.151,0.071,0.304,0.146,0.454,0.217
          c0.146,0.576,0.646,0.612,1.101,0.719l0.017,0.072L307.721,178.588z"/>
        <path fill="#D8D2D8" d="M29.702,82.442c-0.152-0.044-0.391-0.043-0.441-0.141c-0.232-0.441-0.88,0.372-0.967-0.321
          c-0.085-0.678,0.57-0.647,0.971-0.86c-0.91-0.816-1.397-0.722-1.747,0.331c-0.083,0.249-0.192,0.426-0.427,0.529
          c0.104-0.63-0.217-1.55,0.604-1.755c0.909-0.227,1.974-0.479,2.736,0.487C29.792,81.122,29.512,81.683,29.702,82.442z"/>
        <path fill="#D8D2D8" d="M45.989,128.973c0.397,0.72,0.717,1.287-0.641,1.02c-0.801-0.16-1.724,0.586-2.477-0.273
          C43.677,128.497,44.803,128.615,45.989,128.973z"/>
        <path fill="#96688B" d="M229.917,200.978c-0.025-0.982-1.11-1.154-1.534-2.046c1.104,0.092,1.626,1.15,2.564,1.285
          c0.29,0.416,0.576,0.834,0.874,1.244c0.182,0.248,0.499,0.51,0.14,0.793c-0.402,0.316-0.632-0.022-0.812-0.348
          C230.876,201.41,230.573,200.961,229.917,200.978z"/>
        <path fill="#F6A5AB" d="M184.954,197.127c-0.079-1.314-1.235-0.652-1.886-1.088c-0.118-0.357,0.431-0.424,0.485-0.752
          c0.545-0.136,0.743-0.688,1.169-0.97c0.584,0.31,0.476,0.828,0.373,1.314c-0.159,0.754,0.267,0.434,0.579,0.342
          c-0.068,0.476,0.362,0.74,0.477,1.14C185.753,197.119,185.353,197.123,184.954,197.127z"/>
        <path fill="#D8D2D8" d="M249.797,55.745c-0.089,0.084-0.18,0.168-0.27,0.252c-0.091,0.097-0.182,0.192-0.271,0.29
          c-0.378-0.123-0.688-0.535-1.032,0.218c-0.295,0.648-1.063,0.881-1.925,0.592c0.245-0.734,1.302-0.768,1.302-1.604
          C248.395,55.031,249.127,55.12,249.797,55.745z"/>
        <path fill="#E2403A" d="M309.026,180.853c-0.531,0.603-1.158,0.75-1.896,0.408c-0.256-0.119-0.527-0.221-0.505-0.566
          c0.024-0.393,0.321-0.521,0.646-0.563c0.465-0.059,0.937-0.074,1.404-0.107C308.909,180.252,309.587,180.293,309.026,180.853z"/>
        <path fill="#D8D2D8" d="M296.1,128.155c-0.72,0.934-1.583,1.703-2.591,2.313c0.102-0.844,0.41-1.653,1.001-2.214
          C294.876,127.906,295.537,127.566,296.1,128.155z"/>
        <path fill="#F0EBF0" d="M311.641,90.562c0.416,0.438,0.229,0.986,0.27,1.492c-0.798,0.885-0.742,1.81-0.125,2.758
          c-0.031,0.084-0.063,0.169-0.093,0.254c-0.938-0.501-1.256-1.57-0.8-2.909C311.081,91.604,311.389,91.092,311.641,90.562z"/>
        <path fill="#694E82" d="M277.334,189.941c0.114-0.655,0.506-0.284,0.813-0.217c0.688,0.838,1.148,1.896,2.258,2.336
          c0.271,0.107,0.349,0.361,0.155,0.572c-0.197,0.219-0.449,0.104-0.63-0.076C279.06,191.689,278.198,190.814,277.334,189.941z"/>
        <path fill="#D8D2D8" d="M112.463,128.52c0.951-0.169,1.171,0.147,0.852,1.074c-0.224,0.646-0.101,1.419-0.851,1.813
          C111.756,130.444,112.222,129.482,112.463,128.52z"/>
        <path fill="#FEFEFE" d="M300.885,59.876c-0.396-0.944-1.036-1.829-0.694-2.946c0.745,0.357,1.176,0.965,1.387,1.747
          C300.912,58.826,301.237,59.547,300.885,59.876z"/>
        <path fill="#D8D2D8" d="M313.397,74.209c0.495,0.073,1.225-0.32,1.17,0.756c-0.018,0.374,0.791,0.251,0.997,0.701
          c0.13,0.284,0.388,0.62,0.14,0.867c-0.327,0.323-0.504-0.066-0.729-0.284c-0.657-0.63-1.202-1.346-1.723-2.086
          C313.31,74.154,313.358,74.168,313.397,74.209z"/>
        <path fill="#D8D2D8" d="M150.881,127.808c0.146,0.25,0.321,0.439,0.646,0.446c0.974,0.021,0.234,0.627,0.263,0.921
          c0.044,0.48-0.293,0.62-0.702,0.351c-0.31-0.202-0.635-0.313-0.997-0.337C150.204,128.643,150.28,128.075,150.881,127.808z"/>
        <path fill="#E2403A" d="M306.503,183.127c0.383-1.141,0.776-1.219,1.583-0.314C307.859,184.441,307.085,183.297,306.503,183.127z"
          />
        <path fill="#D8D2D8" d="M133.115,127.597c-0.072,1.156-1.192,0.743-1.708,1.276c-0.273-0.497,0.063-0.86,0.01-1.243
          C131.971,127.066,132.543,127.318,133.115,127.597z"/>
        <path fill="#D8D2D8" d="M316.474,73.769c0.003,0.447-0.118,0.856-0.455,1.462c-0.139-1.024-1.194-1.262-1.202-2.188
          C315.432,73.14,316.204,72.886,316.474,73.769z"/>
        <path fill="#FEFEFE" d="M29.702,82.442c-0.188-0.758,0.091-1.319,0.729-1.729c0.599,0.761,0.416,1.423-0.256,2.023
          C30.015,82.638,29.86,82.539,29.702,82.442z"/>
        <path fill="#D8D2D8" d="M171.743,130.897c0.42-0.717,1.52-0.676,1.727-1.837c0.21,0.546,0.329,0.857,0.447,1.167
          C173.343,130.938,172.504,130.791,171.743,130.897z"/>
        <path fill="#F0EBF0" d="M309.195,127.809c0.469,0.005,0.801,0.313,1.145,0.557c0.493,0.35,0.188,0.564-0.184,0.734
          C309.406,128.99,309.25,128.437,309.195,127.809z"/>
        <path fill="#D8D2D8" d="M249.963,61.533c-0.15-0.278-0.728-0.357-0.482-0.797c0.23-0.42,0.734-0.352,1.147-0.41
          C250.62,60.846,250.415,61.257,249.963,61.533z"/>
        <path fill="#D8D2D8" d="M98.177,65.896c-1.104,0.249-0.537-0.55-0.597-0.955c0.077-0.002,0.154-0.011,0.229-0.023
          c0.205-0.011,0.411-0.023,0.617-0.034C98.501,65.259,98.563,65.633,98.177,65.896z"/>
        <path fill="#D8D2D8" d="M246.878,55.575c-0.249,0.53-0.653,0.845-1.25,0.89C245.896,55.96,246.185,55.483,246.878,55.575z"/>
        <path fill="#F0EBF0" d="M98.428,64.883c-0.206,0.011-0.411,0.023-0.617,0.034c-0.01-0.221-0.02-0.442-0.026-0.664
          c0.107-0.111,0.216-0.223,0.324-0.334C98.451,64.162,98.554,64.484,98.428,64.883z"/>
        <path fill="#D8D2D8" d="M130.438,128.496c0.599,0.63,0.333,0.912-0.404,1.003C130.064,129.122,130.168,128.775,130.438,128.496z"
          />
        <path fill="#D8D2D8" d="M28.25,128.789c-0.189,0.494-0.59,0.476-1.008,0.413C27.408,128.644,27.877,128.835,28.25,128.789z"/>
        <path fill="#D8D2D8" d="M314.315,73.086c-0.013,0.502-0.243,0.757-0.771,0.675C313.681,73.399,313.924,73.156,314.315,73.086z"/>
        <path fill="#FDA8A9" d="M307.669,178.504c-0.454-0.104-0.952-0.141-1.101-0.719C307.11,177.758,307.473,178.004,307.669,178.504z"
          />
        <path fill="#D8D2D8" d="M315.804,94.96c-0.043-0.335-0.013-0.637,0.355-0.784C316.187,94.504,316.147,94.801,315.804,94.96z"/>
        <path fill="#D8D2D8" d="M297.263,128.527c-0.273-0.017-0.533-0.067-0.721-0.297C296.899,128.048,297.099,128.242,297.263,128.527z
          "/>
        <path fill="#D8D2D8" d="M97.768,67.062c-0.221-0.411,0.096-0.396,0.346-0.432C97.998,66.774,97.884,66.918,97.768,67.062z"/>
        <path fill="#D8D2D8" d="M78.404,69.182c0.147-0.146,0.3-0.152,0.454-0.008C78.709,69.365,78.556,69.365,78.404,69.182z"/>
        <path fill="#D8D2D8" d="M29.887,132.088c0.041-0.279,0.252-0.381,0.481-0.465C30.373,131.95,30.125,132.014,29.887,132.088z"/>
        <path fill="#D8D2D8" d="M245.617,56.474c0.006,0.306-0.143,0.477-0.445,0.511C245.233,56.74,245.361,56.551,245.617,56.474z"/>
        <path fill="#D8D2D8" d="M296.764,61.337c0.32,0.024,0.469,0.204,0.464,0.519C296.997,61.751,296.823,61.595,296.764,61.337z"/>
        <path fill="#F0EBF0" d="M148.24,63.975c0.008-0.102,0.015-0.204,0.021-0.305C148.412,63.782,148.408,63.884,148.24,63.975z"/>
        <path fill="#E2403A" d="M103.739,200.906c0,0-0.046,0.004-0.048,0.004C103.691,200.906,103.739,200.906,103.739,200.906z"/>
        <path fill="#D8D2D8" d="M275.299,131.297c-0.032,0.004-0.064,0.008-0.098,0.01c0.03-0.014,0.063-0.026,0.098-0.039
          C275.3,131.268,275.299,131.297,275.299,131.297z"/>
        <path fill="#F0EBF0" d="M311.914,129.214c0.084-0.071,0.168-0.145,0.252-0.216C312.082,129.071,311.998,129.142,311.914,129.214z"
          />
        <path fill="#A896A6" d="M214.418,173.801c3.009-0.906,6.092-1.488,9.166-2.104c2.527-0.507,4.979-1.274,7.451-2.005
          c3.59-1.059,7.021-2.525,10.563-3.704c1.882-0.628,3.688-1.476,5.529-2.222c0.628,0.029,1.027,0.404,1.506,0.785
          c1.047,0.838,1.55,2.174,2.739,2.853c0.024,0.157,0.08,0.315,0.07,0.474c-0.118,2.088-0.297,4.188,1.459,5.805
          c-2.578,0.469-5.223,0.582-7.729,1.432c-1.562,0.243-3.149,0.393-4.49,1.358c-0.905,0.269-1.807,0.553-2.718,0.797
          c-2.547,0.688-4.788,2.215-7.419,2.664c-1.574,0.642-3.194,1.195-4.518,2.328c-0.25,0.158-0.5,0.314-0.75,0.473
          c-0.478-0.579-0.966-1.145-1.696-1.422l0.016,0.017c-1.293-2.351-3.61-3.605-5.578-5.234
          C216.82,175.328,215.62,174.565,214.418,173.801z"/>
        <path fill="#A896A6" d="M137.166,157.381c0.005-1.207,0.704-1.813,1.813-1.961c2.519-0.334,4.979-0.93,7.414-1.627
          c0.896-0.256,1.798-0.27,2.688-0.438l-0.009-0.014c0.167-0.082,0.334-0.164,0.499-0.246c0.178,0.393,0.453,0.172,0.708,0.121
          c1.284-0.257,2.578-0.47,3.853-0.765c2.374-0.552,4.736-1.146,7.102-1.728c1.522-0.375,3.071-0.713,4.545-1.201
          c1.031-0.344,2.25,0.029,3.109-0.846c1.626,0.074,3.15-0.33,4.626-0.973c1.153,0.133,2.38-0.371,3.465,0.387
          c0.485,0.605,0.76,1.262,0.49,2.047c-0.36-0.047-0.608,0.072-0.679,0.459c-0.158,0.152-0.317,0.308-0.478,0.459
          c-0.535,0.025-0.854,0.396-1.187,0.744c-0.356,0.354-0.715,0.707-1.071,1.062c-3.267,3.282-7.173,5.396-11.546,6.931
          c-3.988,1.4-7.908,0.848-11.894,0.535c-2.42-0.189-4.867-0.105-7.254-0.627c-1.934-0.421-3.927-0.619-5.741-1.494
          C137.471,157.934,137.32,157.657,137.166,157.381z"/>
        <path fill="#E9605D" d="M176.98,148.094c-1.085-0.756-2.312-0.252-3.464-0.385c-0.232,0.053-0.423-0.017-0.568-0.207
          c2.547-0.93,5.277-1.006,7.869-1.728c1.834-0.509,3.742-0.386,5.597-0.63c1.954-0.258,3.957-0.109,5.876-0.68
          c0.406-0.121,0.879-0.136,1.304-0.072c2.685,0.404,5.409-0.146,8.087,0.371c0.728,0.143,1.438-0.041,2.116-0.239
          c1.405-0.414,2.817-0.452,4.254-0.271c0.33,0.426,0.943,0.59,1.004,1.368c0.263-0.541,0.435-0.896,0.604-1.25
          c2.509,0.871,4.979-0.696,7.523,0.156c-0.062-0.761-0.427-0.791-0.784-0.817c-1.624-0.125-3.25-0.24-4.874-0.369
          c-0.425-0.033-1.087,0.021-1.063-0.5c0.022-0.567,0.701-0.33,1.093-0.365c2.97-0.256,5.907-0.762,8.874-1.059
          c1.831-0.186,3.608-0.906,5.403-1.41c0.296-0.082,0.671-0.252,0.563-0.648c-0.124-0.467-0.569-0.332-0.913-0.335
          c-3.188-0.015-6.375-0.024-9.563-0.036c0.38-0.193,0.759-0.387,1.109-0.564c-0.216-0.67-0.77-0.221-1.104-0.432
          c2.233-0.537,4.452-1.193,6.764-1.281c1.222-0.045,2.334-0.389,3.549-0.777c-0.275-0.285-0.553-0.162-0.787-0.196
          c-0.093-0.479,0.171-0.759,0.548-0.976c0.762,0.228,1.62-0.205,2.333,0.351c-1.055,0.433-1.244,0.729-0.867,1.353
          c-2.276,1.008-4.737,1.149-7.15,1.694c2.06,0,4.117,0,6.177,0c-0.021,0.373,0.255,0.58,0.465,0.821
          c-0.834,2.16-2.85,2.429-4.713,2.888c-1.308,0.321-2.648,0.313-4,0.263c-0.645-0.027-1.276,0.274-2.02,0.67
          c0.604,0.235,1.068,0.416,1.531,0.602c0.355,0.143,0.608,0.385,0.648,0.783c0.046,0.455-0.313,0.598-0.625,0.777
          c-0.247,0.146-0.505,0.188-0.789,0.221c-2.282,0.285-4.557,0.678-6.834,0.955c-1.856,0.229-3.791,0.289-5.688-0.221
          c-1.532-0.412-3.128-0.479-4.729-0.341c-2.847,0.239-5.701,0.33-8.552,0.134c-0.977-0.068-1.855,0.105-2.79,0.315
          c-1.079,0.241-2.222,0.213-3.338,0.271c-0.516,0.025-1.034-0.02-1.552-0.031c-0.538,0.273-1.225-0.117-1.715,0.377
          c-0.32,0.076-0.645,0.15-0.965,0.228C179.51,147.163,178.139,147.297,176.98,148.094z"/>
        <path fill="#96688B" d="M183.51,148.668c0.053,0.029,0.106,0.043,0.168,0.041c0.396,0.49,0.8,0.973,0.974,1.598
          c0.077,0.277,0.149,0.57,0.543,0.33c1.297-0.182,1.979,0.521,2.359,1.646c0.141,0.416,0.227,0.922,0.865,0.563
          c1.686-0.942,2.619,0.121,3,1.746c0.1,0.422,0.155,0.854,0.231,1.281c-0.194,0.35-0.653,0.229-0.896,0.502
          c-0.093-0.75-0.177-1.498-0.278-2.246c-0.062-0.443-0.167-0.91-0.697-0.988c-0.521-0.078-0.723,0.358-0.966,0.729
          c-0.418,0.636-0.503,1.481-1.257,1.897c0.469,0.51,1.336,0.521,1.473,1.334c0.083,0.311,0.165,0.615,0.247,0.924
          c-0.931-0.559-1.626-1.531-2.871-1.721c0.249,1.435,0.44,2.758,0.714,4.064c0.486,2.327,0.188,4.725,0.652,7.053
          c-0.805,0.021-0.376,0.936-0.855,1.19c0.036-3.427-0.604-6.794-0.966-10.183c-0.12-1.123-0.076-2.555-1.079-3.312
          c-1.243-0.938-2.663-1.672-4.16-2.194c-0.07-0.07-0.191-0.209-0.205-0.201c-0.166,0.123-0.065,0.24,0.027,0.354
          c0.247,0.858,0.495,1.722,0.742,2.583c-0.192,0.078-0.388,0.154-0.581,0.232c-0.047-0.438,0.036-0.901-0.28-1.276
          c-0.047-0.065-0.095-0.134-0.142-0.198c-0.115-0.326-0.231-0.65-0.349-0.979c-0.003-0.146-0.004-0.297-0.006-0.444
          c0.082-0.409-0.306-0.347-0.49-0.481c-0.037-0.032-0.078-0.039-0.124-0.021c-0.112-0.101-0.226-0.196-0.337-0.295
          c0.004-0.062,0.012-0.121,0.021-0.183c0.341-0.002,0.798,0.441,0.956-0.252c0.3,0.025,0.6,0.051,0.897,0.074
          c0.362,0.385,0.82,0.604,1.318,0.754c0.297,0.504,0.883,0.498,1.325,0.744c0.193,0.104,0.367,0.043,0.484-0.18
          c0.209-0.396-0.216-3.589-0.536-3.85c-0.085-0.066-0.212-0.086-0.322-0.12c-1.112-0.347-1.572-0.082-1.94,1.131
          c-0.077,0.259-0.039,0.628-0.447,0.543c-0.312-0.063-0.395-0.377-0.402-0.665c-0.005-0.195,0.088-0.402,0.055-0.591
          c-0.084-0.479,0.136-1.175-0.498-1.345c-0.658-0.174-1.251,0.108-1.531,0.813c-0.043-0.002-0.082,0.008-0.118,0.03
          c-0.344-0.181-0.313-0.362-0.063-0.634c0.744-0.799,1.616-1.057,2.658-0.719l0.005,0.008c0.063,0.343,0.097,0.701,0.532,0.789
          c0.156-0.037,0.313-0.076,0.469-0.112c0.479,0.008,0.957,0.019,1.437,0.026l-0.016-0.021
          C183.336,148.526,183.422,148.598,183.51,148.668z M185.427,151.559c-0.643-0.356-1.011,0.078-1.085,0.562
          c-0.076,0.489,0.449,0.36,0.771,0.377c-0.398,1.692,0.084,2.461,1.763,2.83c0.429,0.095,0.512-0.14,0.438-0.453
          c-0.23-0.989-0.504-1.974-0.741-2.961C186.384,151.129,185.942,151.243,185.427,151.559z"/>
        <path fill="#694E82" d="M190.758,156.377c0.239-0.271,0.698-0.152,0.895-0.502c1.425-0.957,2.121-0.645,2.663,1.203
          c-0.138,1.01-0.002,1.973,0.473,2.881c0.009,0.319-0.421,0.696,0.017,0.946c0.606,0.349,0.973,1.015,1.666,1.231
          c1.012,0.826,1.081,0.81,1.93-0.486l0.006-0.008c0.072-0.086,0.146-0.174,0.22-0.262c1.411-0.508,1.838-0.369,2.6,0.85
          c-0.021,0.391,0.095,0.863-0.553,0.839c-0.705-1.873-0.975-1.923-2.25-0.403c-0.104,0.123-0.177,0.275-0.3,0.369
          c-0.379,0.286-0.369,0.568-0.026,0.863c0.896,0.777,1.441,1.893,2.461,2.555l-0.015-0.016c0.801,0.82,1.601,1.643,2.398,2.461
          c0.221,0.244,0.473,0.184,0.738,0.086c0.532-0.193,0.694,0.096,0.708,0.563l-0.007,0.006c-0.031,0.021-0.091,0.039-0.091,0.06
          c0.003,0.063,0.023,0.125,0.038,0.188c0.359,0.578,0.902,0.971,1.425,1.379c0.259,0.203,0.504,0.219,0.628-0.162
          c0.226,0.24,0.448,0.482,0.674,0.727c-0.104,1.036,1.029,1.021,1.41,1.648l-0.021,0.021c-0.533,0.407-0.831-0.363-1.304-0.264
          c-1.049-0.549-1.574-1.768-2.755-2.146c-0.469-0.81-1.383-1.158-1.934-1.881c-0.394-0.586-1.018-0.688-1.646-0.777
          c-0.967-0.969-1.932-1.938-2.896-2.908c-0.339-0.301-0.885-0.256-1.146-0.686c-0.479-1.172-1.816-1.681-2.231-2.938
          c-0.081-0.248-0.389-0.836-0.976-0.676c-0.201-0.006-0.401-0.01-0.604-0.016c-0.021-0.416-0.18-0.691-0.574-0.934
          c-0.746-0.454-1.424-1.021-2.129-1.543c-0.114-0.506-0.529-0.385-0.874-0.416c-0.052-0.061-0.083-0.129-0.1-0.207
          c-0.082-0.309-0.164-0.613-0.247-0.924c0.144,0.066,0.284,0.131,0.426,0.197c0.09,0.207,0.267,0.274,0.474,0.289
          c0.657,0.439,1.322,0.873,1.973,1.326c0.509,0.354,1.046,1.039,1.61,0.75c0.423-0.218,0.075-1.035,0.086-1.586
          c0.011-0.693,0.275-1.582-0.583-1.887c-0.857-0.301-1.056,0.621-1.441,1.127c-0.146,0.191-0.253,0.439-0.55,0.324
          c-0.241-0.094-0.374-0.293-0.354-0.563C190.688,156.848,190.726,156.615,190.758,156.377z"/>
        <path fill="#96688B" d="M177.076,177.269c0.098-0.573-0.061-1.104-0.268-1.631c-0.407-1.035-0.583-2.009,0.63-2.683
          c0.467-0.259,0.34-0.637,0.104-1.023c-0.502-0.834-0.896-1.654-0.417-2.697c0.225-0.482-0.331-0.918-0.618-1.327
          c-0.751-1.073-0.684-1.95,0.24-2.966c0.311-0.34-0.113-0.459-0.219-0.674c0.069-0.229,0.14-0.463,0.208-0.692
          c0.423,0.332,0.845,0.666,1.269,1c0.016,0.286,0.168,0.483,0.41,0.621c-0.247,0.392-0.645,0.214-0.979,0.257
          c-0.295,0.038-0.567,0.111-0.596,0.461c-0.068,0.9,0.017,1.799,0.78,2.387c0.497,0.385,1.23,0.67,1.777,0.416
          c0.646-0.301,0.128-0.986,0.078-1.5c-0.025-0.273-0.13-0.539-0.198-0.81c1.234,0.157,0.497-0.761,0.649-1.19
          c0.292,0.26,0.365,0.631,0.402,0.977c0.255,2.375,0.425,4.759,0.1,7.137c-0.167,1.221,0.254,2.478-0.286,3.664
          c-0.292,0.014-0.585,0.024-0.878,0.039c0.343-1.111,0.227-2.24,0.139-3.367c0.705-0.255,0.358-0.518,0.009-0.779
          c0.73-1.113,0.022-2.316,0.313-3.478c-2.249,0.306-2.372,0.519-1.442,2.392c0.141,0.281,0.319,0.543,0.481,0.813
          c-0.936,0.781,0.148,0.719,0.417,0.994c-1.863-0.008-2.045,0.236-1.517,2.041c0.108,0.371,0.213,0.746,0.317,1.119
          C177.717,176.99,177.614,177.519,177.076,177.269z"/>
        <path fill="#E9605D" d="M137.166,157.381c0.153,0.274,0.306,0.553,0.458,0.828c-1.146,1.321-1.013,2.215,0.453,2.998
          c3.494,1.864,5.615,7.174,4.3,10.814c-0.909,2.521-2.921,3.899-5.232,4.937c-0.646,0.291-1.256,0.838-2.328,0.393
          c1.671-0.328,2.812-1.194,3.969-1.985c2.482-1.696,3.669-4.146,3.148-6.979c-0.283-1.541-1.296-2.899-2.275-4.164
          c-0.458-0.59-0.796-1.305-1.503-1.678c-0.208-0.109-0.503-0.361-0.614-0.299c-1.547,0.891-1.322-0.576-1.354-1.16
          C136.117,159.785,135.765,158.323,137.166,157.381z"/>
        <path fill="#96688B" d="M176.316,151.061c0.158-0.151,0.317-0.307,0.478-0.459l-0.002-0.004c0.05,0.168,0.1,0.334,0.147,0.5
          c0.443,2.015,0.911,4.023,1.326,6.043c0.454,2.211,1.371,4.318,1.482,6.605c-0.166-0.488-0.19-1.191-1.021-0.671
          c-0.066-0.308-0.139-0.61-0.2-0.918c-0.153-0.769-0.439-1.243-1.263-0.599c-0.246,0.193-0.499,0.379-0.749,0.566
          c-0.367-0.063-0.45-0.313-0.417-0.636c0.521-0.317,0.88-0.629,0.268-1.239c-0.965-0.961-1.137-2.141-0.761-3.418
          c-0.039-0.313-0.001-0.652,0.408-0.445c1.506,0.762,1.334,0.041,0.928-0.984c-0.126-0.316-0.125-0.686-0.191-1.027
          c-0.106-0.541-0.404-0.508-0.875-0.392c-0.833,0.207-0.635,1.099-1.08,1.53c-0.41-0.198-0.371-0.642-0.618-0.894
          c0.06-0.592,1.548-1.28-0.118-1.756c0.358-0.354,0.717-0.709,1.074-1.063c0.006,0.117,0.027,0.236,0.016,0.35
          c-0.066,0.625,0.235,0.857,0.824,0.806c0.779-0.067,0.193-0.575,0.265-0.866C176.315,151.762,176.294,151.407,176.316,151.061z
           M178.015,160.807c0.006-1.078,0.068-1.91-0.356-2.727c-0.211-0.406-0.258-0.877-0.868-0.643
          c-0.554,0.213-0.813,0.559-0.559,1.197C176.566,159.473,177.116,160.108,178.015,160.807z"/>
        <path fill="#E9605D" d="M168.889,148.682c-0.859,0.875-2.078,0.502-3.109,0.847c-1.474,0.487-3.021,0.825-4.545,1.2
          c-2.364,0.582-4.729,1.177-7.102,1.728c-1.273,0.295-2.567,0.509-3.853,0.765c-0.256,0.051-0.531,0.271-0.708-0.121
          c0.928-0.693,1.982-0.982,3.123-1c0.529-0.008,1.027-0.035,1.379-0.539c0.123-0.176,0.388-0.383,0.571-0.368
          c1.731,0.125,3.073-1.034,4.654-1.368c1.106-0.234,2.199-0.305,3.229-0.979c0.58-0.377,1.569-0.563,2.392-0.004
          c0.392,0.27,0.85,0.021,1.273-0.07c0.967-0.211,1.896-0.656,2.926-0.488C169.044,148.413,168.969,148.547,168.889,148.682z"/>
        <path fill="#E9605D" d="M268.409,173.709c-4.843,0.182-9.668,0.584-14.478,1.172c-0.202,0.024-0.395,0.055-0.564-0.09
          c0.155-0.141,0.313-0.279,0.467-0.42c0.81-0.324,0.808-0.914,0.566-1.623c-0.172-0.509-0.596-0.918-0.577-1.498
          c0.204,0.119,0.515,0.188,0.597,0.364c0.719,1.541,2.062,1.425,3.379,1.269c1.229-0.146,2.419-0.162,3.665,0.021
          c1.147,0.168,2.362-0.162,3.552-0.205c1.072-0.038,2.149,0.013,3.227,0.022c-0.124,0.256-0.121,0.443,0.235,0.441l-0.006-0.007
          C268.449,173.34,268.429,173.526,268.409,173.709z"/>
        <path fill="#E2403A" d="M117.481,199.582c1.525-0.008,2.367,0.61,2.36,1.736c-0.009,1.332-1.273,2.76-2.471,2.782
          c-1.149,0.021-1.843-0.881-1.866-2.428C115.482,200.199,116.056,199.59,117.481,199.582z"/>
        <path fill="#E9605D" d="M252.903,173.682c-1.756-1.615-1.576-3.717-1.459-5.805c0.01-0.156-0.046-0.314-0.07-0.474
          c-0.473-1.983-1.854-3.513-2.534-5.394c0.063,1.297,0.063,1.297-1.244,1.051c-0.31,0.023-0.618,0.047-1.242,0.095
          c1.774-1.267,0.313-2.468,0.287-3.681l-0.008,0.008c0.171,0.076,0.342,0.152,0.514,0.229l-0.022-0.006
          c-0.119,0.67,0.12,1.027,0.845,0.969c0.438,0.354,0.611,1.1,1.395,0.949c0.843,0.553,1.039,1.543,1.387,2.35
          c0.784,1.824,2.004,3.5,2.14,5.576c-0.563-0.473-0.818-0.326-0.834,0.393c-0.027,1.268,0.413,2.328,1.333,3.195
          C253.155,173.26,253.25,173.666,252.903,173.682z"/>
        <path fill="#E9605D" d="M98.583,183.258c1.01,0.17,2.082-0.188,3.104,0.447c-3.113,0.563-5.982,1.973-9.153,2.155
          c-0.313,0.019-0.668,0.107-0.799-0.307c-0.066-0.209,0.138-0.311,1.077-0.518c-2.568-0.429-5.02-0.353-7.461-0.4
          c0.32-0.356,0.986,0.002,1.2-0.578c0.032-0.102,0.064-0.203,0.097-0.305c0.833,0.02,1.666,0.041,2.499,0.061
          c0.483,0.218,0.99,0.009,1.482,0.082c0.486,0.011,0.973,0.021,1.458,0.029c0.95,0.402,1.902,0.359,2.858,0.021
          c0.083,0.01,0.167,0.018,0.251,0.022c-0.063,0.814,0.026,1.088,1.032,0.769C97.199,184.43,97.894,183.902,98.583,183.258z"/>
        <path fill="#E9605D" d="M245.676,156.594c-0.513-0.338-0.533-0.961-0.814-1.436c-0.319-0.541-0.352-1.137-1.302-1.199
          c-0.549-0.037-0.954-1.012-1.131-1.713c-0.391-1.559-0.913-3.059-1.854-4.359c-0.673-0.932-0.838-1.686,0.402-2.34
          c1.063,3.854,3.695,6.928,5.161,10.58C245.855,156.157,245.724,156.336,245.676,156.594z"/>
        <path fill="#E9605D" d="M220.504,133.813c3.075,0.168,6.055-0.561,9.078-0.985c-0.847,0.763-1.884,1.024-2.956,1.177
          c-0.866,0.118-1.727,0.264-2.556,0.555c-0.021,0.02-0.061,0.041-0.058,0.057c0.011,0.051,0.036,0.098,0.056,0.146l0.003,0.004
          c0.178,0.317,0.146,0.641,0.011,0.961c-0.22-0.01-0.438-0.021-0.655-0.029c0.013-0.797-0.267-1.094-1.165-1.195
          c-0.9-0.104-1.688,0.271-2.505,0.221c-0.943-0.059-0.84,0.408-0.752,0.992c-0.907-0.037-1.816-0.063-2.727-0.115
          c-0.366-0.021-0.751-0.11-0.775-0.574c-0.022-0.432,0.345-0.479,0.661-0.528c1.216-0.188,2.435-0.371,3.648-0.556
          C220.063,134.01,220.292,133.956,220.504,133.813z"/>
        <path fill="#E9605D" d="M102.578,169.131c0.082,0.24,0.163,0.479,0.245,0.721c0.083,0.229,0.166,0.455,0.249,0.684
          c0.687,2.387,1.571,4.721,1.951,7.188c-0.929-1.131-0.898-2.604-1.37-3.899c-0.391-1.067-0.594-2.204-1.18-3.235
          c-0.12,2.467-0.49,4.87-1.183,7.219c-0.104-0.377-0.255-0.713-0.699-0.793c-0.201-0.854,0.375-1.567,0.427-2.377
          c0.145-0.187,0.122-0.365-0.021-0.541c-0.005-0.276-0.01-0.557-0.016-0.834c0.059-0.188,0.115-0.375,0.174-0.563
          c0.015-1.526,0.027-3.054,0.043-4.577c0.462,0.48-0.05,1.346,0.678,1.723c0.041,0.002,0.082,0.002,0.123,0.004
          c-0.019-0.024-0.036-0.049-0.053-0.074C101.98,169.385,102.163,169.143,102.578,169.131z"/>
        <path fill="#96688B" d="M200.669,163.072c0.646,0.024,0.532-0.448,0.553-0.839c0.205,0.699,0.181,1.605,1.413,1.057
          c0.467-0.207,0.965,0.148,1.223,0.704c0.4,0.856,0.405,1.759,0.472,2.671c0.021,0.291-0.429,1.073,0.509,0.971
          c0.255,0.045,0.509,0.09,0.763,0.135c-0.591,0.468-0.596,1.332-1.213,1.781c-0.014-0.469-0.176-0.758-0.708-0.563
          c-0.267,0.097-0.52,0.157-0.738-0.086c0.259-0.022,0.487-0.123,0.489-0.41c0.004-1.063,0.051-2.134-0.058-3.185
          c-0.043-0.42,0.116-1.158-0.365-1.25c-0.576-0.105-0.696,0.662-0.939,1.066c-0.379,0.629-0.514,1.45-1.525,1.314l0.015,0.016
          C200.592,165.326,200.63,164.198,200.669,163.072z"/>
        <path fill="#DC271E" d="M207.139,173.156c0.473-0.103,0.771,0.671,1.304,0.263c0.425-0.063,0.853-0.287,1.271,0.021
          c0.46,0.369,0.911,0.107,1.363-0.02l0.04,0.008c0.476,0.381,1.005,0.205,1.522,0.156c0.131,0.02,0.262,0.039,0.393,0.059
          c0.109,0.388-0.078,0.373-0.373,0.31c-0.392-0.081-0.816-0.231-1.245,0.271c1.196,0.219,2.144,0.76,3.244,0.979
          c1.163,0.229,2.351,0.661,3.395,1.325c0.463,0.396,0.574,0.703-0.203,0.773c-0.206-0.09-0.412-0.182-0.617-0.273l-0.004-0.063
          l0.001,0.065c-0.053-0.016-0.089,0.003-0.108,0.056c-0.075-0.027-0.15-0.029-0.229-0.008c-2.053-0.871-4.086-1.791-6.271-2.323
          c-1.016-0.248-2.02-0.268-3.041-0.22c-0.162-0.446-0.536-0.414-0.901-0.403C206.504,173.647,207.106,173.536,207.139,173.156z"/>
        <path fill="#E2403A" d="M137.116,169.725c0.029,0.765-0.36,1.084-1.107,0.996c-0.859-0.1-2.012-1.293-2.119-2.184
          c-0.139-1.162,0.515-1.721,1.557-1.229C136.426,167.77,137.103,168.538,137.116,169.725z"/>
        <path fill="#96688B" d="M209.713,173.438c-0.418-0.309-0.846-0.084-1.271-0.021l0.021-0.021c0.074-0.184,0.184-0.357,0.22-0.551
          c0.14-0.748,0.695-1.719-0.021-2.199c-0.992-0.664-1.011,0.787-1.608,1.102c-0.225-0.242-0.448-0.484-0.674-0.727
          c-0.056-1.063,0.116-2.16-0.471-3.145c0.296-0.076,0.574,0.85,0.88,0.014c0.097,0.125,0.27,0.244,0.273,0.373
          c0.049,1.027,0.312,1.639,1.596,1.605c1.115-0.027,1.071,1.104,1.155,1.904c0.054,0.502-0.083,1.025-0.134,1.539
          C209.683,173.356,209.694,173.397,209.713,173.438z"/>
        <path fill="#96688B" d="M194.787,159.959c-0.475-0.908-0.608-1.871-0.473-2.881c0.075,0.262,0.194,0.52,0.216,0.786
          c0.041,0.493,0.178,0.688,0.745,0.571c1.454-0.295,1.942,0.093,2.123,1.543c0.044,0.354,0.153,0.711,0.115,1.056
          c-0.09,0.808,0.369,0.729,0.884,0.616c-0.849,1.296-0.918,1.313-1.93,0.486c0.436-0.871,0.061-1.744-0.026-2.606
          c-0.063-0.636-0.538-0.614-0.949-0.343C195.211,159.377,195.018,159.698,194.787,159.959z"/>
        <path fill="#E9605D" d="M254.534,143.092c1.896-0.123,3.701,0.707,5.614,0.494c0.398-0.043,0.756-0.027,1.103-0.281
          c0.245-0.182,0.571-0.139,0.717,0.162c0.147,0.309-0.104,0.516-0.326,0.686c-0.091,0.068-0.214,0.125-0.326,0.134
          c-2.237,0.146-4.457,0.123-6.558-0.826C254.612,143.393,254.481,143.291,254.534,143.092z"/>
        <path fill="#DC271E" d="M253.384,173.139c-0.921-0.867-1.36-1.93-1.333-3.195c0.016-0.719,0.271-0.863,0.834-0.393
          c0.291,0.58,1.008,0.922,0.938,1.699c-0.019,0.58,0.405,0.989,0.577,1.498c0.239,0.709,0.241,1.299-0.566,1.623
          c-0.315-0.316-0.597-0.602-0.185-1.087C253.81,173.094,253.511,173.137,253.384,173.139z"/>
        <path fill="#FCA7A8" d="M247.594,163.063c1.307,0.246,1.307,0.246,1.244-1.051c0.681,1.881,2.063,3.408,2.534,5.394
          c-1.189-0.679-1.692-2.015-2.739-2.853c-0.479-0.381-0.878-0.756-1.506-0.785C247.324,163.559,247.566,163.381,247.594,163.063z"
          />
        <path fill="#E2403A" d="M245.676,156.594c0.05-0.258,0.181-0.438,0.462-0.467c0.864,1.746,2.389,3.078,3.091,4.926
          c0.068,0.182,0.089,0.381,0.132,0.572c-0.781,0.148-0.957-0.595-1.395-0.949c-0.14-0.447-0.188-0.976-0.845-0.969l0.022,0.006
          C247,158.51,246.141,157.645,245.676,156.594z"/>
        <path fill="#E2403A" d="M218.052,176.525c-1.044-0.664-2.229-1.096-3.395-1.323c-1.103-0.221-2.048-0.762-3.244-0.98
          c0.429-0.504,0.854-0.354,1.245-0.271c0.295,0.063,0.482,0.076,0.373-0.311c0.489-0.186,0.911,0.213,1.387,0.16
          c1.2,0.764,2.401,1.527,3.604,2.291C218.031,176.236,218.041,176.381,218.052,176.525z"/>
        <path fill="#F6A5AB" d="M174.176,154.623c0.246,0.252,0.208,0.691,0.618,0.893c0.445-0.434,0.247-1.324,1.08-1.531
          c0.471-0.116,0.769-0.149,0.875,0.392c0.066,0.345,0.065,0.712,0.191,1.027c0.406,1.026,0.578,1.747-0.928,0.985
          c-0.409-0.207-0.447,0.133-0.408,0.445C174.685,156.383,174.016,155.772,174.176,154.623z"/>
        <path fill="#E2403A" d="M166.024,178.443c0.729-0.149,1.304,0.359,1.944,0.628c0.322,0.139,0.397,0.475,0.357,0.813
          c-0.054,0.438-0.393,0.408-0.712,0.441c-1.077,0.11-1.843-0.57-2.687-1.052c-0.177-0.1-0.289-0.313-0.151-0.508
          C165.081,178.346,165.548,178.449,166.024,178.443z"/>
        <path fill="#E2403A" d="M86.552,184.059c-0.214,0.58-0.88,0.222-1.2,0.578c-1.059,0.139-2.066-0.287-3.114-0.264
          c-0.218-0.156-0.453-0.24-0.726-0.183c-0.319-0.014-0.641-0.026-0.961-0.039c-0.356-0.207-0.943-0.155-0.905-0.807
          c0.1-0.031,0.197-0.063,0.296-0.094c0.123,0.072,0.246,0.147,0.369,0.225c0.375,0.125,0.753,0.172,1.136,0.027
          c0.128,0.213,0.337,0.229,0.553,0.227c0.952,0.289,1.916-0.082,2.871,0.06C85.429,183.879,85.992,183.969,86.552,184.059z"/>
        <path fill="#E9605D" d="M100.971,178.418c0.048,1.736-0.808,3.076-1.888,4.317c-0.204-0.1-0.407-0.198-0.61-0.301
          c0.104-0.291,0.208-0.582,0.313-0.875c0.037-0.03,0.056-0.069,0.055-0.119c0.687-0.403,0.558-1.159,0.765-1.77
          c0.508-0.379,0.359-0.945,0.416-1.453c0.063-0.012,0.124-0.029,0.184-0.053C100.393,178.453,100.552,178.828,100.971,178.418z"/>
        <path fill="#E2403A" d="M168.889,148.682c0.078-0.135,0.153-0.27,0.231-0.401c0.938-0.64,1.985-0.925,3.107-0.974
          c0.182,0.037,0.359,0.078,0.54,0.117c0.064,0.012,0.125,0.037,0.178,0.078c0.146,0.19,0.336,0.26,0.568,0.207
          C172.041,148.352,170.515,148.756,168.889,148.682z"/>
        <path fill="#96688B" d="M266.093,154.508c1.733,0.699,3.502,1.313,5.186,2.242c-0.386,0.271-0.525,0.246-2.65-0.641
          c-0.906-0.377-1.794-0.807-2.688-1.213C265.988,154.768,266.04,154.639,266.093,154.508z"/>
        <path fill="#694E82" d="M179.267,177.03c0.293-0.015,0.586-0.023,0.878-0.039c-0.022,0.399-0.047,0.803-0.07,1.2
          c-0.405,0.002-0.634-0.664-1.137-0.303c-0.508-0.024-1.019-0.037-1.523-0.08c-0.683-0.061-0.948,0.254-0.875,0.91
          c-0.139,0.059-0.274,0.117-0.412,0.178c-0.463-0.998,0-1.455,0.949-1.627c0.537,0.25,0.641-0.278,0.91-0.506
          C178.353,177.144,178.807,177.102,179.267,177.03z"/>
        <path fill="#E2403A" d="M98.472,182.435c0.203,0.104,0.407,0.201,0.61,0.301c-0.021,0.314-0.264,0.416-0.5,0.521
          c-0.688,0.645-1.386,1.172-2.354,1.482c-1.006,0.319-1.097,0.045-1.032-0.769c0.938-0.354,2.083-0.299,2.67-1.348
          C98.069,182.562,98.271,182.498,98.472,182.435z"/>
        <path fill="#E9605D" d="M113.687,182.045c1.119-0.865,1.053-1.971,0.817-3.123c-0.064-0.324,0.104-0.547,0.447-0.568
          c0.361-0.021,0.453,0.267,0.546,0.529C116.014,180.338,115.414,181.535,113.687,182.045z"/>
        <path fill="#96688B" d="M253.333,153.008c0.007,0.184,0.065,0.459-0.266,0.516c-0.265,0.045-0.489-0.09-0.529-0.354
          c-0.173-1.174-0.321-2.351-0.475-3.524c-0.021-0.172,0.073-0.334,0.247-0.338c0.242-0.006,0.313,0.209,0.354,0.403
          C252.888,150.791,253.104,151.875,253.333,153.008z"/>
        <path fill="#96688B" d="M256.877,148.957c1.381,0.178,1.938,1.252,2.654,2.067c0.266,0.3,0.691,0.579,0.613,1.104
          c-0.029,0.194,0.006,0.438-0.206,0.521c-0.229,0.093-0.352-0.1-0.439-0.286c-0.377-0.789-1.192-1.31-1.397-2.194
          C257.951,149.531,257.122,149.672,256.877,148.957z"/>
        <path fill="#E9605D" d="M259.863,141.985c-1.883,0.669-3.805-0.135-5.695,0.238c1.088-0.758,1.815-0.848,4.736-0.67
          C259.066,142.045,259.511,141.914,259.863,141.985z"/>
        <path fill="#96688B" d="M256.625,157.735c1.128,0.186,1.438,0.943,1.938,1.457c0.416,0.423,0.807,0.871,1.208,1.309
          c0.163,0.176,0.374,0.379,0.185,0.607c-0.2,0.242-0.474,0.076-0.623-0.092C258.444,160.024,257.471,159.098,256.625,157.735z"/>
        <path fill="#96688B" d="M264.648,165.369c1.123,0.652,1.86,1.607,2.333,2.777c0.083,0.205,0.021,0.406-0.188,0.5
          c-0.215,0.102-0.404-0.014-0.486-0.207C265.853,167.369,265.165,166.42,264.648,165.369z"/>
        <path fill="#E2403A" d="M227.462,136.463c-0.377-0.623-0.187-0.92,0.868-1.352c0.22-0.113,0.369,0.049,0.539,0.135
          c0.065,0.119,0.148,0.146,0.255,0.049c0.322-0.119,0.697-0.369,0.813,0.217c0.075,0.385-0.17,0.576-0.523,0.635
          C228.762,136.256,228.111,136.358,227.462,136.463z"/>
        <path fill="#FCA7A8" d="M219.005,135.709c-0.088-0.584-0.191-1.051,0.753-0.992c0.815,0.051,1.604-0.322,2.504-0.221
          c0.898,0.104,1.178,0.398,1.165,1.193c-0.431,0.01-0.859,0.016-1.289,0.021c0.006-1.046-0.822-0.689-1.305-0.681
          c-0.563,0.015-1.441-0.433-1.561,0.685C219.184,135.715,219.095,135.711,219.005,135.709z"/>
        <path fill="#96688B" d="M251.179,159.768c0.867,0.979,1.389,2.066,1.668,3.621C251.421,162.307,251.47,160.951,251.179,159.768z"
          />
        <path fill="#E2403A" d="M215.926,137.993c0.335,0.211,0.889-0.237,1.104,0.433c-0.353,0.18-0.729,0.371-1.109,0.563
          c-0.575-0.082-1.527,0.379-1.583-0.468C214.304,138.029,215.294,137.948,215.926,137.993z"/>
        <path fill="#96688B" d="M273.246,164.344c-1.188-0.168-2.177-0.766-3.115-1.469C271.412,162.844,272.32,163.613,273.246,164.344z"
          />
        <path fill="#E9605D" d="M219.273,135.717c0.117-1.115,0.996-0.67,1.561-0.684c0.48-0.012,1.311-0.365,1.305,0.68
          C221.182,135.715,220.228,135.717,219.273,135.717z"/>
        <path fill="#A896A6" d="M176.098,161.494c-0.033,0.322,0.05,0.57,0.417,0.635c0.074,0.48,0.148,0.965,0.224,1.446
          c-0.068,0.229-0.139,0.464-0.208,0.692C175.677,163.454,175.37,162.553,176.098,161.494z"/>
        <path fill="#694E82" d="M206.788,167.889c-0.306,0.836-0.584-0.09-0.88-0.014c-0.104-0.035-0.208-0.072-0.312-0.107
          c-0.254-0.045-0.509-0.09-0.763-0.135C205.522,166.727,206.106,166.805,206.788,167.889z"/>
        <path fill="#FCA7A8" d="M224.082,135.723c0.135-0.322,0.167-0.643-0.011-0.961c0.644,0.269,1.283-0.029,1.926,0
          c-0.378,0.217-0.641,0.496-0.549,0.976C224.993,135.733,224.538,135.727,224.082,135.723z"/>
        <path fill="#E2403A" d="M226.952,138.981c-0.21-0.241-0.482-0.448-0.464-0.821c0.238-0.002,0.481-0.03,0.717,0.002
          c0.396,0.06,0.744,0.297,0.633,0.709C227.679,139.444,227.255,138.954,226.952,138.981z"/>
        <path fill="#FCA7A8" d="M81.512,184.191c0.271-0.059,0.507,0.025,0.726,0.185c-0.083,0.584-0.166,1.168-0.249,1.754
          c-0.474-0.23-0.887-0.533-1.234-0.926l0.05-0.063C81.677,185.301,81.575,184.73,81.512,184.191z"/>
        <path fill="#E2403A" d="M209.659,144.375c-0.172,0.354-0.344,0.709-0.604,1.25c-0.062-0.779-0.675-0.943-1.004-1.369
          C208.606,144.016,209.135,144.17,209.659,144.375z"/>
        <path fill="#E2403A" d="M100.971,178.418c-0.419,0.41-0.578,0.035-0.769-0.252c0.067-0.23,0.136-0.459,0.204-0.688
          c0.062-0.155,0.124-0.311,0.186-0.467c0.443,0.08,0.595,0.416,0.699,0.793C101.118,177.975,100.943,178.143,100.971,178.418z"/>
        <path fill="#F6A5AB" d="M185.193,150.637c-0.395,0.24-0.466-0.053-0.543-0.33c-0.174-0.625-0.577-1.106-0.974-1.598
          C184.959,148.741,184.942,149.793,185.193,150.637z"/>
        <path fill="#F6A5AB" d="M181.795,146.643c0.49-0.494,1.177-0.104,1.715-0.377c-0.276,0.89-0.876,1.037-1.677,0.732
          C181.82,146.879,181.807,146.762,181.795,146.643z"/>
        <path fill="#E2403A" d="M259.863,141.985c-0.353-0.069-0.797,0.063-0.959-0.432c0.683-0.08,1.386-0.315,2.021,0.229
          C260.583,142.11,260.205,141.936,259.863,141.985z"/>
        <path fill="#E9605D" d="M106.51,182.498c0.476,0.477,0.915,0.971,0.92,1.701C106.862,183.773,106.592,183.187,106.51,182.498z"/>
        <path fill="#E9605D" d="M105.486,179.184c0.632,0.5,0.495,1.213,0.52,1.881C105.63,180.494,105.5,179.853,105.486,179.184z"/>
        <path fill="#FCA7A8" d="M181.795,146.643c0.014,0.119,0.024,0.236,0.038,0.355c-0.161,0.24-0.32,0.48-0.481,0.723
          c-0.174,0.017-0.35,0.033-0.522,0.049c0,0-0.005-0.008-0.004-0.01c0.003-0.297,0.005-0.594,0.007-0.891
          C181.151,146.793,181.473,146.719,181.795,146.643z"/>
        <path fill="#E9605D" d="M105.024,177.727c0.504,0.373,0.479,0.91,0.458,1.449C105.029,178.789,105.019,178.26,105.024,177.727z"/>
        <path fill="#E2403A" d="M209.713,173.438c-0.02-0.041-0.028-0.082-0.032-0.125c0.47-0.014,0.945-0.119,1.396,0.105
          C210.624,173.545,210.174,173.807,209.713,173.438z"/>
        <path fill="#E9605D" d="M106.006,181.074c0.438,0.375,0.474,0.895,0.499,1.414C106.082,182.107,105.954,181.623,106.006,181.074z"
          />
        <path fill="#F6A5AB" d="M180.827,147.77c0.176-0.016,0.352-0.032,0.523-0.049c0.004,0.279,0.007,0.56,0.01,0.838
          C180.924,148.471,180.889,148.113,180.827,147.77z"/>
        <path fill="#F6A5AB" d="M183.264,148.473c-0.479-0.01-0.957-0.02-1.437-0.026C182.313,148.11,182.793,148.082,183.264,148.473z"/>
        <path fill="#E2403A" d="M212.639,173.582c-0.52,0.049-1.05,0.225-1.522-0.156C211.626,173.465,212.158,173.281,212.639,173.582z"
          />
        <path fill="#F6A5AB" d="M184.483,147.581c-0.033-0.045-0.065-0.09-0.101-0.135c0.025-0.02,0.072-0.055,0.077-0.051
          c0.039,0.039,0.069,0.086,0.104,0.132C184.538,147.545,184.511,147.563,184.483,147.581z"/>
        <path fill="#F6A5AB" d="M188.346,149.311c0.004,0.056,0.012,0.111,0.009,0.166c0,0.008-0.06,0.013-0.091,0.017
          c-0.005-0.056-0.014-0.11-0.011-0.166C188.255,149.319,188.313,149.315,188.346,149.311z"/>
        <path fill="#F6A5AB" d="M186.961,148.954c-0.057,0.004-0.111,0.012-0.167,0.01c-0.008-0.002-0.012-0.061-0.017-0.092
          c0.057-0.004,0.111-0.013,0.167-0.01C186.952,148.863,186.956,148.92,186.961,148.954z"/>
        <path fill="#E2403A" d="M268.475,173.163c-0.355,0.002-0.359-0.188-0.235-0.441c0.071-0.022,0.144-0.021,0.21,0.012
          C268.459,172.877,268.468,173.02,268.475,173.163z"/>
        <path fill="#FCA7A8" d="M220.504,133.813c-0.212,0.144-0.439,0.197-0.689,0.123C220.044,133.895,220.273,133.854,220.504,133.813z
          "/>
        <path fill="#E9605D" d="M172.77,147.424c-0.181-0.039-0.359-0.08-0.54-0.117C172.426,147.282,172.617,147.262,172.77,147.424z"/>
        <path fill="#F6A5AB" d="M183.51,148.668c-0.088-0.07-0.174-0.144-0.261-0.215C183.336,148.526,183.422,148.598,183.51,148.668z"/>
        <path fill="#E9605D" d="M246.639,159.477l-0.009,0.008L246.639,159.477z"/>
        <path fill="#E9605D" d="M229.124,135.295c-0.104,0.098-0.188,0.07-0.255-0.049C228.954,135.264,229.039,135.281,229.124,135.295z"
          />
        <path fill="#FCA7A8" d="M224.069,134.758c-0.02-0.049-0.045-0.096-0.056-0.146c-0.003-0.015,0.037-0.037,0.058-0.056
          C224.071,134.623,224.071,134.69,224.069,134.758z"/>
        <path fill="#96688B" d="M198.622,161.383c-0.072,0.088-0.146,0.176-0.22,0.262C198.475,161.559,198.55,161.471,198.622,161.383z"
          />
        <path fill="#E9605D" d="M149.075,153.344c0,0,0.008,0.014,0.009,0.014C149.083,153.356,149.075,153.344,149.075,153.344z"/>
        <path fill="#FDA8A9" d="M100.593,177.012c-0.062,0.156-0.124,0.312-0.186,0.467c-0.491,0.06-0.813,0.203-0.388,0.74
          c-0.057,0.508,0.092,1.074-0.417,1.453c-1.415,0.066-1.489,0.24-0.765,1.77c0,0.05-0.018,0.089-0.055,0.119
          c-0.471,0.213-1.053,0.33-0.919,1.064c-0.587,1.049-1.732,0.994-2.67,1.348c-0.084-0.008-0.167-0.016-0.251-0.022
          c-0.951-0.177-1.896-1.3-2.858-0.021c-0.486-0.01-0.972-0.021-1.458-0.029c-0.445-0.926-0.946-0.821-1.482-0.082
          c-0.833-0.02-1.666-0.039-2.499-0.061c-0.424-0.035-0.843-0.057-1.228-0.291c-0.336-0.207-0.491,0.008-0.552,0.326
          c-0.955-0.144-1.919,0.229-2.871-0.062c-0.187-0.073-0.369-0.147-0.553-0.227c-0.383,0.145-0.762,0.098-1.137-0.027
          c-0.123-0.074-0.245-0.147-0.368-0.225c-0.1,0.031-0.197,0.063-0.298,0.094c-0.505-0.807-1.333-1.037-2.186-1.199
          c-0.289-0.055-0.728,0.119-0.698-0.385c0.084-1.492-1.169-1.426-2.041-1.725c-0.544-0.187-1.004-0.273-1.492-0.73
          c-0.561-0.524-1.006-1.486-2.061-1.33l0.019,0.008c-0.093-0.084-0.187-0.168-0.278-0.252l0.009,0.039
          c-0.269-1.188-1.099-1.815-2.188-2.188c-0.061-0.08-0.123-0.162-0.184-0.244c0.246-1.167-0.314-1.995-1.458-2.159l0.006,0.004
          c0.016-0.865,0.654-2.084-1.102-1.947c-0.192-0.184-0.313-0.402-0.33-0.674c-0.05-0.086-0.1-0.174-0.146-0.26
          c-0.033-0.074-0.064-0.148-0.1-0.221c-0.073-0.178-0.146-0.352-0.218-0.523l-0.002-0.006c-0.043-0.84,0.058-1.476,1.118-0.82
          c0.123,0.076,0.298,0.068,0.446,0.102c0.008,0.066-0.021,0.104-0.09,0.114c-0.093,0.563,0.194,0.935,0.672,1.134
          c0.515,0.213,0.562-0.271,0.719-0.568c-0.021-0.144-0.045-0.287-0.065-0.432c0.342,0.139,0.686-0.688,0.982-0.044
          c0.194,0.419,0.231,1.116,0.787,0.946c0.471-0.143,0.176-0.844,0.22-1.295c0.029-0.313-0.017-0.633-0.027-0.948
          c-0.216-0.729,0.264-0.83,0.799-0.882l-0.028,0.033c0.101,0.105,0.198,0.211,0.298,0.316c0.389,0.241,0.351,0.666,0.441,1.034
          c0.01,0.364-0.146,0.821,0.336,0.979c0.382,0.125,0.531-0.176,0.583-0.512c-0.068-0.271-0.138-0.537-0.207-0.809
          c0.387-0.008,0.772-0.016,1.158-0.021c0.199-0.018,0.397-0.031,0.597-0.047c0.222-0.016,0.574,0.111,0.604-0.173
          c0.032-0.347-0.335-0.198-0.542-0.235c-0.066-0.133-0.134-0.27-0.199-0.4c0.019-0.448,0.115-0.936-0.519-1.064
          c-0.066-0.07-0.135-0.141-0.2-0.209c0.143-0.104,0.285-0.203,0.429-0.305c0.192,0.01,0.385,0.02,0.577,0.025
          c0.079,0.16,0.157,0.32,0.236,0.479c0.037,0.272,0.192,0.437,0.463,0.485l0.011,0.01c0.439,0.813,1.021,1.316,2.013,0.906
          c0.254-0.104,0.403-0.297,0.299-0.561c-0.105-0.271-0.3-0.104-0.485-0.048c-0.729,0.216-0.529-0.19-0.343-0.55
          c0.127-0.243,0.289-0.469,0.438-0.702c0.491-0.86-0.431-0.692-0.719-0.987c0.068-0.425,0.14-0.852,0.209-1.275
          c0.075-0.436,0.401-0.684,0.71-0.946c0.197,0.403,0.365,0.786,0.977,0.604c0.711-0.213,0.751-0.74,0.765-1.316
          c0.165,0.146,0.33,0.294,0.493,0.439c-0.026,0.229,0.01,0.496-0.095,0.684c-0.485,0.865,0.015,0.895,0.66,0.822
          c0.564,1.334,1.032,2.699,1.304,4.125c-0.659,0.529-0.374,0.791,0.27,0.955c0.155,0.559,0.313,1.117,0.469,1.676
          c0.077,0.08,0.154,0.16,0.231,0.242c0.142,1.094,0.038,2.264,0.967,3.115c0.044,0.254-0.107,0.682,0.21,0.709
          c0.4,0.035,0.198-0.408,0.271-0.643c0.466-0.053,0.945-0.07,1.225-0.543c0.322-0.09,0.44-0.238,0.153-0.512
          c0.021-0.147,0.038-0.297,0.06-0.447c0.086-0.09,0.172-0.18,0.258-0.27c0.354-0.037,0.653,0.108,0.942,0.289
          c-0.074,0.401-0.285,0.819,0.365,0.998c0.416,0.114,0.707,0.448,0.834,0.887c-0.635,0.101-1.269,0.201-1.903,0.301
          c-0.072-0.23-0.158-0.469-0.475-0.364c-0.535,0.177-0.496,0.63-0.485,1.063l0.004-0.008c-0.772,0.201-1.448,0.02-2.089-0.449
          c-0.191-0.141-0.469-0.625-0.735-0.123c-0.16,0.301-0.139,0.701,0.222,0.947c0.207,0.141,0.656,0.162,0.478,0.524
          c-0.358,0.724,0.182,1.074,0.51,1.529c0.007,0.037,0.027,0.078,0.018,0.109c-0.021,0.063-0.061,0.123-0.092,0.186
          c0.113,0.121,0.227,0.244,0.339,0.365l0.109,0.018l0.034,0.103c0.102,0.121,0.204,0.242,0.307,0.365
          c1.209,0.491,2.48,0.672,3.771,0.758c0.445,0.412,0.959,0.705,1.554,0.844c0.654,0.154,1.209,0.115,1.317-0.727
          c0.193-0.218,0.44-0.222,0.7-0.172c0.576,0.176,0.902-0.354,1.369-0.484c1.969-0.176,2.088-0.301,1.726-1.793
          c0.018-0.109,0.034-0.219,0.052-0.326c0.132-0.092,0.263-0.184,0.395-0.275c1.168-0.264,1.061-1.155,1.006-2.025
          c0.191-0.518,0.384-1.033,0.575-1.55c0.355-0.097,0.415-0.375,0.429-0.687c0.012-0.256,0.021-0.512,0.031-0.77
          c0.064-0.085,0.129-0.169,0.192-0.255c-0.014-0.142-0.026-0.282-0.039-0.425c0.209-0.174,0.211-0.35,0.003-0.525
          c-0.056-0.32-0.112-0.643-0.168-0.961c-0.022-0.391-0.046-0.781-0.069-1.171c-0.052-0.095-0.104-0.188-0.155-0.278l0.009-0.002
          c-0.107-1.086-0.846-0.771-1.487-0.67c-0.007-0.156,0.008-0.318-0.023-0.471c-0.078-0.368-0.054-0.801-0.455-1.004
          c-0.065-0.032-0.258,0.121-0.355,0.227c-0.349,0.362-0.383,0.737-0.034,1.139c0.098,0.111,0.125,0.283,0.186,0.426
          c-1.113,1.127-2.228,2.252-3.34,3.377c-0.079-0.656-1.156-0.557-1.042-1.363c0.644-0.878,0.069-1.498-0.449-2.13
          c-0.019-0.659-0.492-1.127-0.625-1.704c-0.414,0.025-0.314,1.059-0.674,0.465c-0.524-0.867-1.193-0.572-1.851-0.646
          c-0.35-0.129-0.56-0.395-0.703-0.725c0.102-0.188,0.456-0.342,0.229-0.576c-0.268-0.271-0.668-0.115-1.014-0.119
          c-0.065-0.084-0.133-0.168-0.197-0.252c0.2-0.207,0.34-0.463,0.146-0.711c-0.221-0.283-0.481-0.039-0.722,0.041
          c-0.93-1.121-1.858-2.244-2.787-3.365c0.528-1.034,1.666-0.371,2.495-0.854c0.65-0.379,0.411,1.188,1.155,1.602
          c0.204-0.418,0.512-0.758,1.341-0.789c-1.498-0.691-1.063-1.586-0.792-2.446c0.16,0.13,0.321,0.259,0.483,0.386
          c0.695,0.405,1.788-0.789,2.267,0.555c0,0,0.3-0.078,0.297-0.104c-0.069-0.748,0.688-0.865,1.016-1.303
          c0.558-0.736-0.281-1.358-0.031-2.028c0.276,0.022,0.555,0.045,0.831,0.067c0.15,0.936,0.671,1.188,1.562,0.92
          c0.274-0.081,0.615,0.061,0.926,0.098c0.038,0.135,0.046,0.297,0.238,0.309c0.022,0.002,0.07-0.047,0.069-0.069
          c-0.013-0.19-0.172-0.198-0.309-0.237c-0.343-0.963-1.259-1.082-2.046-1.39c-0.125-0.081-0.249-0.161-0.373-0.241
          c0.014-0.283,0.024-0.564,0.038-0.848c0.153,0.025,0.309,0.055,0.462,0.08c0.537,0.675,1.021,0.789,1.404-0.135
          c-0.068-0.121-0.137-0.244-0.205-0.365c-0.286-1.336-1.171-0.26-1.746-0.437c-0.188,0.104-0.365,0.153-0.481-0.094
          c0.234-0.839,1.083-1.177,1.51-1.854c0.916,0.65,1.689,1.549,2.915,1.658l-0.028-0.01c0.074,0.09,0.148,0.182,0.223,0.271
          l0.007,0.008c0.028,0.68-0.196,1.531,0.979,1.422l-0.012-0.008c0.068,0.09,0.14,0.179,0.208,0.268l0.019,0.036
          c0.032,0.05,0.066,0.099,0.104,0.146c0.057,0.082,0.112,0.164,0.169,0.246c-0.402,1.143,0.213,1.664,1.193,1.965
          c0.264,0.396,0.597,0.766,0.493,1.299c0.04,0.506-0.272,1.17,0.587,1.308c0.325,0.241,0.452,0.604,0.586,0.966
          c0.063,0.084,0.126,0.17,0.189,0.254c0.019,0.121,0.039,0.243,0.058,0.366c0.065,0.116,0.131,0.233,0.196,0.353
          c0.049,0.049,0.067,0.104,0.058,0.174c0.062,0.185,0.12,0.365,0.18,0.549c0.097,0.313,0.191,0.619,0.289,0.932
          c0.069,0.174,0.142,0.346,0.211,0.518c0.383,0.539,0.521,1.15,0.526,1.799c-0.025,0.391,0.005,0.742,0.479,0.852
          c0.024,0.143,0.05,0.284,0.074,0.426c-0.294,0.229-0.273,0.41,0.088,0.531c0.099,0.398,0.194,0.801,0.293,1.2
          c-0.015,1.524-0.027,3.052-0.042,4.578c-0.059,0.188-0.115,0.375-0.174,0.563c0.005,0.276,0.01,0.557,0.016,0.834
          c-0.22,0.19-0.196,0.371,0.021,0.541C100.967,175.443,100.392,176.16,100.593,177.012z M95.86,176.91
          c-0.185,0.899-0.412,1.463,0.389,2.071C96.207,178.258,96.225,177.74,95.86,176.91z M94.277,155.983
          c-0.154-0.266-0.259-0.659-0.514-0.821c-0.236-0.151-0.36,0.22-0.378,0.435c-0.022,0.334,0.139,0.607,0.484,0.697
          C94.071,156.344,94.253,156.285,94.277,155.983z M89.74,182.406c0.015-0.261-0.15-0.326-0.355-0.343
          c-0.19-0.012-0.404-0.01-0.479,0.203c-0.084,0.234,0.097,0.355,0.285,0.404C89.43,182.734,89.638,182.662,89.74,182.406z
           M82.242,178.285c0.012-0.189-0.104-0.302-0.265-0.271c-0.127,0.025-0.281,0.121-0.342,0.23c-0.08,0.147,0.012,0.305,0.189,0.332
          C82.043,178.609,82.177,178.486,82.242,178.285z M72.821,168.707c0.126,0.221,0.157,0.355,0.239,0.396
          c0.134,0.066,0.216-0.051,0.234-0.176c0.024-0.156,0.016-0.33-0.186-0.346C73.012,168.576,72.911,168.666,72.821,168.707z
           M69.199,171.5c0.197,0.03,0.261-0.08,0.261-0.195c0-0.104-0.038-0.27-0.106-0.301c-0.2-0.09-0.269,0.078-0.265,0.233
          C69.091,171.336,69.167,171.432,69.199,171.5z M96.043,164.948c-0.22,0.128-0.356,0.157-0.397,0.239
          c-0.065,0.134,0.053,0.215,0.177,0.234c0.155,0.021,0.33,0.016,0.347-0.188C96.175,165.141,96.085,165.038,96.043,164.948z
           M97.557,180.709c0.104-0.125,0.208-0.199,0.218-0.285c0.012-0.1-0.034-0.27-0.104-0.301c-0.201-0.088-0.268,0.078-0.264,0.235
          C97.411,180.457,97.488,180.551,97.557,180.709z M97.217,175.894c-0.063-0.053-0.138-0.16-0.234-0.188
          c-0.134-0.039-0.239,0.063-0.197,0.197c0.028,0.094,0.134,0.198,0.228,0.229C97.142,176.174,97.225,176.082,97.217,175.894z
           M78.483,166.756c0.017-0.107,0.049-0.207,0.036-0.301c-0.005-0.033-0.117-0.052-0.182-0.076
          c-0.026,0.096-0.066,0.188-0.073,0.287C78.262,166.688,78.382,166.715,78.483,166.756z M99.627,165.543
          c-0.063,0.03-0.158,0.047-0.18,0.096c-0.063,0.142,0.026,0.207,0.157,0.188c0.056-0.012,0.124-0.082,0.14-0.141
          C99.754,165.654,99.671,165.594,99.627,165.543z M90.969,168.608c-0.042-0.034-0.085-0.07-0.128-0.107
          c-0.019,0.025-0.058,0.068-0.054,0.075c0.037,0.041,0.082,0.075,0.124,0.11C90.93,168.661,90.95,168.635,90.969,168.608z
           M81.852,172.907c-0.018-0.026-0.034-0.054-0.053-0.079c-0.045,0.031-0.092,0.063-0.131,0.104
          c-0.005,0.006,0.032,0.051,0.049,0.078C81.762,172.975,81.806,172.942,81.852,172.907z M81.831,177.303
          c0.007-0.031,0.014-0.063,0.021-0.094c-0.057-0.008-0.11-0.021-0.167-0.021c-0.007-0.002-0.015,0.06-0.021,0.091
          C81.719,177.287,81.777,177.295,81.831,177.303z"/>
        <path fill="#FCA7A8" d="M78.654,161.575c-0.015,0.575-0.054,1.104-0.766,1.315c-0.609,0.183-0.776-0.201-0.977-0.604
          c-0.085-1.132-1.1-0.699-1.68-0.996c0.052-0.984-0.039-1.938-0.688-2.941c-0.426,1.14-0.174,2.015,0.293,2.865
          c-0.228-0.002-0.457-0.002-0.685-0.004c-0.044-0.109-0.063-0.244-0.137-0.322c-0.461-0.477-0.262-1.791-0.939-1.551
          c-0.62,0.219,0.137,1.227,0.225,1.896c-0.297,0.003-0.593,0.005-0.89,0.007c-0.467-0.552-0.459-1.438-1.288-1.81
          c-0.346,0.762,0.52,1.234,0.394,1.898c-0.221,0.271-0.524,0.332-0.853,0.334c-0.262-0.523-0.517-1.054-0.789-1.57
          c-0.127-0.24-0.36-0.326-0.603-0.201c-0.218,0.115-0.165,0.345-0.089,0.525c0.229,0.545,0.476,1.082,0.716,1.621
          c-0.211,0.123-0.421,0.246-0.63,0.366c-0.359-0.409-0.716-0.827-1.083-1.231c-0.186-0.203-0.255-0.711-0.671-0.414
          c-0.353,0.25-0.004,0.563,0.092,0.84c0.143,0.42,0.718,0.676,0.466,1.246c-0.729-0.024-0.985-0.981-1.854-0.983
          c0.234,0.892,0.896,1.306,1.406,1.832c-0.296,0.461-0.847,0.562-1.244,0.877c-0.17,0.138-0.6-0.03-0.536,0.179
          c0.311,1.012-0.533,1.063-1.062,1.325c-0.448,0.226-0.589,0.487-0.4,0.94c-0.06,0.056-0.12,0.109-0.18,0.164
          c-0.623-1.252-0.563-2.641-0.764-3.971c-0.639-4.197-1.699-8.281-3.114-12.277c-0.13-0.369-0.233-0.746-0.424-1.359
          c1.535,0.584,2.542,1.615,3.727,2.348c0.202,0.125,0.362,0.313,0.547,0.467c0.345,0.285,0.725,0.623,1.166,0.281
          c0.404-0.309,0.104-0.713,0.008-1.094c-0.5-2.021-1.318-3.932-2.021-5.881c-0.073-0.201-0.263-0.419-0.021-0.773
          c0.927,1.012,1.805,2.027,2.746,2.979c0.53,0.535,1.078,1.591,1.887,1.093c0.801-0.494-0.083-1.343-0.241-2.031
          c-0.319-1.401-0.988-2.678-1.564-3.985c0.084-0.078,0.167-0.152,0.251-0.23c1.032,0.92,2.063,1.842,3.098,2.762
          c0.507,0.451,0.926,1.428,1.713,0.9c0.701-0.471,0.193-1.344-0.051-1.982c-0.559-1.467-1.192-2.902-1.727-4.395
          c1.267,1.004,2.376,2.137,3.405,3.35c0.287,0.338,0.571,0.803,1.065,0.502c0.495-0.299,0.192-0.775,0.052-1.182
          c-0.466-1.334-1.094-2.621-1.311-4.139c1.274,0.395,2.095,1.248,3.062,1.863c2.813,1.787,5.969,2.852,8.95,4.278
          c3.691,1.771,6.978,4.146,9.384,7.56c-1.227-0.107-1.999-1.008-2.915-1.658c-0.357-1.002-1.33-0.543-1.984-0.838
          c-0.261-0.406-0.172-1.285-0.708-1.193c-0.699,0.115-0.024,0.85-0.189,1.286c-0.024,0.067,0,0.153,0.004,0.233
          c-0.084,0.026-0.169,0.055-0.253,0.082c-0.098-0.125-0.185-0.259-0.291-0.371c-0.382-0.408-0.239-1.521-0.798-1.324
          c-0.724,0.262,0.066,1.102-0.01,1.691c-0.107,0.159-0.219,0.321-0.328,0.481c-0.063-0.101-0.129-0.197-0.188-0.3
          c-0.286-0.485-0.497-1.383-1.065-1.192c-0.786,0.263,0.188,0.897,0.05,1.394c-0.011,0.032-0.052,0.061-0.134,0.147
          c-0.217-0.263-0.383-0.604-0.654-0.761c-0.771-0.442-0.513,0.22-0.54,0.558c-0.074,0.07-0.149,0.14-0.227,0.209
          c-0.335,0.009-0.587-0.614-0.926-0.209c-0.236,0.283,0.167,0.558,0.276,0.839c0.027,0.071,0.031,0.151,0.048,0.229
          c-0.428,0.359-0.864-0.485-1.366,0.072c0.161,0.152,0.337,0.289,0.475,0.457c0.193,0.234,0.523,0.51,0.26,0.812
          c-0.255,0.289-0.466-0.101-0.695-0.211c-0.292-0.146-0.508-0.511-0.926-0.361c0.091,0.712,0.764,1.021,1.012,1.604
          c-0.168,0.346-0.337,0.688-0.506,1.031c-0.265-0.084-0.522-0.222-0.791-0.244c-0.513-0.045-1.096-0.984-1.364-0.354
          c-0.229,0.537,0.757,0.803,1.318,1.033c0.143,0.061,0.311,0.061,0.466,0.088c0.041,0.538-1.03,0.846-0.458,1.372
          c0.537,0.494,1.258,0.194,1.809-0.259c0.334-0.271,0.725-0.254,1.112-0.268c-0.097,0.512-0.114,1.021,0.108,1.504
          c0.1,0.215,0.218,0.465,0.506,0.4c0.319-0.07,0.213-0.355,0.223-0.58c0.021-0.492-0.19-0.918-0.344-1.361
          c0.047-0.098,0.094-0.195,0.141-0.293c0.406,0.377,0.773,0.787,1.029,1.285c-0.271,0.86-0.707,1.754,0.791,2.446
          c-0.829,0.03-1.136,0.371-1.34,0.789c-0.746-0.414-0.505-1.979-1.156-1.603c-0.829,0.484-1.966-0.18-2.495,0.854
          c-0.436-0.405-0.809-0.862-1.183-1.327c-0.473-0.589-1.175-0.968-1.879-0.455c-0.687,0.494-0.631,1.192-0.176,1.899
          C78.108,160.426,78.362,161.012,78.654,161.575z"/>
        <path fill="#E9605D" d="M58.957,188.994c-0.594,0.53-0.711,1.317-1.021,1.989c-1.349,2.914-3.318,5.283-5.95,7.111
          c-0.064,0.043-0.16,0.053-0.211,0.107c-0.22,0.229-0.052,1.051-0.635,0.637c-0.352-0.25,0.182-0.777,0.45-1.057
          c0.411-0.423,0.967-0.697,1.398-1.104c2.743-2.576,4.325-5.816,5.301-9.396C58.837,187.728,58.972,188.332,58.957,188.994z"/>
        <path fill="#FCA7A8" d="M71.167,177.977c1.054-0.155,1.5,0.806,2.062,1.33c0.487,0.457,0.947,0.545,1.491,0.73
          c0.872,0.299,2.125,0.231,2.041,1.725c-0.026,0.504,0.409,0.33,0.698,0.385c0.854,0.162,1.681,0.396,2.187,1.199
          c-0.038,0.65,0.549,0.601,0.905,0.808c0.005,0.252,0.011,0.508,0.017,0.76c-0.034,0.028-0.067,0.061-0.102,0.092
          c-0.998-0.646-2.007-1.184-2.929-2.043c-0.905-0.85-2.289-1.418-3.395-2.234C73.048,179.914,72.052,179.008,71.167,177.977z"/>
        <path fill="#E2403A" d="M100.818,166.387c-0.025-0.141-0.05-0.283-0.075-0.426c-0.021-0.361-0.206-0.629-0.479-0.852
          c-0.006-0.646-0.145-1.26-0.525-1.799c0-0.151-0.001-0.307-0.001-0.461c0.03-0.024,0.063-0.053,0.097-0.078
          c1.065,2.057,2.021,4.156,2.744,6.357c-0.416,0.012-0.599,0.254-0.633,0.641l-0.07,0.072c-0.728-0.379-0.215-1.242-0.677-1.726
          c-0.1-0.399-0.195-0.802-0.293-1.2C100.876,166.741,100.846,166.565,100.818,166.387z"/>
        <path fill="#FCA7A8" d="M66.76,168.832c-0.148-0.031-0.324-0.023-0.446-0.102c-1.063-0.652-1.162-0.02-1.118,0.82
          c-0.486-0.725-0.85-1.502-0.986-2.373c0.061-0.055,0.12-0.109,0.179-0.164c0.688,0.646,1.608,0.154,2.361,0.482
          C66.752,167.944,66.757,168.387,66.76,168.832z"/>
        <path fill="#E2403A" d="M98.804,160.791c-0.019-0.123-0.039-0.246-0.058-0.367c-0.063-0.084-0.126-0.17-0.188-0.254
          c-0.135-0.359-0.262-0.724-0.587-0.965c-0.109-0.475-0.153-0.979-0.586-1.309c0.104-0.532-0.229-0.9-0.493-1.299
          c0.012-0.084,0.021-0.168,0.032-0.252c1.188,1.48,1.926,3.178,2.41,4.998l-0.083,0.07c-0.059-0.043-0.122-0.076-0.19-0.102
          c0.011-0.066-0.011-0.125-0.06-0.172C98.935,161.026,98.871,160.91,98.804,160.791z"/>
        <path fill="#E9605D" d="M55.55,156.149c-0.166-0.092-0.332-0.184-0.499-0.273c-0.325-0.145-0.577-0.355-0.665-0.72l0.062-0.046
          l-0.052,0.056c-0.521-0.248-0.439,0.358-0.696,0.485c-0.104-1.245-0.021-1.303,1.453-0.979
          C55.495,155.106,55.591,155.608,55.55,156.149z"/>
        <path fill="#FCA7A8" d="M67.086,173.18c1.146,0.164,1.704,0.992,1.458,2.158C67.961,174.684,67.414,174.004,67.086,173.18z"/>
        <path fill="#FCA7A8" d="M68.728,175.582c1.09,0.371,1.92,0.998,2.188,2.188C69.964,177.264,69.313,176.455,68.728,175.582z"/>
        <path fill="#FCA7A8" d="M65.991,171.238c1.755-0.138,1.115,1.081,1.102,1.946C66.538,172.639,66.203,171.973,65.991,171.238z"/>
        <path fill="#FCA7A8" d="M96.924,156.348c-0.011,0.084-0.021,0.168-0.033,0.252c-0.979-0.301-1.597-0.821-1.192-1.965
          C96.106,155.206,96.514,155.776,96.924,156.348z"/>
        <path fill="#FCA7A8" d="M95.208,153.95c-1.175,0.108-0.95-0.744-0.979-1.422C94.662,152.928,94.99,153.401,95.208,153.95z"/>
        <path fill="#E2403A" d="M99.528,162.795c-0.098-0.313-0.192-0.619-0.289-0.931c0.004-0.149,0.009-0.3,0.013-0.448l0.083-0.07
          c0.436,0.383,0.383,0.935,0.5,1.428c-0.033,0.025-0.064,0.055-0.097,0.078C99.67,162.823,99.6,162.803,99.528,162.795z"/>
        <path fill="#E2403A" d="M103.072,170.535c-0.083-0.229-0.166-0.455-0.249-0.684C103.079,170.016,103.101,170.266,103.072,170.535z
          "/>
        <path fill="#FCA7A8" d="M65.197,169.559c0.072,0.174,0.146,0.349,0.218,0.523C65.232,169.954,65.196,169.764,65.197,169.559z"/>
        <path fill="#FCA7A8" d="M70.907,177.732c0.094,0.084,0.187,0.168,0.279,0.252C71.093,177.9,70.999,177.816,70.907,177.732z"/>
        <path fill="#FCA7A8" d="M80.753,185.201c-0.095-0.066-0.189-0.134-0.286-0.199c0.033-0.031,0.066-0.062,0.102-0.092
          c0.078,0.075,0.155,0.153,0.234,0.229L80.753,185.201z"/>
        <path fill="#FCA7A8" d="M95.406,154.207c-0.069-0.088-0.141-0.176-0.208-0.267C95.265,154.032,95.336,154.12,95.406,154.207z"/>
        <path fill="#FCA7A8" d="M95.529,154.389c-0.038-0.047-0.073-0.096-0.105-0.145C95.498,154.264,95.534,154.313,95.529,154.389z"/>
        <path fill="#FCA7A8" d="M65.513,170.303c0.05,0.086,0.1,0.174,0.146,0.26C65.613,170.477,65.563,170.389,65.513,170.303z"/>
        <path fill="#FCA7A8" d="M94.222,152.52c-0.073-0.09-0.146-0.182-0.223-0.271C94.075,152.338,94.148,152.43,94.222,152.52z"/>
        <path fill="#694E82" d="M206.678,174.127c0.365-0.011,0.739-0.043,0.901,0.403c-0.32,0.25-0.644,0.498-0.965,0.747
          c-0.264,0.163-0.527,0.329-0.792,0.493c-1.697,1.263-1.787,1.49-0.969,2.482c-0.004,0.051,0.001,0.1,0.017,0.146
          c-0.271,0.146-0.597-0.176-0.828,0.091c-0.022,0.024,0.213,0.278,0.328,0.43c0.01,0.08,0.02,0.16,0.026,0.242
          c0.007,0.395,0.014,0.787,0.02,1.184c-0.266,0.949-0.991,1.316-1.873,1.307c-0.733-0.008-0.847,0.363-0.88,0.941
          c-0.114,1.941-0.27,2.092-2.134,1.91c-0.424-0.041-0.42,0.191-0.535,0.443c-0.601,1.299-0.764,1.348-2.049,0.696
          c-0.344-0.646-0.943-0.434-1.479-0.438c-0.736-0.439-1.136,0.168-1.539,0.561c-0.679,0.66,0.5,0.795,0.428,1.308
          c-0.325,0.356-0.65,0.135-0.979,0.004c-0.906-0.306-1.613,0.217-1.923,1.416c-0.043,0.475-0.405,0.479-0.751,0.514
          c-0.27-0.025-0.479,0.102-0.682,0.256c-0.379-0.035-0.813-0.022-0.513-0.621c0.81-1.162,2.208-1.744,2.938-2.985
          c0.75-0.336,0.318-1.166,0.643-1.701c0.277,0.219,0.193,0.479,0.244,0.707c0.07,0.225,0.296,0.395,0.435,0.225
          c1.299-1.604,3.468-2.385,4.245-4.479l-0.017-0.004c0.532,0.473,0.742-0.064,1.039-0.334c0.768-0.688,1.314-1.621,2.268-2.099
          c1.002-0.502,2.408-1.483,2.167-2.875c0.221-0.002,0.439-0.004,0.659-0.006c0.146,0.17,0.343,0.504,0.541,0.238
          C205.189,174.639,206.092,174.657,206.678,174.127z M200.827,182.674c-0.067-0.73,0.087-1.854-0.826-1.928
          c-0.581-0.047-1.179,0.946-1.589,1.623c-0.14,0.229,1.547,1.729,1.866,1.643C200.986,183.823,200.729,183.191,200.827,182.674z
           M202.583,181.084c0.99-0.025,1.067-0.947,1.295-1.504c0.167-0.404-0.57-1.135-0.979-1.051c-0.645,0.131-1.372,0.69-1.502,1.334
          C201.264,180.514,201.958,180.91,202.583,181.084z M198.105,184.531c-0.014-0.81-0.403-1.625-0.771-1.646
          c-0.218-0.014-1.393,1.178-1.275,1.271c0.51,0.407,1.023,0.883,1.621,1.086C198.144,185.396,198.065,184.816,198.105,184.531z"/>
        <path fill="#96688B" d="M195.469,185.205c0.533,0.008,1.135-0.207,1.479,0.44c-0.271,0.242-0.281,0.568-0.249,0.886
          c0.096,0.907-0.339,1.375-1.211,1.385c-0.674,0.008-0.881,0.313-0.943,0.942c-0.178,1.75-0.651,1.951-2.272,0.968
          c-0.144,0.549,0.007,1.125-0.274,1.633c-0.375,0.678-0.779,1.219-1.684,0.955c-1.096-0.318-1.37,0.172-1.35,1.221
          c0.023,1.316-0.489,1.748-1.699,1.324c-1.14-0.396-1.423,0.106-1.587,1.016c-0.313,0.092-0.738,0.412-0.579-0.342
          c0.103-0.486,0.211-1.006-0.373-1.314c0.47-0.254,0.938-0.51,1.407-0.764c0.428,0.502,0.96,1.035,1.609,0.787
          c0.649-0.246,0.229-0.996,0.319-1.518c0.027-0.156,0.028-0.318,0.041-0.478c0.521-0.115,0.417-0.531,0.432-0.893
          c0.468,0.067,0.936,0.135,1.399,0.209c1.066,0.174,1.619-0.433,1.309-1.484c-0.119-0.408-0.354-0.783-0.536-1.174
          c0.346-0.033,0.708-0.039,0.75-0.516c0.542,0.063,0.935,0.396,1.332,0.725c0.54,0.441,0.81,0.213,0.796-0.385
          c-0.014-0.588-0.133-1.172-0.204-1.756c0.327,0.133,0.652,0.354,0.979-0.005c1.468,0.242,1.692-0.038,1.217-1.526
          C195.538,185.43,195.504,185.318,195.469,185.205z"/>
        <path fill="#E2403A" d="M253.384,173.139c0.128-0.002,0.428-0.045,0.267,0.146c-0.412,0.484-0.133,0.77,0.185,1.086
          c-0.154,0.141-0.312,0.279-0.467,0.42c-1.748,0.82-3.679,0.824-5.529,1.125c-1.051,0.172-2.021,0.766-3.127,0.602
          c-0.347-0.114-0.724-0.313-0.741-0.662c-0.022-0.414,0.496-0.237,0.729-0.414c0.153-0.114,0.317-0.221,0.478-0.327
          c2.508-0.85,5.15-0.963,7.729-1.432C253.25,173.666,253.155,173.26,253.384,173.139z"/>
        <path fill="#E2403A" d="M230.549,179.934c2.63-0.449,4.871-1.979,7.418-2.664c0.911-0.244,1.813-0.528,2.717-0.797
          c0.634,0.189,1.609-0.506,1.844,0.717c-0.234,0-0.471,0.002-0.705,0.004c-0.314-0.217-0.416-0.014-0.484,0.25
          c-1.809,0.111-3.434,0.779-5.074,1.505c-1.469,0.646-2.921,1.305-4.306,2.11C231.475,180.698,230.276,181.236,230.549,179.934z"/>
        <path fill="#E2403A" d="M225.281,182.734c0.25-0.155,0.5-0.313,0.75-0.473c0.769,0.125,1.695-0.131,2.076,0.894
          c-1.639,0.698-3.121,1.661-4.559,2.7c-0.415-0.051-0.788-0.162-0.895-0.645c1.866-1.918,1.877-1.93-0.174-3.771
          c-0.363-0.325-0.594-0.729-0.906-1.082c0.129-0.528,0.398-0.391,0.646-0.11c0.396,0.438,0.812,0.854,1.376,1.082l-0.016-0.017
          C224.101,181.844,224.144,182.941,225.281,182.734z"/>
        <path fill="#E9605D" d="M228.107,183.155c-0.381-1.021-1.311-0.769-2.076-0.894c1.321-1.133,2.941-1.688,4.519-2.328
          c-0.271,1.303,0.928,0.765,1.407,1.125C230.77,181.935,229.355,182.391,228.107,183.155z"/>
        <path fill="#FCA7A8" d="M223.598,181.326c-0.564-0.228-0.979-0.642-1.376-1.082c-0.248-0.277-0.52-0.418-0.646,0.112
          c-1.371-0.862-2.452-2.08-3.729-3.059c0.777-0.07,0.666-0.377,0.203-0.773c-0.011-0.145-0.021-0.289-0.03-0.434
          C219.988,177.723,222.306,178.978,223.598,181.326z"/>
        <path fill="#E9605D" d="M222.656,185.211c0.104,0.481,0.479,0.594,0.895,0.645c-1.3,0.484-2.519,1.063-3.479,2.142
          c-0.621,0.694-1.434,1.215-2.478,1.27C219.151,187.75,220.904,186.482,222.656,185.211z"/>
        <path fill="#E9605D" d="M242.526,177.189c-0.232-1.223-1.21-0.526-1.844-0.717c1.342-0.969,2.932-1.115,4.491-1.358
          c-0.159,0.106-0.323,0.211-0.479,0.327c-0.232,0.175-0.752,0-0.729,0.414c0.02,0.349,0.396,0.547,0.741,0.662
          C244.102,177.133,243.246,176.934,242.526,177.189z"/>
        <path fill="#96688B" d="M238.326,182.121c0.229-0.818,0.693-0.688,1.156-0.732c1.089-0.104,2.313,0.424,3.227-0.633
          c0.137-0.156,0.382-0.125,0.484,0.107c0.108,0.241,0.03,0.487-0.146,0.653c-0.347,0.318-0.808,0.289-1.235,0.314
          C240.69,181.894,239.557,181.531,238.326,182.121z"/>
        <path fill="#96688B" d="M204.871,178.402c-0.016-0.049-0.021-0.098-0.018-0.146c0.521-0.148,1.225,0.256,1.508-0.598
          c0.264-0.783,0.133-1.396-0.538-1.888c0.265-0.164,0.528-0.33,0.792-0.493c0.588,0.78,0.764,1.661,0.629,2.616
          C206.6,178.75,205.82,178.973,204.871,178.402z"/>
        <path fill="#96688B" d="M267.614,183.81c1.459,0.351,2.979,0.533,4.239,1.435c-0.04,0.114-0.079,0.231-0.119,0.352
          c-1.403-0.481-2.809-0.963-4.213-1.443C267.553,184.037,267.583,183.924,267.614,183.81z"/>
        <path fill="#96688B" d="M258.161,180.781c1.36-0.369,2.187-1.945,4.115-1.81C260.812,179.918,259.648,180.719,258.161,180.781z"/>
        <path fill="#96688B" d="M234.486,193.221c1.3-0.34,2.393-0.123,3.27-0.797c0.159-0.123,0.395-0.07,0.5,0.143
          c0.112,0.229-0.049,0.367-0.211,0.486C237.009,193.812,235.911,193.603,234.486,193.221z"/>
        <path fill="#96688B" d="M253.534,185.869c1.441-0.451,2.523,0.303,3.68,0.813c-0.026,0.099-0.056,0.193-0.081,0.291
          C255.931,186.605,254.732,186.236,253.534,185.869z"/>
        <path fill="#96688B" d="M245.454,186.281c-1.116,0.18-2.21,0.428-3.293-0.002C243.269,186.297,244.37,185.441,245.454,186.281z"/>
        <path fill="#96688B" d="M276.111,177.935c-1.131,0.545-2.293,0.972-3.587,0.697C273.746,178.519,274.813,177.699,276.111,177.935z
          "/>
        <path fill="#96688B" d="M230.469,190.248c0.207-1.14,1.209-1.703,2.618-1.558C232.499,189.809,231.071,189.295,230.469,190.248z"
          />
        <path fill="#96688B" d="M277.928,184.318c1.096-0.163,2.101-0.829,3.277-0.549C280.158,184.198,279.122,184.693,277.928,184.318z"
          />
        <path fill="#96688B" d="M245.723,190.91c0.652,0.352,1.108,0.668,1.613,0.844c0.345,0.121,0.474,0.268,0.357,0.572
          c-0.107,0.291-0.347,0.213-0.591,0.141C246.396,192.258,246.025,191.754,245.723,190.91z"/>
        <path fill="#A896A6" d="M250.295,180.199c0.371-1.511,0.402-1.55,1.429-1.843C251.561,179.166,251.085,179.682,250.295,180.199z"
          />
        <path fill="#96688B" d="M285.094,178.768c0.775-0.182,1.525-0.586,2.671-0.375C286.717,179.019,285.911,178.946,285.094,178.768z"
          />
        <path fill="#F6A5AB" d="M204.871,178.402c0.948,0.57,1.729,0.351,2.373-0.508c0.11,0.775-0.254,1.066-1,1.031
          c-0.623-0.029-1.249-0.006-1.873-0.006c-0.116-0.148-0.353-0.402-0.328-0.428C204.275,178.227,204.598,178.547,204.871,178.402z"
          />
        <path fill="#E2403A" d="M268.409,173.709c0.021-0.186,0.04-0.369,0.063-0.554c0.372,0.062,0.478,0.312,0.454,0.647
          C268.732,173.879,268.556,173.87,268.409,173.709z"/>
        <path fill="#F6A5AB" d="M204.417,180.346c-0.007-0.395-0.013-0.789-0.02-1.184C204.705,179.551,204.706,179.946,204.417,180.346z"
          />
        <path fill="#E9605D" d="M241.336,177.443c0.068-0.264,0.169-0.467,0.484-0.25C241.706,177.369,241.55,177.459,241.336,177.443z"/>
        <polygon fill="#FCA7A8" points="217.227,177.027 217.226,176.959 217.23,177.025 		"/>
        <path fill="#FCA7A8" d="M216.89,177.074c0.076-0.021,0.151-0.021,0.228,0.008C217.042,177.078,216.967,177.076,216.89,177.074z"/>
        <path fill="#DC271E" d="M54.385,155.156c0.088,0.362,0.34,0.575,0.665,0.72c0.035,0.938,0.306,1.9-0.356,2.754
          c-0.18,0.229-0.35,0.457-0.671,0.396c-0.311-0.057-0.26-0.355-0.325-0.57c-0.287-0.936-0.215-1.867,0.001-2.805
          c0.257-0.127,0.177-0.733,0.696-0.485L54.385,155.156z"/>
        <path fill="#E9605D" d="M56.587,163.529c-0.544,0.657-1.231,0.53-1.879,0.519c-0.188-0.004-0.416-0.099-0.412-0.342
          c0.004-0.261,0.24-0.313,0.431-0.281C55.322,163.518,55.951,163.17,56.587,163.529z"/>
        <path fill="#E9605D" d="M54.786,165.381c0.075-0.185,0.081-0.24,0.102-0.246c0.577-0.16,1.141-0.537,1.755-0.152
          c0.05,0.031,0.05,0.281,0.001,0.313C56.064,165.647,55.461,165.514,54.786,165.381z"/>
        <g id="facebook" className="clickcable" style={{cursor: 'pointer'}} onClick={openFacebook}>
          <path fill="#38B3FC" d="M327.758,210.906c-0.518,4.563-0.252,9.172-0.641,13.723c-0.24,2.826-0.178,5.64-0.355,8.453
            c-0.078,1.209-0.443,1.428-1.652,1.154c-1.076-0.24-2.184-0.299-3.276-0.513c-3.368-0.651-6.803-0.977-10.173-1.627
            c-2.596-0.5-5.204-0.918-7.796-1.422c-1.554-0.305-2.385-0.67-1.723-2.844c0.124-0.41,0.131-0.865,0.137-1.299
            c0.038-2.64-0.113-5.291,0.129-7.91c0.398-4.295,0.435-8.59,0.424-12.891c-0.001-0.67,0.315-0.974,0.946-0.935
            c2.697,0.166,5.431-0.27,8.097,0.457c0.113,0.031,0.238,0.035,0.356,0.029c3.954-0.197,7.855,0.688,11.808,0.563
            c1.078-0.032,2.07,0.529,3.171,0.457c0.559-0.035,0.542,0.488,0.547,0.886C327.764,208.43,327.758,209.668,327.758,210.906z
             M309.021,220.949c0,0.479,0,0.963,0,1.438c0.001,0.48,0.002,0.959,0.002,1.439c-0.002,1.803-0.001,1.775,1.856,1.861
            c0.719,0.034,1.045-0.187,1.027-0.921c-0.024-1.276-0.026-2.557-0.032-3.836c-0.005-1.27,0.246-1.491,1.511-1.196
            c1.018,0.237,2.036,0.377,3.081,0.412c0.579,0.02,0.781-0.255,0.836-0.763c0.063-0.596-0.358-0.612-0.765-0.67
            c-1.025-0.145-2.054-0.285-3.077-0.44c-0.63-0.095-1.208-0.293-1.189-1.078c0.028-1.316-0.012-2.646,0.368-3.926
            c0.41-1.388,1.171-1.818,2.529-1.427c1.117,0.322,2.222,0.722,2.974,1.679c0.477,0.604,1.148,0.526,1.632,0.313
            c0.58-0.258,0.127-0.824,0-1.252c-0.331-1.113-0.99-2.066-1.919-2.682c-1.355-0.9-2.866-1.785-4.55-0.922
            c-1.569,0.805-2.673,2.11-3.272,3.813C309.098,215.442,309.027,218.19,309.021,220.949z"/>
          <path fill="#FEFEFE" d="M309.021,220.949c0.006-2.76,0.077-5.508,1.012-8.154c0.602-1.701,1.703-3.008,3.272-3.813
            c1.684-0.861,3.192,0.021,4.55,0.922c0.929,0.615,1.588,1.567,1.919,2.684c0.127,0.428,0.58,0.994,0,1.252
            c-0.481,0.215-1.156,0.293-1.632-0.313c-0.752-0.957-1.854-1.354-2.974-1.679c-1.358-0.393-2.119,0.039-2.529,1.427
            c-0.38,1.278-0.34,2.608-0.368,3.926c-0.019,0.785,0.562,0.983,1.189,1.078c1.023,0.155,2.052,0.301,3.077,0.44
            c0.404,0.058,0.827,0.074,0.765,0.67c-0.055,0.509-0.257,0.781-0.836,0.763c-1.045-0.035-2.063-0.175-3.081-0.412
            c-1.265-0.295-1.516-0.07-1.511,1.197c0.006,1.278,0.008,2.561,0.032,3.836c0.018,0.733-0.311,0.954-1.027,0.92
            c-1.857-0.086-1.858-0.06-1.856-1.859c0-0.479-0.001-0.959-0.002-1.438C309.021,221.91,309.021,221.43,309.021,220.949z"/>
        </g>
        <path fill="#A896A6" d="M246.332,96.723c-0.053,2.214-0.457,4.338-1.566,6.291c-0.896,1.577-2.14,2.172-3.987,1.887
          c-1.347-0.208-2.555-1.367-2.835-2.864c-0.594-3.181,0.146-6.039,2.154-8.584c0.513-0.65,0.936-1.372,1.394-2.064
          c0.678-1.024,1.938-1.195,2.86-0.34C246.009,92.583,246.255,94.627,246.332,96.723z"/>
        <path fill="#A896A6" d="M76.387,103.618c-2.324,0.185-4.271-2.007-4.907-4.556c-0.65-2.604-0.322-5.097,1.287-7.383
          c1.99-2.827,5.361-2.951,7.469-0.19c2.782,3.646,1.737,9.634-2.012,11.739C77.62,103.568,77.013,103.561,76.387,103.618z"/>
        <g id="mail" className="clickcable" style={{cursor: 'pointer'}} onClick={openEmail}>
          <path fill="#FDA8A9" d="M254.333,217.541c0.023,4.236-0.105,8.467-0.254,12.699c-0.037,1.07-0.341,1.789-1.552,1.807
            c-0.235,0.002-0.475,0.113-0.709,0.105c-3.682-0.146-7.319,0.521-10.996,0.574c-2.548,0.036-5.08,0.485-7.644,0.471
            c-1.071-0.006-1.445-0.305-1.471-1.424c-0.126-5.826-0.581-11.646-0.479-17.479c0.019-1.033-0.102-2.072-0.202-3.104
            c-0.105-1.068,0.505-1.504,1.412-1.486c2.286,0.041,4.533-0.352,6.796-0.555c3.015-0.271,6.042-0.379,9.043-0.841
            c1.692-0.258,3.43-0.036,5.136-0.317c0.878-0.146,0.925,0.5,0.923,1.156C254.327,211.946,254.333,214.744,254.333,217.541z
             M232.227,212.694c0.11,0.623-0.259,1.422,0.291,1.744c1.109,0.649,1.741,1.735,2.661,2.54c2.85,2.488,5.834,4.837,8.184,7.843
            c0.604,0.772,0.936,0.387,1.313-0.183c1.756-2.616,3.459-5.266,4.853-8.104c0.985-2.008,2.063-3.973,2.688-6.135
            c0.11-0.384,0.438-0.871-0.186-1.121c-0.993-0.398-3.183,0.446-3.627,1.457c-1.432,3.244-3.128,6.371-4.314,9.725
            c-0.151,0.432-0.229,1-0.796,1.109c-0.647,0.123-0.893-0.447-1.166-0.863c-2.146-3.24-5.046-5.852-7.414-8.904
            c-0.524-0.676-1.151-1.207-2.032-0.867C231.782,211.285,232.462,212.149,232.227,212.694z"/>
          <path fill="#FEFEFE" d="M232.227,212.694c0.233-0.545-0.445-1.408,0.454-1.759c0.881-0.34,1.508,0.191,2.032,0.867
            c2.368,3.054,5.269,5.664,7.414,8.904c0.273,0.416,0.519,0.986,1.166,0.863c0.564-0.109,0.645-0.679,0.796-1.109
            c1.188-3.354,2.885-6.479,4.314-9.725c0.444-1.011,2.634-1.855,3.627-1.457c0.624,0.25,0.297,0.737,0.187,1.121
            c-0.625,2.162-1.703,4.127-2.688,6.135c-1.394,2.838-3.097,5.486-4.853,8.104c-0.38,0.566-0.71,0.955-1.313,0.183
            c-2.35-3.006-5.334-5.354-8.184-7.842c-0.92-0.806-1.552-1.892-2.661-2.541C231.969,214.115,232.339,213.317,232.227,212.694z"/>
        </g>
        <path fill="#A896A6" d="M260.475,98.022c-0.021-1.988,0.451-3.858,1.337-5.631c0.381-0.763,0.604-1.807,1.705-1.719
          c1.165,0.092,1.915,0.987,2.325,1.972c0.937,2.252,0.187,4.36-0.886,6.355c-0.368,0.686-0.908,1.303-1.466,1.857
          c-0.433,0.433-0.977,0.884-1.684,0.518c-0.728-0.377-1.359-0.845-1.336-1.796C260.486,99.059,260.475,98.54,260.475,98.022z"/>
        <path fill="#FDA8A9" d="M180.075,178.191c0.023-0.397,0.048-0.801,0.07-1.2c0.54-1.187,0.119-2.442,0.286-3.663
          c0.325-2.378,0.155-4.762-0.1-7.137c-0.037-0.346-0.11-0.717-0.402-0.977c-0.035-0.492,0.086-1.004-0.182-1.469
          c-0.111-2.287-1.028-4.396-1.482-6.605c-0.415-2.02-0.883-4.028-1.326-6.043c0.276-0.012,0.554-0.022,0.829-0.035v-0.014
          c0.057,0.428,0.356,0.508,0.717,0.502c0.168,0.158,0.336,0.314,0.504,0.473c-0.01,0.062-0.018,0.121-0.021,0.183
          c0.111,0.099,0.225,0.194,0.337,0.295c0.046-0.021,0.087-0.014,0.124,0.021c0.051,0.275,0.216,0.438,0.49,0.482
          c0.003,0.146,0.004,0.297,0.006,0.444c-0.009,0.371-0.057,0.754,0.349,0.978c0.047,0.065,0.095,0.133,0.142,0.198
          c0.012,0.58-0.401,1.24,0.323,1.683l-0.053-0.012c0.077,0.094,0.155,0.188,0.231,0.281c0,0,0.007-0.014,0.006-0.014
          c-0.055,0.287-0.023,0.55,0.215,0.754l-0.02-0.018c0.08,0.09,0.162,0.182,0.243,0.271c0.021,0.082,0.011,0.157-0.029,0.231
          c-0.017,0.072-0.03,0.146-0.047,0.217c0.201,1.17,0.572,2.287,1.048,3.371c-0.063,0.508,0.07,0.976,0.286,1.426
          c0.128,0.875,0.251,1.753,0.182,2.643c0.057,0.65-0.197,1.342,0.243,1.944c0.022,0.161,0.048,0.323,0.07,0.485
          c-0.363,0.291-0.322,0.703-0.321,1.099c0.009,3.497,0.02,6.998,0.028,10.496c-0.02,0.293-0.037,0.584-0.058,0.878
          c-0.617,0.227-0.408,0.764-0.451,1.206c-0.422,3.402-0.923,6.787-1.976,10.066c-0.396-0.055-0.406-0.52-0.712-0.668l0.005-0.002
          c-0.09-0.656,0.547-1.299,0.03-1.961l0.002,0.004c0.194-0.629,0.3-1.271,0.243-1.93c-0.066-2.224-0.198-4.438,0.215-6.648
          C180.259,179.711,180.099,178.939,180.075,178.191z M180.055,157.551c0.043,0.035,0.085,0.072,0.128,0.109
          c0.021-0.027,0.059-0.07,0.054-0.076c-0.036-0.041-0.081-0.074-0.124-0.111C180.094,157.498,180.074,157.524,180.055,157.551z"/>
        <path fill="#F6A5AB" d="M176.539,178.719c-0.074-0.656,0.192-0.971,0.875-0.91c0.507,0.043,1.018,0.056,1.523,0.08
          c0.773,1.351,0.72,1.994-0.238,2.81c-0.382-0.05-0.764-0.101-1.146-0.146C177.311,179.885,176.99,179.266,176.539,178.719z"/>
        <path fill="#F6A5AB" d="M178.972,186.584c-0.415,0.436,0.479,1.631-0.932,1.611c-1.321-0.019-0.969-1.121-1.187-1.816
          c-0.193-0.621,0.306-0.953,0.794-0.877C178.198,185.586,178.997,185.576,178.972,186.584z"/>
        <path fill="#F6A5AB" d="M178.951,181.266c0.008,0.875,0.021,1.75,0.021,2.627c0,0.314,0.046,0.689-0.349,0.804
          c-0.425,0.122-0.73-0.164-0.841-0.505c-0.307-0.959-0.695-1.926-0.248-2.952C178.004,181.398,178.475,181.446,178.951,181.266z"/>
        <path fill="#F6A5AB" d="M179.663,189.002c0.517,0.662-0.12,1.305-0.03,1.961c-0.234,0.002-0.555-0.096-0.688,0.021
          c-0.353,0.313-0.607,0.416-1.01,0.063c-0.473-0.416-0.991-0.957-0.839-1.494c0.259-0.912,1.15-0.279,1.721-0.313
          C179.159,189.217,179.446,189.389,179.663,189.002z"/>
        <path fill="#694E82" d="M178.951,181.266c-0.476,0.181-0.946,0.133-1.417-0.026c-0.009-0.229-0.262-0.47,0.021-0.688
          c0.382,0.049,0.764,0.1,1.146,0.146C178.784,180.887,178.868,181.076,178.951,181.266z"/>
        <path fill="#F6A5AB" d="M179.909,187.076c0.056,0.659-0.049,1.301-0.243,1.93C178.908,188.258,179.946,187.734,179.909,187.076z"
          />
        <path fill="#A896A6" d="M188.461,128.047c-0.253-0.32-0.265-0.553-0.057-0.806C188.699,127.489,188.642,127.73,188.461,128.047z"
          />
        <path fill="#D8D2D8" d="M313.764,77.774c-0.216-0.518-0.237-0.906,0.163-1.166c0.188-0.12,0.452,0.055,0.478,0.225
          C314.475,77.312,314.051,77.454,313.764,77.774z"/>
        <path fill="#D8D2D8" d="M60.285,66.912c0.562-0.38,0.796-0.623,1.079-0.713c0.648-0.208,1.229,0.202,1.596,0.575
          c0.49,0.5-0.006,0.543-0.409,0.43c-0.226-0.064-0.427-0.253-0.65-0.278C61.486,66.879,61.063,66.912,60.285,66.912z"/>
        <path fill="#A896A6" d="M43.868,66.162c0.506,0,0.698-0.004,0.891,0c0.531,0.013,0.845,0.379,1.164,0.729
          c0.1,0.108,0.063,0.239-0.056,0.294c-0.54,0.257-0.736-0.313-1.104-0.479C44.552,66.615,44.367,66.469,43.868,66.162z"/>
        <path fill="#D8D2D8" d="M170.9,129.016c0.503-0.576,1.156-0.869,1.153-1.617c-0.001-0.153,0.178-0.275,0.356-0.197
          c0.094,0.042,0.187,0.15,0.22,0.25C172.854,128.102,171.815,129.161,170.9,129.016z"/>
        <path fill="#D8D2D8" d="M247.93,57.619c0.969-0.368,1.02-0.354,1.699,0.433C248.971,58.106,248.532,57.507,247.93,57.619z"/>
        <path fill="#A896A6" d="M249.629,56.187c0.082,0.084,0.146,0.179,0.004,0.253c-0.023,0.014-0.104-0.079-0.156-0.123
          C249.526,56.273,249.577,56.229,249.629,56.187z"/>
        <path fill="#A896A6" d="M249.258,56.287c0.09-0.098,0.181-0.193,0.271-0.29c0.04,0.06,0.073,0.124,0.101,0.189
          c-0.052,0.042-0.103,0.085-0.151,0.129C249.402,56.31,249.329,56.301,249.258,56.287z"/>
        <path fill="#A896A6" d="M311.927,128.073c-0.326-0.031-0.635-0.09-0.7-0.491c0.219-0.099,0.438-0.194,0.656-0.292
          c0.277-0.151,0.402-0.413,0.495-0.697c0.621,0.068,0.529,0.466,0.32,0.816C312.526,127.697,312.297,127.983,311.927,128.073z"/>
        <path fill="#D8D2D8" d="M312.378,126.592c-0.093,0.284-0.218,0.546-0.495,0.697C311.34,126.555,311.893,126.597,312.378,126.592z"
          />
        <path fill="#D8D2D8" d="M95.943,66.303c-0.225,0.013-0.401-0.078-0.419-0.303c-0.023-0.316,0.146-0.529,0.474-0.54
          c0.229-0.007,0.344,0.162,0.353,0.379C96.358,66.111,96.232,66.283,95.943,66.303z"/>
        <path fill="#A896A6" d="M97.785,64.253c0.009,0.221,0.019,0.442,0.026,0.664c-0.075,0.014-0.152,0.021-0.229,0.023
          c-0.271-0.168-0.33-0.454-0.405-0.73C97.379,64.224,97.581,64.24,97.785,64.253z"/>
        <polygon fill="#D8D2D8" points="94.708,65.882 94.761,65.931 94.714,65.876 		"/>
        <path fill="#D8D2D8" d="M153.476,129.446c-0.379-0.216-0.955,0.219-1.092-0.386c-0.063-0.282,0.234-0.605,0.475-0.604
          C153.476,128.466,153.061,129.192,153.476,129.446z"/>
        <path fill="#D8D2D8" d="M145.552,64.696c-0.431-0.023-0.812,0.082-0.706-0.69c0.075-0.55-0.068-1.254-0.463-1.789
          c0.398-0.739,0.985-0.804,1.685-0.469c-0.506,0.259-0.904,0.531-0.502,1.205c0.171,0.285,0.183,0.664,0.267,1
          C145.74,64.2,145.646,64.448,145.552,64.696z"/>
        <path fill="#A896A6" d="M146.068,61.747c-0.698-0.335-1.285-0.271-1.685,0.469c-0.733,0.618-0.588,1.425-0.478,2.224l0.017-0.013
          c-0.588-0.694-0.904-1.452-0.335-2.289c0.467-0.686,2.028-1.091,2.667-0.707C146.415,61.528,146.213,61.683,146.068,61.747z"/>
        <path fill="#A896A6" d="M145.552,64.696c0.094-0.248,0.188-0.496,0.279-0.743c0.588-0.091,0.483-0.836,0.969-1.026
          c0.475,0.76-0.443,0.983-0.581,1.508c-0.154,0.146-0.142,0.324-0.097,0.509l-0.042,0.023
          C145.906,64.876,145.729,64.785,145.552,64.696z"/>
        <path fill="#A896A6" d="M143.907,64.44c0.057,0.093,0.112,0.186,0.17,0.276c-0.035,0.015-0.07,0.028-0.105,0.043
          c-0.016-0.111-0.031-0.222-0.048-0.333L143.907,64.44z"/>
        <path fill="#F6A5AB" d="M180.711,152.93c1.497,0.523,2.917,1.256,4.16,2.195c1.003,0.756,0.959,2.188,1.078,3.311
          c0.36,3.391,1.003,6.756,0.967,10.184c-0.019,0.188-0.03,0.37,0.203,0.439c-0.213,0.814-0.279,1.645-0.249,2.482
          c0.056,1.502,0.391,3.061,0.099,4.492c-0.745,3.649-1.116,7.373-2.081,10.979c-0.069,1.174-1.113,2-1.135,3.189
          c-0.757,1.813-1.515,3.625-2.271,5.438c-0.28,0.025-0.563,0.051-0.847,0.074l-0.041,0.016c0.115-2.51,0.995-4.869,1.448-7.313
          c0.605-3.256,1.269-6.504,1.903-9.754c0.003-0.783,0.006-1.568,0.008-2.354c-0.027-4.419,0.319-8.854-0.535-13.242
          c-0.177-0.797-0.318-1.602-0.687-2.342c-0.388-1.442-0.774-2.889-1.164-4.332c-0.01-0.276-0.097-0.521-0.294-0.727
          c-0.247-0.861-0.495-1.724-0.743-2.582C180.594,153.034,180.652,152.981,180.711,152.93z"/>
        <path fill="#F6A5AB" d="M182.823,179.482c-0.011-3.498-0.021-6.998-0.028-10.496c-0.001-0.395-0.042-0.807,0.32-1.098
          c-0.016,0.869-0.042,1.738-0.042,2.605c-0.001,2.004-0.031,4.01,0.165,6.009c-0.277,0.623-0.241,1.272-0.163,1.928
          C183.029,178.791,183.065,179.17,182.823,179.482z"/>
        <path fill="#694E82" d="M183.075,178.432c-0.078-0.653-0.114-1.305,0.163-1.928c0.239-0.064,0.479-0.131,0.718-0.195
          c-0.002,0.783-0.004,1.568-0.008,2.354C183.622,178.719,183.34,178.609,183.075,178.432z"/>
        <path fill="#F6A5AB" d="M182.314,181.566c0.043-0.44-0.166-0.979,0.451-1.207C182.717,180.799,182.628,181.225,182.314,181.566z"
          />
        <path fill="#A896A6" d="M274.474,130.217c-0.688-0.348-1.406-0.393-2.147-0.227C272.872,129.156,273.866,129.26,274.474,130.217z"
          />
        <path fill="#A896A6" d="M274.702,130.397c-0.081-0.067-0.163-0.137-0.245-0.205C274.538,130.26,274.621,130.329,274.702,130.397z"
          />
        <path fill="#D8D2D8" d="M97.059,131.875c-1.255,0.061-1.304-0.869-1.459-1.607c-0.159-0.749,0.755-0.528,1.011-0.939
          c0.523,0.596,0.937,1.258,1.066,2.049C97.779,131.985,97.262,131.805,97.059,131.875z"/>
        <path fill="#A896A6" d="M311.786,94.812c-0.617-0.948-0.673-1.873,0.125-2.758c-0.021,0.372-0.044,0.744-0.063,1.111
          c0.521,0.058,0.47-0.553,0.886-0.587c0.033,0.475-0.239,0.959,0.128,1.37c0.005,0.083,0.011,0.167,0.016,0.25
          C312.815,94.941,312.345,94.956,311.786,94.812z"/>
        <path fill="#A896A6" d="M314.62,92.781c-0.142,0.353-0.395,0.524-0.778,0.483c-0.342-0.456-0.206-0.893,0.241-1.078
          C314.531,92.001,314.464,92.551,314.62,92.781z"/>
        <path fill="#F0EBF0" d="M312.874,94.198c-0.005-0.083-0.011-0.167-0.016-0.25c0.024,0.056,0.059,0.11,0.071,0.169
          C312.935,94.138,312.894,94.17,312.874,94.198z"/>
        <path fill="#A896A6" d="M313.313,112.446c-0.922,0.922-1.304,0.927-1.851,0.022c0.578-0.976,1.258-1.168,1.865-0.529
          c-0.168,0.079-0.204,0.18-0.05,0.31C313.299,112.312,313.308,112.378,313.313,112.446z"/>
        <path fill="#D8D2D8" d="M313.28,112.247c-0.154-0.13-0.118-0.229,0.05-0.31C313.312,112.042,313.297,112.145,313.28,112.247z"/>
        <path fill="#A896A6" d="M295.426,131.549c-0.277-0.043-0.273-0.266-0.251-0.451c0.037-0.324,0.149-0.647,0.494-0.746
          c0.243-0.069,0.349,0.119,0.322,0.359C295.954,131.09,295.819,131.407,295.426,131.549z"/>
        <path fill="#D8D2D8" d="M316.067,59.19c0.032,0.003,0.08-0.004,0.092,0.014c0.021,0.026,0.028,0.078,0.017,0.106
          c-0.051,0.112-0.104,0.106-0.136-0.014C316.031,59.265,316.056,59.226,316.067,59.19z"/>
        <path fill="#A896A6" d="M180.636,195.713c0.282-0.023,0.564-0.049,0.847-0.074c0.036,0.094,0.071,0.189,0.106,0.283
          c-0.514,0.127-0.525,0.795-1.098,0.955C180.22,196.459,180.567,196.094,180.636,195.713z"/>
        <path fill="#F6A5AB" d="M189.277,158.031c0.017,0.077,0.048,0.146,0.1,0.207c0.153,1.454,0.55,2.896,0.393,4.368
          c-0.091,0.852,0.161,1.473,0.831,1.955c-1.015,1.697-0.732,3.455-0.239,5.222c-0.496,0.563-0.482,1.312-0.432,1.935
          c0.22,2.672-0.146,5.297-0.36,7.946c-0.287,3.492-0.981,6.901-1.69,10.315c-0.164,0.334-0.327,0.666-0.491,1
          c-0.277,0.688-0.912,0.992-1.471,1.381l-0.003,0.005c-0.083,0.073-0.165,0.147-0.248,0.227h-0.002
          c-0.106,0.057-0.218,0.113-0.324,0.17c-0.696,0.49-1.313,1.063-1.854,1.726c-0.436-0.88,0.08-1.617,0.399-2.357
          c0.234-0.549,0.537-1.064,0.61-1.668c0.476-0.979,0.75-2.004,0.737-3.1c0.497-0.046,0.578-0.396,0.698-0.813
          c1.002-3.459,1.353-7.033,1.83-10.578c0.327-2.437,0.173-4.917,0.015-7.375c-0.001-0.389-0.001-0.777-0.002-1.166
          c-0.464-2.328-0.166-4.725-0.653-7.053c-0.271-1.309-0.464-2.631-0.713-4.064C187.651,156.497,188.347,157.471,189.277,158.031z"
          />
        <path fill="#694E82" d="M183.482,194.482c0.54-0.659,1.157-1.231,1.854-1.725c0.107-0.057,0.218-0.113,0.324-0.17h0.002
          c0.083-0.074,0.165-0.15,0.248-0.226l0.003-0.004c0.345,0.009,0.688,0.019,1.029,0.027c-0.063,0.533-0.601,0.736-0.813,1.168
          c-0.469,0.254-0.938,0.51-1.406,0.764c-0.426,0.281-0.624,0.834-1.169,0.97C183.212,195.047,183.366,194.764,183.482,194.482z"/>
        <path fill="#694E82" d="M185.231,187.357c0.014,1.099-0.264,2.121-0.736,3.103c-0.271-0.021-0.536-0.047-0.741-0.256
          c0.021-1.189,1.063-2.019,1.135-3.189C185.001,187.127,185.116,187.244,185.231,187.357z"/>
        <path fill="#F0EBF0" d="M146.123,64.943c-0.045-0.185-0.06-0.362,0.097-0.509c0.169,0.04,0.488,0.103,0.486,0.117
          C146.659,64.88,146.347,64.851,146.123,64.943z"/>
        <path fill="#FCA7A8" d="M225.281,182.734c-1.137,0.207-1.18-0.891-1.696-1.422C224.315,181.588,224.804,182.155,225.281,182.734z"
          />
        <path fill="#F6A5AB" d="M180.83,146.87c-0.002,0.297-0.004,0.594-0.007,0.891c-1.043-0.336-1.916-0.078-2.659,0.721
          c-0.251,0.271-0.279,0.453,0.063,0.634c0.135,0.481,0.19,0.967,0.007,1.448c-0.078,0.021-0.155,0.029-0.234,0.029
          c-0.177-0.15-0.354-0.299-0.527-0.449c0.27-0.785-0.005-1.44-0.49-2.047C178.139,147.297,179.51,147.163,180.83,146.87z"/>
        <path fill="#F6A5AB" d="M176.316,151.061c-0.021,0.347-0.001,0.701-0.081,1.031c-0.069,0.291,0.517,0.799-0.265,0.867
          c-0.59,0.053-0.893-0.18-0.824-0.805c0.013-0.113-0.01-0.232-0.017-0.352C175.462,151.456,175.781,151.086,176.316,151.061z"/>
        <path fill="#96688B" d="M177.471,150.143c0.176,0.15,0.353,0.299,0.526,0.449c0.005,0.139,0.011,0.275,0.016,0.414
          c-0.082,0.014-0.163,0.029-0.244,0.043v0.014c-0.278-0.256-0.573-0.475-0.979-0.465l0.002,0.004
          C176.862,150.215,177.11,150.096,177.471,150.143z"/>
        <path fill="#F6A5AB" d="M190.758,156.377c-0.03,0.235-0.068,0.471-0.089,0.709c-0.021,0.27,0.111,0.469,0.354,0.563
          c0.297,0.115,0.404-0.133,0.55-0.323c0.388-0.507,0.584-1.429,1.441-1.127c0.859,0.304,0.594,1.19,0.583,1.886
          c-0.011,0.551,0.337,1.369-0.086,1.586c-0.564,0.289-1.104-0.396-1.61-0.75c-0.648-0.453-1.313-0.886-1.973-1.326
          c-0.12-0.156-0.222-0.346-0.473-0.289c-0.143-0.065-0.282-0.131-0.427-0.197c-0.137-0.813-1.004-0.823-1.473-1.334
          c0.754-0.416,0.839-1.264,1.257-1.896c0.243-0.371,0.442-0.811,0.966-0.73c0.53,0.078,0.638,0.545,0.697,0.988
          C190.58,154.879,190.664,155.627,190.758,156.377z"/>
        <path fill="#F6A5AB" d="M178.345,149.082c0.281-0.703,0.874-0.986,1.532-0.813c0.634,0.17,0.414,0.863,0.498,1.346
          c0.032,0.188-0.06,0.395-0.055,0.59c0.01,0.289,0.093,0.602,0.402,0.666c0.407,0.084,0.37-0.285,0.447-0.543
          c0.368-1.213,0.828-1.479,1.94-1.131c0.11,0.033,0.237,0.053,0.322,0.119c0.32,0.262,0.745,3.453,0.536,3.85
          c-0.117,0.223-0.291,0.285-0.484,0.18c-0.442-0.246-1.028-0.24-1.325-0.744c0.281-0.106,0.646,0.025,0.884-0.309
          c-0.194-0.354-0.738-0.39-0.749-0.947c-0.017-0.877-0.44-0.506-0.789-0.18c-0.229,0.217-0.442,0.453-0.664,0.682
          c-0.3-0.022-0.601-0.049-0.898-0.074l-0.005,0.004c-0.086-0.075-0.172-0.153-0.258-0.229c-0.321-0.25-0.643-0.5-0.964-0.75
          C179.314,150.067,179.456,149.438,178.345,149.082z"/>
        <path fill="#F6A5AB" d="M185.427,151.559c0.516-0.315,0.956-0.43,1.145,0.354c0.237,0.987,0.511,1.972,0.741,2.961
          c0.074,0.313-0.009,0.548-0.438,0.453c-1.679-0.369-2.161-1.138-1.763-2.83C185.219,152.184,185.323,151.872,185.427,151.559z"/>
        <path fill="#FCA7A8" d="M180.844,151.846c0.222-0.229,0.435-0.465,0.665-0.682c0.348-0.326,0.771-0.697,0.788,0.18
          c0.011,0.559,0.555,0.596,0.749,0.947c-0.237,0.334-0.603,0.201-0.884,0.309C181.664,152.452,181.206,152.231,180.844,151.846z"/>
        <path fill="#694E82" d="M178.719,150.795c0.32,0.25,0.642,0.5,0.963,0.75c0.086,0.076,0.172,0.154,0.258,0.229l0.005-0.005
          c-0.157,0.693-0.614,0.25-0.955,0.252c-0.168-0.156-0.336-0.313-0.504-0.473c-0.156-0.183-0.314-0.363-0.473-0.545
          c-0.005-0.14-0.011-0.275-0.016-0.414c0.08,0,0.157-0.011,0.235-0.027C178.395,150.641,178.557,150.719,178.719,150.795z"/>
        <path fill="#FCA7A8" d="M178.719,150.795c-0.161-0.076-0.323-0.154-0.484-0.23c0.186-0.483,0.128-0.969-0.007-1.45
          c0.036-0.022,0.075-0.033,0.118-0.031C179.456,149.438,179.314,150.067,178.719,150.795z"/>
        <path fill="#694E82" d="M187.772,167.426c0.001,0.389,0.001,0.777,0.002,1.166c-0.219,0.156-0.438,0.311-0.654,0.467
          c-0.233-0.069-0.222-0.254-0.203-0.439C187.396,168.363,186.969,167.45,187.772,167.426z"/>
        <path fill="#694E82" d="M181.276,155.666c0.197,0.203,0.284,0.448,0.294,0.727c0.007,0.308-0.029,0.584-0.398,0.666
          c-0.082-0.166-0.164-0.33-0.246-0.496l-0.007,0.013c-0.01-0.149-0.068-0.259-0.231-0.28c0,0,0.053,0.012,0.052,0.012
          c-0.015-0.135-0.027-0.27-0.042-0.404C180.889,155.823,181.083,155.745,181.276,155.666z"/>
        <path fill="#694E82" d="M185.427,151.559c-0.104,0.313-0.208,0.625-0.313,0.938c-0.321-0.017-0.849,0.112-0.771-0.377
          C184.417,151.637,184.786,151.202,185.427,151.559z"/>
        <path fill="#F6A5AB" d="M180.695,155.901c0.015,0.137,0.027,0.271,0.042,0.404c-0.726-0.44-0.313-1.102-0.322-1.682
          C180.731,154.998,180.648,155.463,180.695,155.901z"/>
        <path fill="#F6A5AB" d="M180.273,154.424c-0.403-0.223-0.355-0.605-0.349-0.977C180.041,153.774,180.157,154.098,180.273,154.424z
          "/>
        <path fill="#F6A5AB" d="M179.92,153.002c-0.274-0.047-0.439-0.207-0.49-0.482C179.615,152.657,180.002,152.592,179.92,153.002z"/>
        <path fill="#F6A5AB" d="M180.533,153.084c-0.094-0.115-0.193-0.232-0.025-0.355c0.013-0.008,0.134,0.131,0.204,0.201
          C180.652,152.981,180.594,153.034,180.533,153.084z"/>
        <path fill="#F6A5AB" d="M179.305,152.5c-0.112-0.101-0.226-0.197-0.337-0.295C179.08,152.303,179.192,152.401,179.305,152.5z"/>
        <path fill="#FDA8A9" d="M192.45,185.655c-0.729,1.241-2.129,1.823-2.938,2.985c-0.262-0.076-0.39-0.201-0.326-0.516
          c0.354-1.783,0.688-3.572,1.026-5.359c0.49-0.779,0.182-1.637,0.238-2.459c0.213-2.754,0.563-5.498,0.479-8.27
          c-0.021-0.699-0.369-1.402-0.026-2.105c0.006-0.233,0.011-0.469,0.015-0.703c-0.088-0.646-0.315-1.293,0.016-1.938
          c-0.088-0.781,0.301-1.629-0.326-2.336c-0.104-0.125-0.137-0.255-0.026-0.395c0.028-0.168,0.06-0.334,0.089-0.502
          c0.352-0.459,0.476-0.889-0.159-1.221c-0.086-1.396-0.173-2.789-0.26-4.188c0.706,0.521,1.384,1.088,2.13,1.543
          c0.396,0.24,0.555,0.518,0.574,0.934c0.138,2.801,0.272,5.602,0.41,8.4c0.006,0.115,0.014,0.232,0.02,0.35
          c0,0.104,0.001,0.211,0.001,0.314c-0.001,0.295-0.001,0.593-0.002,0.889c-0.003,0.195-0.006,0.393-0.011,0.588
          c0.005,2.282,0.009,4.567,0.012,6.851c-0.047,0.748-0.115,1.496-0.137,2.246C193.202,182.432,192.345,183.965,192.45,185.655z"/>
        <path fill="#F6A5AB" d="M198.014,180.406c-0.777,2.094-2.946,2.87-4.245,4.479c-0.139,0.17-0.362,0-0.435-0.227
          c0.264-0.906,0.695-1.806,0.757-2.728c0.166-2.569,0.266-5.141,0.234-7.731c-0.051-4.372-0.471-8.709-0.771-13.058
          c0.587-0.16,0.895,0.429,0.976,0.676c0.415,1.26,1.756,1.768,2.23,2.938c-0.103,0.785,0.414,1.451,0.455,2.192
          c0.189,3.468,0.506,6.927,0.43,10.403C197.625,178.375,197.582,179.426,198.014,180.406z"/>
        <path fill="#F6A5AB" d="M203.472,175.096c0.242,1.392-1.165,2.373-2.167,2.875c-0.951,0.478-1.5,1.41-2.268,2.098
          c-0.296,0.271-0.507,0.808-1.039,0.335c0.218-0.191,0.35-0.429,0.371-0.728c0.162-2.146,0.245-4.324-0.096-6.429
          c-0.229-1.423,0.03-2.843-0.347-4.266c-0.296-1.116-0.799-2.361-0.019-3.541c0.966,0.971,1.932,1.939,2.896,2.908
          c0.062,0.185,0.106,0.373,0.185,0.551c0.109,0.255-0.104,0.832,0.303,0.716c0.373-0.108,1.053,0.274,1.159-0.487
          c0.55,0.723,1.464,1.071,1.933,1.881l0.018-0.019c-1.443-0.478-0.998,0.493-0.909,1.188
          C203.485,173.154,203.478,174.123,203.472,175.096z M201.458,171.073c0.004-0.032,0.082-0.386-0.289-0.427
          c-0.395-0.043-0.34,0.326-0.361,0.593c-0.021,0.231-0.06,0.464-0.097,0.692c-0.062,0.369-0.304,0.906,0.299,0.916
          c0.56,0.01,0.159-0.559,0.264-0.85C201.35,171.781,201.368,171.539,201.458,171.073z M200.931,175.164
          c-0.503,0.813-0.319,1.487-0.321,2.125c-0.001,0.242,0.194,0.446,0.484,0.387c0.227-0.047,0.324-0.268,0.29-0.459
          C201.271,176.6,201.118,175.99,200.931,175.164z"/>
        <path fill="#96688B" d="M193.556,161.143c0.301,4.349,0.721,8.686,0.771,13.059c0.03,2.592-0.067,5.161-0.233,7.73
          c-0.062,0.922-0.493,1.818-0.757,2.729c-0.05-0.228,0.033-0.488-0.243-0.707c-0.323,0.535,0.106,1.364-0.644,1.701
          c-0.104-1.688,0.753-3.224,0.798-4.89c0.021-0.75,0.089-1.498,0.137-2.246c0.479-1.453,0.144-2.953,0.233-4.427
          c0.05-0.791,0.219-1.658-0.245-2.424c0.004-0.195,0.007-0.393,0.011-0.588c0.202-0.296,0.226-0.593,0.002-0.889
          c0-0.104-0.001-0.211-0.001-0.314c0.104-0.123,0.102-0.238-0.021-0.35c-0.137-2.801-0.271-5.602-0.409-8.4
          C193.155,161.133,193.355,161.137,193.556,161.143z"/>
        <path fill="#96688B" d="M197.912,165.442c-0.779,1.18-0.277,2.425,0.019,3.541c0.377,1.423,0.116,2.843,0.347,4.266
          c0.341,2.104,0.258,4.282,0.096,6.43c-0.021,0.299-0.153,0.533-0.371,0.728l0.017,0.004c-0.434-0.98-0.391-2.031-0.367-3.054
          c0.076-3.479-0.238-6.938-0.43-10.404c-0.041-0.741-0.558-1.407-0.455-2.192C197.026,165.186,197.572,165.141,197.912,165.442z"/>
        <path fill="#F6A5AB" d="M200.669,163.072c-0.039,1.127-0.077,2.257-0.115,3.384c-1.019-0.662-1.564-1.775-2.461-2.555
          c-0.343-0.295-0.353-0.577,0.027-0.863c0.122-0.093,0.195-0.246,0.299-0.369C199.695,161.149,199.963,161.198,200.669,163.072z"/>
        <path fill="#F6A5AB" d="M206.678,174.127c-0.587,0.528-1.488,0.512-2.005,1.201c-0.199,0.266-0.396-0.068-0.54-0.238
          c0.084-0.964,0.169-1.93,0.254-2.895c0.006-0.401,0.011-0.806,0.016-1.205l-0.018,0.019c1.181,0.379,1.706,1.598,2.755,2.146
          C207.106,173.536,206.504,173.647,206.678,174.127z"/>
        <path fill="#F6A5AB" d="M200.539,166.44c1.014,0.137,1.146-0.688,1.525-1.314c0.243-0.404,0.363-1.175,0.939-1.066
          c0.481,0.092,0.321,0.83,0.365,1.25c0.106,1.051,0.062,2.121,0.058,3.185c-0.003,0.287-0.23,0.388-0.489,0.409
          C202.139,168.081,201.339,167.26,200.539,166.44z"/>
        <path fill="#96688B" d="M190.25,158.654c0.086,1.396,0.174,2.79,0.26,4.187c0.053,0.408,0.104,0.813,0.158,1.221
          c-0.028,0.168-0.06,0.334-0.088,0.502h0.02c-0.67-0.482-0.922-1.104-0.831-1.955c0.157-1.473-0.237-2.913-0.393-4.368
          C189.72,158.268,190.135,158.147,190.25,158.654z"/>
        <path fill="#F6A5AB" d="M194.787,159.959c0.231-0.263,0.425-0.582,0.705-0.771c0.411-0.271,0.887-0.293,0.949,0.343
          c0.087,0.862,0.462,1.735,0.026,2.606c-0.693-0.219-1.06-0.885-1.666-1.231C194.366,160.657,194.795,160.281,194.787,159.959z"/>
        <path fill="#F6A5AB" d="M205.598,167.768c0.104,0.035,0.207,0.072,0.313,0.107c0.586,0.982,0.414,2.08,0.47,3.145
          c-0.123,0.381-0.369,0.365-0.628,0.162c-0.521-0.408-1.063-0.801-1.424-1.379c0.019-0.082,0.033-0.166,0.052-0.248l0.007-0.006
          C205.003,169.1,205.008,168.235,205.598,167.768z"/>
        <path fill="#F6A5AB" d="M207.052,171.745c0.6-0.313,0.616-1.766,1.608-1.102c0.717,0.48,0.159,1.451,0.021,2.199
          c-0.036,0.191-0.146,0.367-0.22,0.551C208.082,172.768,206.95,172.782,207.052,171.745z"/>
        <path fill="#96688B" d="M202.454,169.127c-0.106,0.764-0.786,0.379-1.159,0.487c-0.404,0.116-0.191-0.461-0.303-0.716
          c-0.076-0.178-0.124-0.366-0.185-0.551C201.435,168.442,202.059,168.541,202.454,169.127z"/>
        <path fill="#96688B" d="M189.456,157.303c0.251-0.057,0.353,0.133,0.473,0.289C189.721,157.579,189.545,157.51,189.456,157.303z"
          />
        <path fill="#96688B" d="M204.379,169.555c-0.019,0.082-0.033,0.166-0.053,0.248c-0.015-0.063-0.035-0.127-0.037-0.188
          C204.289,169.594,204.347,169.575,204.379,169.555z"/>
        <path fill="#F6A5AB" d="M179.28,166.407c0.068,0.271,0.172,0.534,0.198,0.81c0.05,0.514,0.565,1.199-0.078,1.5
          c-0.547,0.254-1.28-0.031-1.777-0.416c-0.766-0.588-0.851-1.484-0.78-2.387c0.025-0.35,0.301-0.423,0.596-0.461
          c0.337-0.043,0.732,0.135,0.979-0.257C179.233,165.223,179.153,165.889,179.28,166.407z"/>
        <path fill="#F6A5AB" d="M179.267,177.03c-0.46,0.071-0.914,0.114-1.28-0.267c-0.104-0.373-0.209-0.748-0.317-1.119
          c-0.528-1.804-0.349-2.048,1.517-2.041c0.069,0.031,0.146,0.052,0.222,0.061C179.493,174.789,179.61,175.918,179.267,177.03z"/>
        <path fill="#F6A5AB" d="M176.739,163.575c-0.073-0.481-0.146-0.966-0.224-1.446c0.25-0.188,0.503-0.373,0.749-0.566
          c0.821-0.646,1.107-0.17,1.263,0.599c0.063,0.308,0.135,0.61,0.2,0.919c0.004,0.316,0.007,0.639,0.011,0.956
          c-0.002,0.166-0.003,0.33-0.005,0.494c-0.242,0.017-0.484,0.03-0.729,0.047C177.583,164.241,177.162,163.907,176.739,163.575z"/>
        <path fill="#F6A5AB" d="M178.767,172.61c-0.162-0.27-0.343-0.529-0.481-0.813c-0.93-1.873-0.807-2.086,1.442-2.392
          c-0.291,1.159,0.417,2.362-0.313,3.478C179.198,172.791,178.982,172.701,178.767,172.61z"/>
        <path fill="#694E82" d="M178.738,164.035c-0.004-0.318-0.007-0.641-0.011-0.957c0.83-0.521,0.854,0.182,1.021,0.67
          c0.268,0.465,0.146,0.977,0.182,1.469c-0.152,0.432,0.585,1.35-0.649,1.19c-0.127-0.519-0.047-1.185-0.862-1.211
          c-0.242-0.138-0.396-0.334-0.41-0.62c0.241-0.018,0.483-0.031,0.728-0.048c0.104-0.08,0.206-0.159,0.31-0.241
          C178.941,164.201,178.84,164.119,178.738,164.035z"/>
        <path fill="#694E82" d="M178.767,172.61c0.215,0.094,0.431,0.184,0.646,0.273c0.352,0.264,0.696,0.524-0.009,0.779
          c-0.076-0.009-0.15-0.027-0.222-0.061C178.917,173.326,177.833,173.391,178.767,172.61z"/>
        <path fill="#F6A5AB" d="M178.015,160.807c-0.899-0.699-1.449-1.334-1.783-2.17c-0.256-0.641,0.005-0.984,0.559-1.197
          c0.61-0.234,0.657,0.234,0.868,0.643C178.085,158.897,178.021,159.729,178.015,160.807z"/>
        <path fill="#F6A5AB" d="M176.791,150.598c0.403-0.01,0.698,0.209,0.979,0.465c-0.276,0.013-0.553,0.023-0.829,0.035
          C176.889,150.932,176.84,150.766,176.791,150.598z"/>
        <path fill="#FDA8A9" d="M247.121,159.707c0.657-0.007,0.704,0.521,0.844,0.969C247.242,160.738,247.002,160.377,247.121,159.707z"
          />
        <path fill="#FCA7A8" d="M92.087,183.926c0.962-1.275,1.907-0.152,2.858,0.023C93.991,184.285,93.038,184.328,92.087,183.926z"/>
        <path fill="#FCA7A8" d="M89.147,183.814c0.537-0.737,1.038-0.844,1.482,0.082C90.137,183.823,89.631,184.031,89.147,183.814z"/>
        <path fill="#FCA7A8" d="M84.87,183.789c0.062-0.318,0.216-0.533,0.552-0.326c0.384,0.236,0.804,0.256,1.228,0.291
          c-0.03,0.102-0.063,0.203-0.097,0.305C85.992,183.969,85.429,183.879,84.87,183.789z"/>
        <path fill="#FCA7A8" d="M101.019,174.635c-0.22-0.17-0.24-0.351-0.021-0.541C101.14,174.27,101.163,174.45,101.019,174.635z"/>
        <path fill="#FCA7A8" d="M100.982,173.26c0.059-0.188,0.115-0.375,0.175-0.563C101.097,172.885,101.039,173.073,100.982,173.26z"/>
        <path fill="#E2403A" d="M101.946,169.772c0.018,0.025,0.035,0.05,0.053,0.074c-0.041-0.002-0.082-0.002-0.123-0.004
          C101.875,169.844,101.946,169.772,101.946,169.772z"/>
        <path fill="#FDA8A9" d="M80.802,185.141c-0.079-0.076-0.157-0.153-0.234-0.229c-0.006-0.254-0.012-0.508-0.017-0.761
          c0.32,0.015,0.643,0.025,0.961,0.039C81.575,184.73,81.677,185.301,80.802,185.141z"/>
        <path fill="#FCA7A8" d="M81.447,183.504c0.184,0.076,0.367,0.152,0.553,0.227C81.784,183.732,81.575,183.717,81.447,183.504z"/>
        <path fill="#FCA7A8" d="M79.943,183.252c0.122,0.074,0.245,0.149,0.368,0.225C80.187,183.402,80.065,183.326,79.943,183.252z"/>
        <path fill="#FCA7A8" d="M98.84,181.441c-0.726-1.526-0.65-1.702,0.765-1.77C99.397,180.281,99.526,181.037,98.84,181.441z"/>
        <path fill="#FCA7A8" d="M97.867,182.625c-0.134-0.734,0.448-0.853,0.919-1.064c-0.104,0.293-0.208,0.584-0.313,0.875
          C98.271,182.498,98.069,182.562,97.867,182.625z"/>
        <path fill="#FCA7A8" d="M100.02,178.219c-0.427-0.537-0.104-0.684,0.388-0.74c-0.068,0.23-0.137,0.457-0.204,0.689
          C100.144,178.189,100.083,178.207,100.02,178.219z"/>
        <path fill="#DC271E" d="M82.719,177.818c0.032-0.063,0.07-0.12,0.093-0.186c0.012-0.031-0.011-0.072-0.019-0.109
          c0.458-0.647,0.915-1.301,1.373-1.948c0.237,1.182,0.951,0.664,1.512,0.375c0.888-0.459,1.818-0.859,2.798-0.732
          c1.046,0.137,1.549-0.156,1.747-1.198c0.079-0.41,0.112-1.192,0.779-1.037c0.791,0.188,0.861-0.325,1.089-0.764
          c0.569,0.031,1.116,0.055,0.593,0.861c-0.364,0.563-0.114,0.6,0.441,0.555c1.079-0.086,1.414,0.324,1.24,1.413
          c-0.093,0.578-0.245,1.146-0.37,1.723c-0.018,0.107-0.034,0.217-0.051,0.326c-0.364,0.318-0.729,0.635-1.094,0.953
          c-0.307-0.242-0.543-0.586-0.99-0.605c-0.386-0.02-0.497-0.229-0.292-0.553c0.779-1.225-0.059-0.93-0.772-0.877
          c-0.771,0.057-1.159,0.557-1.621,1.158c1.295,0.225,2.282,0.646,1.676,2.201c-0.261-0.052-0.507-0.047-0.701,0.174
          c-0.956-0.039-1.913-0.08-2.87-0.119c-1.291-0.086-2.563-0.268-3.772-0.758c-0.102-0.123-0.204-0.244-0.306-0.365l-0.034-0.104
          c0,0-0.108-0.019-0.11-0.019C82.946,178.062,82.832,177.939,82.719,177.818z M88.669,177.369
          c-0.039-0.201-0.219-0.314-0.475-0.308c-0.167,0.006-0.308,0.107-0.291,0.281c0.021,0.236,0.104,0.473,0.397,0.465
          C88.51,177.803,88.652,177.672,88.669,177.369z"/>
        <path fill="#E9605D" d="M78.654,161.575c-0.292-0.563-0.546-1.147-0.889-1.679c-0.455-0.707-0.51-1.405,0.176-1.898
          c0.705-0.514,1.406-0.134,1.88,0.455c0.374,0.465,0.747,0.922,1.183,1.327c0.93,1.12,1.858,2.243,2.787,3.364
          c0.104,0.299,0.293,0.523,0.575,0.67c0.064,0.084,0.132,0.168,0.197,0.252c0.186,0.318,0.468,0.527,0.785,0.695
          c0.145,0.33,0.354,0.596,0.703,0.726c0.789,1.063,2.085,1.278,3.147,1.888c0.52,0.631,1.092,1.252,0.449,2.129
          c-0.156,0.01-0.317,0.049-0.471,0.024c-1.235-0.19-1.616,0.157-1.257,1.481c0.342,1.262-0.505,1.752-1.853,1.209
          c-0.289-0.18-0.588-0.326-0.943-0.289c-0.086,0.09-0.172,0.18-0.258,0.271c-0.021,0.149-0.039,0.3-0.058,0.446
          c-0.052,0.17-0.104,0.343-0.154,0.513c-0.279,0.472-0.76,0.489-1.226,0.544c-0.159-0.023-0.318-0.046-0.479-0.068
          c-0.93-0.853-0.825-2.021-0.966-3.115c-0.077-0.082-0.155-0.162-0.232-0.241c-0.157-0.56-0.313-1.117-0.469-1.677
          c0.042-0.354-0.085-0.662-0.27-0.955c-0.271-1.426-0.737-2.791-1.305-4.125c-0.154-0.516-0.003-1.143-0.563-1.506
          C78.983,161.869,78.819,161.721,78.654,161.575z M88.635,168.526c-0.309-0.547-0.305-0.557-0.531-0.663
          c-1.642-0.778-3.058-1.845-4.225-3.243c-1.395-1.676-2.651-3.467-4.223-4.986c-0.396-0.383-0.701-1.373-1.299-0.906
          c-0.691,0.541,0.1,1.318,0.479,1.734c1.815,2,2.471,4.506,3.201,6.973c0.462,1.563,0.769,3.166,1.128,4.756
          c0.057,0.254,0.123,0.486,0.388,0.535c0.164,0.029,0.241-0.146,0.311-0.301c0.664-1.533,0.67-1.547,2.44-1.141
          c0.525,0.117,0.5-0.137,0.492-0.508C86.754,168.688,86.76,168.688,88.635,168.526z"/>
        <path fill="#F0EBF0" d="M66.749,167.498c-0.753-0.328-1.673,0.161-2.361-0.482c-0.188-0.453-0.047-0.717,0.4-0.94
          c0.527-0.265,1.371-0.313,1.062-1.325c-0.063-0.209,0.366-0.041,0.536-0.179c0.396-0.315,0.947-0.416,1.244-0.878
          c0.253-0.143,0.687-0.074,0.654-0.555c0.1-0.061,0.2-0.119,0.3-0.178c0.339-0.049,0.658-0.123,0.688-0.556
          c0.209-0.121,0.42-0.243,0.629-0.366c0.304,0.094,0.598,0.461,0.925,0.12c0.162-0.165-0.082-0.334-0.159-0.495
          c0.327-0.002,0.631-0.063,0.852-0.334c0.306,0.038,0.669,0.67,0.896-0.091c0.296-0.003,0.593-0.005,0.89-0.009
          c0.29,0.218,0.575,0.302,0.853-0.021c0.229,0.002,0.457,0.002,0.685,0.004c0.131,0.024,0.264,0.051,0.396,0.076
          c0.579,0.297,1.593-0.136,1.679,0.996c-0.308,0.262-0.635,0.51-0.71,0.943c-0.329,0.357-0.658,0.713-0.987,1.068
          c-0.052,0.047-0.097,0.1-0.135,0.16c-0.457,0.066-0.931,0.104-1.194,0.576l0.005-0.018c-0.129,0.145-0.258,0.285-0.387,0.428
          c-0.143,0.102-0.286,0.201-0.428,0.305c-0.237,0.006-0.475,0.012-0.655,0.201c-0.179,0-0.357,0-0.538-0.003
          c0.483-1.128,0.054-2.041-0.686-2.872c-0.244-0.273-0.553-0.484-0.948-0.354c-0.409,0.139-0.572,0.439-0.7,0.871
          c-0.419,1.416-0.173,2.533,1.204,3.262c-0.535,0.052-1.015,0.15-0.799,0.881c-0.396-0.273-0.471,0.253-0.688,0.305
          c-0.373-0.4,0.312-0.602,0.125-0.986c-0.454-0.111-0.816,0.094-1.163,0.357C67.737,167.444,67.244,167.471,66.749,167.498z"/>
        <path fill="#E2403A" d="M93.996,176.768c0.125-0.574,0.276-1.145,0.37-1.723c0.174-1.087-0.161-1.498-1.24-1.412
          c-0.557,0.045-0.807,0.008-0.441-0.555c0.523-0.809-0.022-0.83-0.593-0.863c0.375-0.601,0.697-1.232,1.07-1.834
          c0.392-0.635,0.68-0.816,1.518-0.172c-0.948-1.207-0.729-2.056,0.374-2.549c0.157,0.363-0.282,0.768,0.068,1.129
          c0.406-0.15,0.286-0.516,0.32-0.805c0.102-0.895,0.471-0.299,0.762-0.143l-0.008,0.002c0.052,0.094,0.104,0.187,0.155,0.279
          c0.022,0.389,0.046,0.78,0.068,1.17c-0.548,0.938-1.596,1.838-0.248,2.936c-0.154,0.203-0.271,0.416-0.202,0.688
          c-0.19,0.516-0.384,1.032-0.575,1.549c-0.571,0.559-0.772,1.3-1.006,2.027C94.26,176.584,94.128,176.676,93.996,176.768z"/>
        <path fill="#E9605D" d="M96.204,167.844c-0.292-0.156-0.66-0.752-0.762,0.144c-0.034,0.288,0.086,0.651-0.32,0.804
          c-0.352-0.361,0.088-0.766-0.068-1.129c-1.104,0.493-1.322,1.342-0.374,2.549c-0.838-0.646-1.125-0.463-1.518,0.172
          c-0.373,0.602-0.695,1.234-1.07,1.834c-0.228,0.438-0.298,0.951-1.089,0.766c-0.667-0.154-0.701,0.627-0.779,1.037
          c-0.198,1.042-0.701,1.334-1.747,1.198c-0.979-0.127-1.91,0.273-2.798,0.734c-0.561,0.287-1.273,0.805-1.512-0.375
          c0.081-0.16,0.163-0.32,0.244-0.48l-0.003,0.008c0.451-0.053,0.713-0.365,0.959-0.697c0.635-0.101,1.269-0.2,1.903-0.302
          c0.62,0.119,1.244,0.227,1.86,0.361c0.328,0.072,0.735,0.172,0.729-0.281c-0.017-1.188,0.647-2.188,0.831-3.314
          c1.111-1.125,2.226-2.25,3.338-3.378c0.229-0.104,0.458-0.211,0.687-0.315C95.36,167.073,96.096,166.758,96.204,167.844z"/>
        <path fill="#FCA7A8" d="M90.693,170.869c-0.184,1.127-0.848,2.127-0.831,3.314c0.006,0.453-0.402,0.354-0.729,0.281
          c-0.615-0.137-1.239-0.242-1.86-0.361c-0.128-0.438-0.419-0.771-0.834-0.887c-0.65-0.178-0.44-0.596-0.365-0.998
          c1.348,0.543,2.194,0.053,1.853-1.209c-0.359-1.324,0.021-1.674,1.258-1.481c0.151,0.022,0.313-0.017,0.471-0.024
          C89.536,170.311,90.614,170.209,90.693,170.869z"/>
        <path fill="#F0EBF0" d="M89.129,149.762c0.654,0.295,1.627-0.164,1.984,0.838c-0.429,0.679-1.275,1.017-1.51,1.854
          c0.001,0-0.094,0.019-0.094,0.019c-0.141-0.109-0.259-0.082-0.357,0.061c-0.101,0.143-0.199,0.284-0.299,0.428
          c-0.374-0.021-0.595,0.168-0.7,0.512c-0.204,0.062-0.313,0.199-0.333,0.408c-0.359-0.002-0.563,0.264-0.801,0.469
          c-0.4-0.754,0.074-1.819-0.854-2.387c-0.401-0.596,0.534-0.793,0.39-1.312c0.109-0.159,0.219-0.321,0.327-0.481
          c0.366-0.066,0.73,0.414,1.1,0.004c0.084-0.027,0.168-0.055,0.252-0.082C88.823,150.774,88.992,150.309,89.129,149.762z"/>
        <path fill="#694E82" d="M70.754,166.86c-1.377-0.729-1.623-1.846-1.204-3.262c0.128-0.432,0.291-0.731,0.7-0.871
          c0.396-0.133,0.705,0.078,0.948,0.354c0.738,0.832,1.169,1.745,0.686,2.873l0.025-0.012c-0.23,0.096-0.241,0.309-0.274,0.507
          c-0.064,0.577,0.273,1.007,0.543,1.459c0.069,0.271,0.139,0.537,0.208,0.81c-0.449,0.123-0.656-0.229-0.92-0.471
          c-0.092-0.369-0.054-0.793-0.441-1.037c0.009-0.207-0.105-0.297-0.297-0.314L70.754,166.86z"/>
        <path fill="#FCA7A8" d="M87.02,154.346c0.238-0.205,0.44-0.471,0.801-0.469c0.471,0.307,0.941,0.611,1.414,0.916
          c-0.25,0.672,0.589,1.293,0.03,2.03c-0.328,0.437-1.085,0.554-1.016,1.302c0.002,0.023-0.297,0.104-0.297,0.104
          c-0.478-1.344-1.569-0.146-2.266-0.553c0.416-0.219,0.47-0.486,0.144-0.844c-0.347-0.377-0.669-0.777-1.003-1.168
          c0.116-0.189,0.293-0.266,0.506-0.273c0.16,0.094,0.321,0.189,0.48,0.283c0.372,0.356,0.741,0.719,1.115,1.074
          c0.225,0.211,0.448,0.133,0.626-0.055c0.197-0.202,0.01-0.354-0.12-0.5c-0.333-0.37-0.865-0.635-0.683-1.279
          C86.843,154.725,86.93,154.536,87.02,154.346z"/>
        <path fill="#E9605D" d="M90.852,179.371c0.606-1.554-0.381-1.978-1.676-2.201c0.462-0.603,0.85-1.103,1.621-1.158
          c0.715-0.053,1.554-0.348,0.772,0.877c-0.206,0.322-0.095,0.533,0.292,0.553c0.447,0.021,0.686,0.363,0.99,0.605
          c-0.211,0.279-0.422,0.559-0.633,0.84C91.754,179.018,91.427,179.547,90.852,179.371z"/>
        <path fill="#FCA7A8" d="M84.411,175.094c-0.081,0.16-0.163,0.32-0.244,0.48c-0.458,0.647-0.915,1.299-1.373,1.948
          c-0.328-0.455-0.868-0.807-0.51-1.528c0.18-0.363-0.271-0.386-0.478-0.525c-0.358-0.246-0.382-0.646-0.222-0.947
          c0.269-0.502,0.543-0.018,0.736,0.123C82.962,175.114,83.638,175.295,84.411,175.094z"/>
        <path fill="#FCA7A8" d="M68.229,167.414c0.347-0.266,0.709-0.471,1.163-0.357c0.186,0.387-0.499,0.586-0.125,0.986
          c0.219-0.052,0.294-0.578,0.688-0.304c0.012,0.315,0.058,0.638,0.028,0.948c-0.044,0.451,0.25,1.152-0.221,1.295
          c-0.556,0.171-0.593-0.527-0.786-0.945c-0.299-0.646-0.643,0.182-0.982,0.043C67.923,168.504,67.836,167.926,68.229,167.414z"/>
        <path fill="#FCA7A8" d="M73.885,165.038c0.264-0.475,0.737-0.509,1.194-0.576c0.396,0.576,0.827,1.102,1.631,1.035
          c-0.146,0.232-0.31,0.459-0.438,0.702c-0.188,0.356-0.387,0.765,0.343,0.55c0.187-0.057,0.38-0.225,0.486,0.047
          c0.104,0.264-0.046,0.455-0.3,0.561c-0.991,0.41-1.571-0.094-2.013-0.904c0.28,0.094,0.6,0.418,0.845,0.066
          c0.236-0.338-0.165-0.557-0.346-0.776C74.934,165.307,74.519,164.942,73.885,165.038z"/>
        <path fill="#96688B" d="M68.229,167.414c-0.395,0.512-0.308,1.09-0.234,1.666c0.021,0.145,0.044,0.289,0.066,0.434
          c-0.604,0.154-0.929-0.377-1.392-0.565c0.069-0.011,0.1-0.048,0.09-0.114c-0.003-0.445-0.008-0.891-0.012-1.334
          C67.244,167.471,67.737,167.444,68.229,167.414z"/>
        <path fill="#FCA7A8" d="M89.201,167.373c-1.063-0.607-2.358-0.824-3.147-1.889c0.655,0.074,1.324-0.221,1.852,0.646
          c0.357,0.594,0.259-0.438,0.673-0.465C88.708,166.247,89.182,166.713,89.201,167.373z"/>
        <path fill="#FCA7A8" d="M92.552,155.879c-0.311-0.037-0.649-0.179-0.926-0.097c-0.89,0.267-1.41,0.015-1.562-0.919
          c0.146-0.125,0.295-0.25,0.44-0.373C91.293,154.797,92.209,154.916,92.552,155.879L92.552,155.879z"/>
        <path fill="#96688B" d="M90.506,154.491c-0.146,0.123-0.293,0.248-0.44,0.373c-0.276-0.022-0.555-0.046-0.83-0.067
          c-0.473-0.308-0.943-0.611-1.414-0.918c0.021-0.21,0.129-0.349,0.334-0.408c0.659,0.26,1.317,0.521,1.979,0.778
          C90.257,154.329,90.381,154.409,90.506,154.491z"/>
        <path fill="#E9605D" d="M96.174,172.231c-1.348-1.098-0.3-1.996,0.248-2.936c0.057,0.319,0.112,0.641,0.168,0.961
          c-0.151,0.176-0.188,0.35-0.003,0.526c0.014,0.142,0.025,0.282,0.039,0.425c-0.063,0.086-0.128,0.17-0.191,0.254
          c-0.012,0.258-0.021,0.514-0.031,0.77C96.326,172.238,96.25,172.24,96.174,172.231z"/>
        <path fill="#96688B" d="M84.828,155.664c0.333,0.391,0.656,0.791,1.003,1.168c0.326,0.355,0.271,0.625-0.144,0.844
          c-0.161-0.129-0.322-0.258-0.483-0.387c-0.255-0.498-0.623-0.908-1.029-1.285C84.244,155.608,84.539,155.645,84.828,155.664z"/>
        <path fill="#96688B" d="M72.421,165.954c0.183-0.189,0.418-0.195,0.655-0.201c0.064,0.069,0.133,0.139,0.201,0.209
          c0.021,0.428,0.191,0.783,0.518,1.065c0.066,0.134,0.133,0.269,0.2,0.399c-0.021,0.137-0.04,0.271-0.061,0.408
          c-0.198,0.016-0.397,0.031-0.598,0.047C73.033,167.24,72.726,166.596,72.421,165.954z"/>
        <path fill="#FCA7A8" d="M72.421,165.954c0.306,0.643,0.611,1.284,0.916,1.93c-0.386,0.008-0.771,0.016-1.158,0.022
          c-0.271-0.452-0.608-0.882-0.544-1.459c0.093-0.168,0.185-0.337,0.274-0.507l-0.025,0.011
          C72.063,165.954,72.243,165.954,72.421,165.954z"/>
        <path fill="#96688B" d="M89.511,152.471c0,0,0.095-0.018,0.094-0.018c0.116,0.248,0.293,0.198,0.48,0.094
          c0.511,0.434,1.144,0.377,1.746,0.436c0.068,0.121,0.136,0.244,0.205,0.367c-0.457,0.162-0.931,0.158-1.404,0.133
          c-0.153-0.026-0.308-0.055-0.462-0.079c-0.388-0.302-0.809-0.5-1.315-0.445c0.101-0.144,0.198-0.284,0.3-0.428
          C89.273,152.51,89.392,152.491,89.511,152.471z"/>
        <path fill="#96688B" d="M76.712,165.497c-0.805,0.064-1.233-0.459-1.631-1.035c0.038-0.063,0.083-0.113,0.135-0.162
          c0.26,0.07,0.52,0.141,0.777,0.209C76.283,164.803,77.204,164.635,76.712,165.497z"/>
        <path fill="#FCA7A8" d="M87.281,179.426c0.957,0.039,1.914,0.08,2.871,0.119c-0.109,0.84-0.664,0.879-1.318,0.725
          C88.241,180.131,87.726,179.838,87.281,179.426z"/>
        <path fill="#FCA7A8" d="M94.717,167.174c-0.229,0.104-0.458,0.211-0.687,0.315c-0.061-0.144-0.086-0.313-0.185-0.427
          c-0.35-0.398-0.313-0.773,0.034-1.139c0.099-0.104,0.291-0.258,0.356-0.226c0.4,0.202,0.377,0.636,0.455,1.005
          C94.723,166.856,94.71,167.018,94.717,167.174z"/>
        <path fill="#FCA7A8" d="M92.22,178.887c0.211-0.281,0.422-0.561,0.633-0.84c0.363-0.318,0.729-0.635,1.094-0.953
          C94.307,178.586,94.189,178.711,92.22,178.887z"/>
        <path fill="#96688B" d="M73.885,165.038c0.634-0.098,1.049,0.27,1.405,0.702c0.181,0.222,0.582,0.438,0.346,0.776
          c-0.244,0.351-0.563,0.025-0.845-0.067l-0.01-0.011c-0.154-0.162-0.311-0.323-0.463-0.484c-0.079-0.158-0.158-0.318-0.237-0.479
          c-0.063-0.149-0.128-0.301-0.192-0.453L73.885,165.038z"/>
        <path fill="#FCA7A8" d="M88.854,152.957c0.509-0.056,0.93,0.145,1.315,0.444c-0.013,0.28-0.024,0.563-0.038,0.847
          c-0.66-0.26-1.318-0.521-1.979-0.779C88.26,153.125,88.48,152.936,88.854,152.957z"/>
        <path fill="#FCA7A8" d="M94.39,176.494c0.232-0.729,0.435-1.471,1.006-2.028C95.452,175.334,95.559,176.228,94.39,176.494z"/>
        <path fill="#FCA7A8" d="M95.86,176.91c0.366,0.83,0.349,1.348,0.389,2.071C95.448,178.373,95.676,177.81,95.86,176.91z"/>
        <path fill="#FCA7A8" d="M94.277,155.983c-0.023,0.303-0.206,0.361-0.406,0.311c-0.347-0.09-0.508-0.363-0.484-0.697
          c0.018-0.215,0.143-0.586,0.378-0.435C94.018,155.323,94.122,155.717,94.277,155.983z"/>
        <path fill="#FCA7A8" d="M85.368,174.406c-0.247,0.332-0.51,0.646-0.959,0.696c-0.011-0.433-0.051-0.886,0.485-1.062
          C85.208,173.936,85.293,174.172,85.368,174.406z"/>
        <path fill="#FCA7A8" d="M79.147,162.014c0.562,0.363,0.409,0.99,0.563,1.506c-0.646,0.07-1.146,0.043-0.659-0.821
          C79.158,162.51,79.12,162.245,79.147,162.014z"/>
        <path fill="#FCA7A8" d="M66.67,168.948c0.463,0.188,0.788,0.722,1.392,0.565c-0.157,0.295-0.205,0.779-0.72,0.566
          C66.865,169.881,66.578,169.512,66.67,168.948z"/>
        <path fill="#FCA7A8" d="M91.832,152.983c-0.604-0.059-1.235-0.002-1.746-0.436C90.661,152.723,91.546,151.647,91.832,152.983z"/>
        <path fill="#FCA7A8" d="M89.74,182.406c-0.102,0.258-0.31,0.327-0.552,0.268c-0.188-0.049-0.369-0.17-0.285-0.404
          c0.076-0.213,0.288-0.215,0.479-0.203C89.589,182.078,89.754,182.146,89.74,182.406z"/>
        <path fill="#FCA7A8" d="M90.632,153.483c0.476,0.025,0.947,0.029,1.404-0.133C91.653,154.272,91.169,154.157,90.632,153.483z"/>
        <path fill="#FCA7A8" d="M71.466,168.245c0.264,0.24,0.471,0.594,0.92,0.471c-0.052,0.334-0.201,0.635-0.583,0.51
          C71.321,169.067,71.476,168.61,71.466,168.245z"/>
        <path fill="#FCA7A8" d="M84.367,163.815c-0.282-0.146-0.473-0.371-0.575-0.67c0.239-0.08,0.501-0.323,0.722-0.041
          C84.705,163.352,84.566,163.608,84.367,163.815z"/>
        <path fill="#FCA7A8" d="M75.994,164.508c-0.259-0.068-0.519-0.139-0.777-0.209c0.328-0.354,0.657-0.709,0.986-1.066
          C76.132,163.66,76.062,164.084,75.994,164.508z"/>
        <path fill="#FCA7A8" d="M85.348,164.762c-0.317-0.168-0.601-0.377-0.785-0.695c0.346,0.005,0.746-0.15,1.014,0.119
          C85.805,164.42,85.45,164.575,85.348,164.762z"/>
        <path fill="#FCA7A8" d="M82.243,178.285c-0.064,0.2-0.198,0.323-0.416,0.293c-0.18-0.027-0.271-0.187-0.189-0.332
          c0.061-0.109,0.215-0.205,0.342-0.23C82.138,177.984,82.253,178.094,82.243,178.285z"/>
        <path fill="#FCA7A8" d="M81.015,167.645c0.185,0.293,0.312,0.602,0.27,0.955C80.642,168.436,80.356,168.174,81.015,167.645z"/>
        <path fill="#FCA7A8" d="M97.385,157.899c0.434,0.33,0.477,0.834,0.586,1.309C97.112,159.069,97.424,158.406,97.385,157.899z"/>
        <path fill="#FCA7A8" d="M72.821,168.707c0.09-0.041,0.191-0.132,0.289-0.123c0.2,0.016,0.21,0.188,0.186,0.346
          c-0.02,0.125-0.102,0.242-0.234,0.176C72.978,169.065,72.947,168.928,72.821,168.707z"/>
        <path fill="#FCA7A8" d="M69.199,171.5c-0.031-0.068-0.107-0.164-0.11-0.263c-0.004-0.155,0.063-0.323,0.265-0.233
          c0.068,0.031,0.106,0.196,0.106,0.301C69.459,171.42,69.396,171.532,69.199,171.5z"/>
        <path fill="#FCA7A8" d="M96.043,164.948c0.042,0.091,0.132,0.192,0.125,0.289c-0.017,0.2-0.19,0.209-0.347,0.187
          c-0.124-0.021-0.243-0.103-0.177-0.234C95.685,165.106,95.824,165.075,96.043,164.948z"/>
        <path fill="#FCA7A8" d="M82.953,173.633c0.161,0.022,0.32,0.045,0.479,0.067c-0.07,0.231,0.132,0.677-0.271,0.642
          C82.844,174.315,82.996,173.887,82.953,173.633z"/>
        <path fill="#FCA7A8" d="M97.557,180.709c-0.069-0.158-0.146-0.252-0.148-0.351c-0.004-0.157,0.063-0.323,0.264-0.235
          c0.069,0.031,0.115,0.201,0.104,0.301C97.765,180.51,97.661,180.584,97.557,180.709z"/>
        <path fill="#FCA7A8" d="M97.217,175.894c0.009,0.189-0.074,0.279-0.205,0.238c-0.094-0.029-0.198-0.135-0.228-0.229
          c-0.042-0.136,0.063-0.236,0.197-0.197C97.079,175.734,97.154,175.842,97.217,175.894z"/>
        <path fill="#FCA7A8" d="M73.795,167.029c-0.325-0.282-0.496-0.64-0.518-1.065C73.911,166.092,73.814,166.579,73.795,167.029z"/>
        <path fill="#FCA7A8" d="M78.483,166.756c-0.102-0.041-0.221-0.068-0.219-0.09c0.007-0.1,0.046-0.191,0.073-0.287
          c0.063,0.024,0.177,0.043,0.182,0.076C78.532,166.549,78.499,166.649,78.483,166.756z"/>
        <path fill="#FCA7A8" d="M73.934,167.836c0.021-0.138,0.039-0.271,0.061-0.408c0.206,0.037,0.574-0.109,0.542,0.236
          C74.509,167.95,74.156,167.823,73.934,167.836z"/>
        <path fill="#FCA7A8" d="M96.174,172.231c0.076,0.009,0.151,0.007,0.229,0c-0.016,0.313-0.073,0.591-0.43,0.688
          C95.903,172.647,96.019,172.434,96.174,172.231z"/>
        <path fill="#FCA7A8" d="M100.264,165.113c0.272,0.222,0.457,0.487,0.479,0.851C100.27,165.854,100.24,165.5,100.264,165.113z"/>
        <path fill="#FCA7A8" d="M99.627,165.543c0.044,0.051,0.126,0.107,0.117,0.145c-0.016,0.059-0.084,0.129-0.14,0.141
          c-0.131,0.02-0.221-0.048-0.157-0.188C99.469,165.59,99.566,165.575,99.627,165.543z"/>
        <path fill="#FCA7A8" d="M90.969,168.608c-0.02,0.027-0.038,0.055-0.058,0.078c-0.042-0.035-0.087-0.069-0.124-0.109
          c-0.004-0.007,0.035-0.051,0.054-0.076C90.884,168.536,90.926,168.573,90.969,168.608z"/>
        <path fill="#FCA7A8" d="M92.552,155.879c0.136,0.039,0.296,0.047,0.31,0.237c0.001,0.022-0.048,0.071-0.07,0.069
          C92.597,156.176,92.59,156.014,92.552,155.879L92.552,155.879z"/>
        <path fill="#FCA7A8" d="M96.587,170.785c-0.185-0.18-0.147-0.354,0.003-0.527C96.798,170.434,96.796,170.61,96.587,170.785z"/>
        <path fill="#FCA7A8" d="M81.852,172.91c-0.046,0.031-0.09,0.064-0.135,0.1c-0.017-0.027-0.054-0.072-0.049-0.078
          c0.039-0.039,0.086-0.069,0.131-0.104C81.818,172.854,81.835,172.879,81.852,172.91z"/>
        <path fill="#FCA7A8" d="M84.658,173.16c0.052-0.171,0.104-0.344,0.154-0.514C85.097,172.918,84.98,173.069,84.658,173.16z"/>
        <path fill="#FCA7A8" d="M100.818,166.387c0.027,0.178,0.059,0.354,0.088,0.531C100.543,166.797,100.523,166.619,100.818,166.387z"
          />
        <path fill="#FCA7A8" d="M81.831,177.303c-0.055-0.008-0.111-0.016-0.166-0.022c0.007-0.029,0.014-0.093,0.021-0.091
          c0.057,0,0.11,0.015,0.167,0.021C81.844,177.24,81.838,177.271,81.831,177.303z"/>
        <path fill="#FCA7A8" d="M74.32,165.954c0.152,0.161,0.309,0.322,0.463,0.484C74.511,166.389,74.357,166.227,74.32,165.954z"/>
        <path fill="#FCA7A8" d="M73.889,165.022c0.064,0.152,0.129,0.305,0.192,0.453c-0.192-0.008-0.384-0.018-0.577-0.023
          C73.633,165.307,73.761,165.164,73.889,165.022z"/>
        <path fill="#FCA7A8" d="M99.251,161.416c-0.004,0.148-0.008,0.299-0.013,0.448c-0.062-0.185-0.12-0.364-0.18-0.55
          C99.129,161.34,99.193,161.373,99.251,161.416z"/>
        <path fill="#FCA7A8" d="M99.528,162.795c0.072,0.008,0.143,0.026,0.21,0.057c0,0.154,0,0.311,0.001,0.461
          C99.668,163.141,99.596,162.969,99.528,162.795z"/>
        <path fill="#FCA7A8" d="M98.804,160.791c0.065,0.116,0.132,0.232,0.196,0.354C98.935,161.026,98.871,160.91,98.804,160.791z"/>
        <path fill="#FCA7A8" d="M82.719,177.818c0.113,0.121,0.228,0.244,0.338,0.365C82.947,178.062,82.832,177.939,82.719,177.818z"/>
        <path fill="#FCA7A8" d="M70.726,166.893c0.19,0.02,0.306,0.107,0.297,0.314C70.924,167.104,70.826,166.998,70.726,166.893z"/>
        <path fill="#FCA7A8" d="M81.754,170.276c0.077,0.08,0.155,0.16,0.232,0.242C81.909,170.436,81.831,170.356,81.754,170.276z"/>
        <path fill="#FCA7A8" d="M83.203,178.303c0.102,0.121,0.204,0.242,0.306,0.365C83.407,178.545,83.303,178.424,83.203,178.303z"/>
        <path fill="#FCA7A8" d="M98.556,160.17c0.063,0.084,0.127,0.17,0.189,0.254C98.682,160.34,98.621,160.254,98.556,160.17z"/>
        <path fill="#FCA7A8" d="M84.87,172.2c0.086-0.091,0.172-0.181,0.258-0.271C85.041,172.02,84.955,172.11,84.87,172.2z"/>
        <path fill="#E9605D" d="M96.352,168.125c-0.053-0.095-0.104-0.188-0.155-0.279C96.248,167.938,96.3,168.032,96.352,168.125z"/>
        <path fill="#FCA7A8" d="M96.432,171.461c0.064-0.084,0.129-0.168,0.192-0.254C96.561,171.293,96.497,171.377,96.432,171.461z"/>
        <path fill="#F0EBF0" d="M84.828,155.664c-0.288-0.021-0.584-0.059-0.653,0.34c-0.047,0.098-0.094,0.194-0.14,0.293
          c-0.164,0.014-0.329,0.024-0.495,0.037c-0.389,0.014-0.777-0.006-1.112,0.268c-0.551,0.453-1.271,0.752-1.809,0.258
          c-0.571-0.525,0.5-0.834,0.458-1.372c0.124-0.175,0.247-0.349,0.371-0.521c0.168-0.345,0.337-0.688,0.506-1.031
          c1.162,0.246,1.323-0.879,1.924-1.4c0.032-0.086,0.063-0.172,0.095-0.259c0.694-0.19,0.659,0.399,0.829,0.771
          c-0.176,0.86,0.001,1.643,0.532,2.346C85.121,155.401,84.945,155.475,84.828,155.664z"/>
        <path fill="#96688B" d="M84.802,153.045c-0.17-0.371-0.135-0.963-0.83-0.771c-0.102-0.079-0.202-0.159-0.304-0.239l0.009,0.013
          c-0.014-0.152-0.095-0.238-0.25-0.25l0.01,0.008c-0.013-0.153-0.101-0.23-0.25-0.25l0.011,0.01
          c-0.017-0.076-0.021-0.156-0.048-0.229c-0.109-0.281-0.514-0.555-0.276-0.838c0.338-0.406,0.591,0.217,0.926,0.209
          c0.125,0.201,0.179,0.648,0.499,0.404c0.253-0.195-0.031-0.469-0.272-0.613c0.027-0.338-0.23-1,0.54-0.559
          c0.271,0.156,0.438,0.498,0.654,0.762c0.082-0.091,0.123-0.115,0.134-0.148c0.138-0.494-0.837-1.131-0.05-1.393
          c0.568-0.189,0.779,0.707,1.065,1.191c0.06,0.104,0.126,0.199,0.188,0.299c0.146,0.52-0.791,0.717-0.39,1.313
          C84.848,151.238,84.955,152.303,84.802,153.045z"/>
        <path fill="#96688B" d="M83.878,152.532c-0.601,0.522-0.762,1.647-1.924,1.401c-0.249-0.582-0.921-0.893-1.012-1.604
          c0.418-0.148,0.635,0.217,0.926,0.359c0.229,0.111,0.44,0.5,0.695,0.212c0.265-0.302-0.065-0.575-0.26-0.812
          c-0.138-0.168-0.313-0.306-0.475-0.457c0.502-0.56,0.938,0.287,1.366-0.072l-0.011-0.01c-0.006,0.172,0.078,0.256,0.25,0.25
          l-0.01-0.009c-0.007,0.175,0.076,0.259,0.25,0.25l-0.009-0.012C83.543,152.282,83.677,152.422,83.878,152.532z"/>
        <path fill="#96688B" d="M70.668,161.664c0.077,0.161,0.321,0.33,0.159,0.496c-0.328,0.34-0.621-0.027-0.925-0.121
          c-0.239-0.539-0.485-1.076-0.715-1.621c-0.076-0.184-0.128-0.41,0.09-0.525c0.24-0.125,0.475-0.039,0.602,0.201
          C70.153,160.613,70.407,161.141,70.668,161.664z"/>
        <path fill="#96688B" d="M69.273,162.407c-0.03,0.433-0.351,0.507-0.688,0.556c-0.146-0.152-0.313-0.211-0.519-0.125l0.011,0.01
          c0.251-0.569-0.323-0.826-0.466-1.246c-0.096-0.274-0.442-0.59-0.092-0.84c0.417-0.297,0.486,0.211,0.671,0.414
          C68.557,161.579,68.913,161.997,69.273,162.407z"/>
        <path fill="#96688B" d="M68.285,163.139c0.031,0.479-0.401,0.412-0.654,0.555c-0.51-0.525-1.172-0.939-1.406-1.831
          c0.868,0.002,1.125,0.959,1.854,0.983l-0.011-0.01C68.138,162.936,68.212,163.038,68.285,163.139z"/>
        <path fill="#A896A6" d="M75.235,161.291c-0.133-0.025-0.266-0.052-0.396-0.076c-0.466-0.853-0.719-1.728-0.292-2.865
          C75.195,159.356,75.286,160.307,75.235,161.291z"/>
        <path fill="#96688B" d="M81.449,154.965c-0.124,0.174-0.248,0.348-0.371,0.521c-0.156-0.026-0.323-0.026-0.466-0.089
          c-0.563-0.229-1.548-0.495-1.318-1.031c0.27-0.632,0.853,0.311,1.364,0.354C80.926,154.745,81.184,154.881,81.449,154.965z"/>
        <path fill="#A896A6" d="M74.156,161.211c-0.275,0.321-0.563,0.237-0.853,0.021c-0.088-0.668-0.845-1.676-0.225-1.895
          c0.679-0.24,0.479,1.074,0.939,1.551C74.094,160.967,74.112,161.102,74.156,161.211z"/>
        <path fill="#A896A6" d="M72.414,161.241c-0.228,0.761-0.591,0.13-0.896,0.091c0.127-0.664-0.737-1.139-0.393-1.898
          C71.955,159.801,71.949,160.69,72.414,161.241z"/>
        <path fill="#A896A6" d="M83.539,156.334c0.166-0.013,0.331-0.023,0.495-0.037c0.15,0.442,0.364,0.869,0.343,1.36
          c-0.01,0.226,0.097,0.511-0.223,0.58c-0.288,0.063-0.406-0.187-0.506-0.399C83.425,157.354,83.443,156.846,83.539,156.334z"/>
        <path fill="#A896A6" d="M87.982,150.17c-0.368,0.41-0.732-0.07-1.1-0.004c0.078-0.592-0.713-1.433,0.011-1.691
          c0.559-0.199,0.416,0.916,0.798,1.324C87.799,149.913,87.885,150.045,87.982,150.17z"/>
        <path fill="#96688B" d="M89.129,149.762c-0.138,0.547-0.307,1.012-0.895,0.326c-0.003-0.08-0.028-0.166-0.004-0.234
          c0.165-0.438-0.51-1.17,0.189-1.287C88.957,148.477,88.869,149.354,89.129,149.762z"/>
        <polygon fill="#DC271E" points="54.395,155.164 54.447,155.11 54.385,155.156 		"/>
        <path fill="#96688B" d="M190.905,169.93c-0.343,0.703,0.007,1.406,0.026,2.105c0.081,2.771-0.269,5.517-0.48,8.271
          c-0.455,0.783-0.153,1.642-0.237,2.459c-0.34,1.787-0.673,3.576-1.026,5.359c-0.063,0.313,0.064,0.438,0.326,0.516
          c-0.302,0.599,0.134,0.586,0.513,0.621c-0.193,0.562-0.646,0.924-1.021,1.346c-0.172-0.573-0.632-0.633-1.126-0.629
          c0.709-3.414,1.403-6.823,1.69-10.317c0.217-2.648,0.58-5.273,0.36-7.945c-0.053-0.623-0.064-1.371,0.432-1.935
          c0.078,0.005,0.156,0.009,0.234,0.013C190.697,169.84,190.802,169.885,190.905,169.93z"/>
        <path fill="#F6A5AB" d="M200.827,182.674c-0.098,0.518,0.159,1.148-0.549,1.338c-0.319,0.086-2.006-1.414-1.867-1.643
          c0.411-0.677,1.009-1.67,1.59-1.623C200.913,180.821,200.759,181.943,200.827,182.674z"/>
        <path fill="#F6A5AB" d="M189.002,190.607c0.376-0.422,0.828-0.784,1.021-1.346c0.201-0.154,0.412-0.281,0.682-0.256
          c0.184,0.391,0.417,0.766,0.536,1.174c0.313,1.055-0.24,1.658-1.309,1.484c-0.466-0.074-0.934-0.14-1.399-0.209
          C188.272,190.943,188.861,190.898,189.002,190.607z"/>
        <path fill="#F6A5AB" d="M202.583,181.084c-0.625-0.174-1.317-0.57-1.188-1.221c0.13-0.644,0.857-1.203,1.502-1.334
          c0.408-0.085,1.146,0.646,0.979,1.051C203.651,180.137,203.574,181.059,202.583,181.084z"/>
        <path fill="#F6A5AB" d="M205.824,175.771c0.671,0.49,0.802,1.104,0.538,1.89c-0.283,0.852-0.986,0.444-1.508,0.596
          C204.037,177.262,204.126,177.035,205.824,175.771z"/>
        <path fill="#F6A5AB" d="M193.379,187.076c0.071,0.584,0.19,1.168,0.204,1.754c0.014,0.598-0.257,0.828-0.796,0.385
          c-0.397-0.326-0.79-0.66-1.332-0.723C191.764,187.293,192.472,186.771,193.379,187.076z"/>
        <path fill="#F6A5AB" d="M195.469,185.205c0.034,0.112,0.068,0.225,0.104,0.338c0.478,1.49,0.251,1.771-1.217,1.529
          c0.072-0.513-1.104-0.646-0.428-1.308C194.334,185.373,194.732,184.768,195.469,185.205z"/>
        <path fill="#F6A5AB" d="M198.105,184.531c-0.04,0.284,0.039,0.864-0.426,0.707c-0.598-0.201-1.111-0.679-1.621-1.087
          c-0.115-0.092,1.06-1.28,1.275-1.27C197.701,182.906,198.093,183.723,198.105,184.531z"/>
        <path fill="#96688B" d="M204.387,172.197c-0.085,0.966-0.17,1.931-0.254,2.896c-0.222,0.002-0.44,0.004-0.661,0.006
          c0.007-0.973,0.014-1.943,0.021-2.917C203.791,172.186,204.089,172.19,204.387,172.197z"/>
        <path fill="#F6A5AB" d="M186.131,193.555c0.214-0.432,0.75-0.635,0.813-1.168c0.43,0.226,0.689-0.354,1.099-0.229
          c0.01,0.065,0.028,0.133,0.062,0.19c-0.013,0.158-0.015,0.32-0.041,0.478c-0.093,0.521,0.33,1.271-0.319,1.52
          C187.092,194.59,186.558,194.057,186.131,193.555z"/>
        <path fill="#694E82" d="M188.102,192.348c-0.031-0.061-0.052-0.125-0.062-0.19c0.063-0.552-0.631-0.679-0.657-1.179
          c0.163-0.334,0.327-0.666,0.491-1c0.494-0.004,0.954,0.056,1.126,0.629c-0.142,0.291-0.729,0.336-0.469,0.851
          C188.519,191.816,188.622,192.232,188.102,192.348z"/>
        <path fill="#96688B" d="M183.955,176.309c-0.238,0.064-0.479,0.131-0.718,0.195c-0.196-2-0.166-4.005-0.165-6.009
          c0-0.867,0.026-1.736,0.042-2.605c-0.021-0.162-0.047-0.324-0.069-0.485c0.256-0.69,0.191-1.343-0.243-1.944
          c0.069-0.891-0.054-1.768-0.182-2.643c0.301-0.023,0.438,0.475,0.8,0.25C184.275,167.454,183.927,171.889,183.955,176.309z"/>
        <path fill="#96688B" d="M181.171,157.059c0.369-0.082,0.405-0.358,0.398-0.666c0.39,1.443,0.776,2.89,1.164,4.332
          c-0.133,0.224-0.267,0.445-0.399,0.666c-0.476-1.084-0.847-2.201-1.049-3.369c0.03-0.068,0.047-0.143,0.048-0.219
          c0.04-0.074,0.052-0.149,0.029-0.231c-0.081-0.091-0.163-0.183-0.243-0.271l0.02,0.018
          C181.148,157.229,181.161,157.145,181.171,157.059z"/>
        <path fill="#694E82" d="M182.334,161.391c0.135-0.221,0.269-0.442,0.399-0.666c0.366,0.74,0.509,1.545,0.687,2.342
          c-0.361,0.225-0.499-0.273-0.8-0.25C182.405,162.366,182.269,161.899,182.334,161.391z"/>
        <path fill="#F6A5AB" d="M182.802,165.457c0.437,0.604,0.499,1.254,0.243,1.943C182.603,166.799,182.857,166.11,182.802,165.457z"
          />
        <path fill="#F6A5AB" d="M178.013,151.006c0.156,0.182,0.314,0.363,0.473,0.545c-0.358,0.006-0.66-0.074-0.717-0.502
          C177.849,151.036,177.931,151.02,178.013,151.006z"/>
        <path fill="#F6A5AB" d="M180.055,157.551c0.02-0.027,0.039-0.053,0.058-0.078c0.043,0.037,0.089,0.07,0.124,0.111
          c0.005,0.006-0.033,0.049-0.054,0.075C180.139,157.623,180.098,157.586,180.055,157.551z"/>
        <path fill="#F6A5AB" d="M181.171,157.059c-0.011,0.086-0.021,0.17-0.032,0.258c-0.238-0.204-0.27-0.467-0.215-0.754
          C181.006,156.729,181.089,156.893,181.171,157.059z"/>
        <path fill="#F6A5AB" d="M181.12,157.299c0.08,0.09,0.162,0.182,0.243,0.271C181.282,157.481,181.2,157.389,181.12,157.299z"/>
        <path fill="#F6A5AB" d="M180.685,156.293c0.163,0.021,0.224,0.131,0.231,0.28C180.842,156.481,180.763,156.387,180.685,156.293z"
          />
        <path fill="#F6A5AB" d="M181.333,157.803c-0.001,0.076-0.018,0.147-0.047,0.219C181.302,157.948,181.317,157.875,181.333,157.803z
          "/>
        <path fill="#96688B" d="M187.12,169.059c0.218-0.156,0.438-0.311,0.654-0.467c0.158,2.459,0.313,4.938-0.015,7.375
          c-0.479,3.545-0.828,7.119-1.83,10.578c-0.12,0.416-0.201,0.768-0.698,0.813c-0.115-0.112-0.229-0.229-0.345-0.346
          c0.965-3.604,1.336-7.328,2.081-10.979c0.292-1.433-0.044-2.99-0.099-4.492C186.839,170.701,186.907,169.873,187.12,169.059z"/>
        <path fill="#694E82" d="M190.594,169.793c-0.078-0.004-0.156-0.008-0.234-0.013c-0.493-1.767-0.773-3.522,0.239-5.222h-0.02
          c-0.108,0.14-0.079,0.27,0.026,0.395c0.054,0.786-0.108,1.604,0.326,2.336c-0.331,0.645-0.104,1.291-0.015,1.938
          C190.81,169.416,190.702,169.606,190.594,169.793z"/>
        <path fill="#96688B" d="M187.385,190.978c0.027,0.5,0.721,0.627,0.657,1.179c-0.407-0.125-0.669,0.454-1.099,0.229
          c-0.344-0.01-0.688-0.02-1.029-0.027C186.472,191.971,187.106,191.664,187.385,190.978z"/>
        <path fill="#96688B" d="M185.912,192.364c-0.083,0.073-0.165,0.149-0.248,0.227C185.746,192.512,185.829,192.437,185.912,192.364z
          "/>
        <path fill="#96688B" d="M185.662,192.588c-0.106,0.057-0.217,0.113-0.324,0.17C185.444,192.701,185.554,192.644,185.662,192.588z"
          />
        <path fill="#96688B" d="M179.939,151.776c-0.086-0.075-0.172-0.153-0.258-0.229C179.767,151.622,179.853,151.7,179.939,151.776z"
          />
        <path fill="#F6A5AB" d="M193.372,171.668c0.464,0.766,0.295,1.633,0.245,2.424c-0.092,1.474,0.244,2.974-0.233,4.427
          C193.38,176.236,193.376,173.951,193.372,171.668z"/>
        <path fill="#F6A5AB" d="M190.213,182.766c0.084-0.817-0.218-1.676,0.238-2.459C190.393,181.129,190.704,181.986,190.213,182.766z"
          />
        <path fill="#F6A5AB" d="M190.933,167.289c-0.437-0.732-0.271-1.55-0.326-2.336C191.234,165.661,190.846,166.508,190.933,167.289z"
          />
        <path fill="#F6A5AB" d="M190.668,164.059c-0.054-0.406-0.105-0.813-0.158-1.221C191.143,163.17,191.019,163.6,190.668,164.059z"/>
        <path fill="#F6A5AB" d="M193.385,170.194c0.224,0.296,0.2,0.594-0.002,0.889C193.384,170.786,193.384,170.49,193.385,170.194z"/>
        <path fill="#F6A5AB" d="M190.594,169.793c0.106-0.188,0.217-0.377,0.325-0.566c-0.005,0.234-0.011,0.47-0.016,0.703
          C190.802,169.885,190.697,169.84,190.594,169.793z"/>
        <path fill="#F6A5AB" d="M193.364,169.531c0.12,0.108,0.124,0.226,0.021,0.349C193.377,169.762,193.371,169.645,193.364,169.531z"
          />
        <path fill="#A896A6" d="M201.458,171.073c-0.091,0.468-0.106,0.707-0.187,0.927c-0.104,0.291,0.296,0.857-0.264,0.85
          c-0.603-0.01-0.358-0.547-0.299-0.916c0.037-0.229,0.075-0.461,0.097-0.692c0.021-0.267-0.031-0.636,0.361-0.593
          C201.54,170.688,201.462,171.039,201.458,171.073z"/>
        <path fill="#96688B" d="M200.931,175.164c0.187,0.826,0.34,1.436,0.453,2.053c0.034,0.191-0.063,0.412-0.29,0.459
          c-0.29,0.062-0.485-0.145-0.484-0.387C200.612,176.652,200.429,175.978,200.931,175.164z"/>
        <path fill="#694E82" d="M204.387,172.197c-0.299-0.007-0.597-0.011-0.896-0.017c-0.088-0.695-0.533-1.666,0.91-1.188
          C204.397,171.391,204.392,171.793,204.387,172.197z"/>
        <path fill="#96688B" d="M178.738,164.035c0.104,0.084,0.203,0.168,0.305,0.252c-0.104,0.082-0.205,0.161-0.31,0.241
          C178.735,164.365,178.736,164.2,178.738,164.035z"/>
        <path fill="#E9605D" d="M88.669,177.369c-0.018,0.305-0.158,0.436-0.366,0.438c-0.295,0.008-0.379-0.229-0.397-0.465
          c-0.017-0.174,0.124-0.275,0.291-0.281C88.451,177.055,88.63,177.166,88.669,177.369z"/>
        <path fill="#FCA7A8" d="M88.635,168.526c-1.875,0.162-1.881,0.162-1.839,2.25c0.008,0.371,0.033,0.625-0.492,0.507
          c-1.771-0.405-1.776-0.394-2.44,1.142c-0.068,0.154-0.146,0.33-0.311,0.301c-0.266-0.049-0.331-0.281-0.388-0.535
          c-0.359-1.59-0.667-3.193-1.128-4.756c-0.73-2.467-1.386-4.973-3.201-6.973c-0.378-0.416-1.169-1.193-0.479-1.734
          c0.599-0.467,0.903,0.523,1.299,0.906c1.57,1.521,2.828,3.313,4.223,4.986c1.167,1.398,2.583,2.465,4.225,3.243
          C88.33,167.969,88.327,167.979,88.635,168.526z"/>
        <path fill="#FCA7A8" d="M68.285,163.139c-0.072-0.102-0.146-0.203-0.219-0.303c0.203-0.086,0.372-0.027,0.519,0.125
          C68.485,163.02,68.384,163.079,68.285,163.139z"/>
        <path fill="#694E82" d="M84.802,153.045c0.153-0.742,0.047-1.809,1.364-1.086c0.929,0.565,0.453,1.633,0.854,2.387
          c-0.089,0.189-0.178,0.379-0.266,0.568c-0.313,0.254-0.627,0.506-0.939,0.76c-0.159-0.094-0.32-0.189-0.48-0.283
          C84.802,154.688,84.626,153.907,84.802,153.045z"/>
        <path fill="#FDA8A9" d="M89.511,152.471c-0.119,0.021-0.238,0.039-0.356,0.06C89.253,152.389,89.371,152.36,89.511,152.471z"/>
        <path fill="#F0EBF0" d="M71.91,165.942c-0.091,0.171-0.184,0.339-0.274,0.507C71.668,166.248,71.678,166.036,71.91,165.942z"/>
        <path fill="#96688B" d="M85.815,155.674c0.313-0.254,0.628-0.506,0.939-0.76c-0.185,0.646,0.35,0.909,0.683,1.279
          c0.129,0.146,0.316,0.298,0.121,0.5c-0.18,0.188-0.402,0.266-0.627,0.055C86.555,156.393,86.186,156.032,85.815,155.674z"/>
        <path fill="#FCA7A8" d="M83.878,152.532c-0.202-0.108-0.335-0.25-0.21-0.498c0.102,0.08,0.202,0.16,0.304,0.239
          C83.941,152.36,83.911,152.447,83.878,152.532z"/>
        <path fill="#FCA7A8" d="M84.024,150.495c0.243,0.146,0.525,0.418,0.272,0.611c-0.319,0.246-0.374-0.201-0.499-0.402
          C83.873,150.633,83.95,150.565,84.024,150.495z"/>
        <path fill="#FCA7A8" d="M83.677,152.045c-0.175,0.008-0.258-0.076-0.25-0.25C83.583,151.807,83.663,151.893,83.677,152.045z"/>
        <path fill="#FCA7A8" d="M83.436,151.803c-0.172,0.006-0.256-0.078-0.25-0.25C83.337,151.572,83.425,151.649,83.436,151.803z"/>
      </g>
    </g>
    <g id="instagram" className="clickcable" style={{cursor: 'pointer'}} onClick={openInstagram}>
      <g>
        <path fill="#F0EBF0" d="M286.71,220.573c-0.021,2.115,0.04,4.229-0.155,6.346c-0.097,1.037-0.781,1.914-0.62,3.01
          c0.057,0.388-0.447,0.4-0.756,0.347c-2.48-0.445-4.916,0.084-7.376,0.237c-1.398,0.089-2.847-0.262-4.24-0.563
          c-1.178-0.254-1.659-1.229-1.971-2.334c-0.882-3.131-0.769-6.322-0.684-9.519c0.053-1.914,0.104-3.827,0.175-5.741
          c0.045-1.22,0.461-1.98,1.858-2.298c2.991-0.67,5.954-0.963,9.012-0.569c2.146,0.274,3.313,1.561,4.085,3.379
          c0.89,2.098,0.632,4.317,0.671,6.51C286.717,219.772,286.71,220.172,286.71,220.573z M285.45,220.797c0.029,0,0.061,0,0.09,0
          c0-1.436-0.026-2.875,0.01-4.311c0.027-1.099-0.15-2.158-0.557-3.158c-0.541-1.342-1.382-2.457-2.94-2.664
          c-2.549-0.338-5.095-0.207-7.62,0.225c-1.787,0.308-2.246,0.74-2.104,2.548c0.219,2.825,0.328,5.651,0.384,8.481
          c0.026,1.437,0.022,2.869,0.339,4.287c0.28,1.264,1.438,2.465,2.619,2.373c2.519-0.201,5.025,0.102,7.537,0
          c1.899-0.076,2.13-0.24,2.227-2.154C285.523,224.553,285.45,222.674,285.45,220.797z"/>
        <path fill="#DF2015" d="M285.45,220.797c0,1.877,0.073,3.756-0.021,5.627c-0.097,1.914-0.325,2.078-2.227,2.154
          c-2.512,0.102-5.021-0.201-7.537,0c-1.183,0.092-2.339-1.109-2.62-2.373c-0.313-1.418-0.312-2.852-0.338-4.287
          c-0.056-2.83-0.165-5.656-0.385-8.481c-0.141-1.808,0.318-2.24,2.105-2.548c2.525-0.432,5.071-0.563,7.62-0.225
          c1.561,0.207,2.398,1.322,2.94,2.664c0.404,1,0.583,2.061,0.556,3.158c-0.035,1.436-0.009,2.875-0.009,4.311
          C285.51,220.797,285.479,220.797,285.45,220.797z M283.116,218.799c0.02-1.774-0.987-3.555-2.492-3.883
          c-1.432-0.311-3.395,0.342-4.474,2.627c-0.634,1.344-0.861,2.73-0.692,4.189c0.189,1.649,1.21,3.063,2.486,3.354
          c1.614,0.365,2.785-0.432,3.715-1.629C282.613,222.229,283.121,220.817,283.116,218.799z"/>
        <path fill="#FEFEFE" d="M283.116,218.799c0.005,2.019-0.503,3.431-1.457,4.658c-0.93,1.197-2.101,1.994-3.715,1.629
          c-1.276-0.289-2.297-1.703-2.486-3.354c-0.169-1.459,0.061-2.847,0.692-4.189c1.079-2.285,3.042-2.938,4.474-2.627
          C282.128,215.244,283.135,217.024,283.116,218.799z M280.723,219.758c0.011-0.959-0.372-1.481-1.114-1.522
          c-0.888-0.052-1.591,0.813-1.569,2.004c0.014,0.785,0.173,1.565,1.125,1.699C279.973,222.051,280.71,220.969,280.723,219.758z"/>
        <path fill="#DC271E" d="M280.723,219.758c-0.014,1.211-0.749,2.293-1.561,2.181c-0.952-0.134-1.111-0.914-1.125-1.699
          c-0.021-1.188,0.684-2.056,1.569-2.004C280.35,218.276,280.733,218.799,280.723,219.758z"/>
        <path fill="#DC271E" d="M289.49,226.119c0,1.516-0.054,3.035,0.022,4.549c0.039,0.764-0.243,1.121-0.928,1.26
          c-0.272,0.058-0.553,0.109-0.829,0.117c-5.392,0.16-10.767,0.693-16.163,0.715c-0.944,0.004-1.277-0.364-1.313-1.256
          c-0.14-3.871-0.541-7.725-0.646-11.602c-0.092-3.31-0.438-6.613-0.454-9.931c-0.009-1.58,0.046-1.641,1.558-1.725
          c5.303-0.297,10.604-0.617,15.905-0.896c1.577-0.084,1.65,0.039,1.734,1.541c0.323,5.742,0.657,11.482,0.987,17.224
          C289.408,226.119,289.449,226.119,289.49,226.119z M286.71,220.573c0-0.398,0.007-0.801-0.001-1.197
          c-0.039-2.188,0.219-4.412-0.671-6.51c-0.771-1.818-1.939-3.104-4.085-3.379c-3.058-0.394-6.021-0.101-9.012,0.569
          c-1.397,0.314-1.813,1.078-1.858,2.298c-0.068,1.913-0.122,3.827-0.175,5.741c-0.085,3.193-0.198,6.388,0.684,9.519
          c0.312,1.104,0.793,2.08,1.971,2.334c1.396,0.303,2.842,0.649,4.24,0.563c2.46-0.153,4.896-0.684,7.376-0.237
          c0.309,0.055,0.813,0.041,0.756-0.347c-0.161-1.096,0.523-1.973,0.62-3.01C286.75,224.801,286.688,222.688,286.71,220.573z"/>
      </g>
    </g>
    </svg>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <IndexPageTemplate />
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
